<template>
  <div
    class="bg-white content-card justify-content-center"
    :style="{ margin: '10px 0 !important' }"
  >
    <div class="d-flex flex-row px-4 py-2 opacity-border-radius bg-orange">
      <h2
        class="color-white fs-3 fw-bolder px-0 align-middle m-0"
        :style="pdf ? { fontSize: '18px !important' } : {}"
      >
        {{ t("health_report." + type + ".title") }}
      </h2>
    </div>
    <div class="px-3 pt-3">
      <div v-for="vital in vitalSign" :key="vital" >
        <vitals :vital="vital" :type="type" :font="font" :pdf="pdf" />
      </div>
    </div>
  </div>
</template>

<script>
import Vitals from "./vitals.vue";
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
    };
  },
  props: {
    vitalSign: Array,
    type: String,
    font: String,
    pdf: Boolean,
  },
  components: {
    Vitals,
  },
};
</script>

<style scoped>
.border-orange {
  border-bottom: solid;
  border-bottom-color: rgb(255, 130, 0);
}
.opacity-border-radius {
  border-radius: 10px 10px 0px 0px;
}
.right-arrow {
  height: 15px;
  margin-left: 10px;
}
</style>