<template>
  <img ref="image" :src="url" @load="onLoad()">
</template>

<script>
import Logger from "../../common/logger";

  const TRANSPARENT_PIXEL = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg==";
  
  function stopStream(url) {

    if (url == TRANSPARENT_PIXEL) {
      return Promise.resolve();
    }

    return fetch(url, {
      method: 'DELETE'
    }).then( r => r.json() )
    .then( () => {
      //console.log(json);
    }).catch( err => {
      console.error(err);
      Logger.err(err);
    });
  }

  export default {
    name: 'video-feed',
    props: {
      feedId: String,
      frameRate: { type: Number, default: 30 },
      playing: { type: Boolean, default: true },
      host: undefined,
      enableReloadTimer: { type: Boolean, default: true }
    },
    data: function() {
      return {
        timer: undefined,
        lastRefreshTime: undefined,
        frameCount: 0,
        fps: 0,
        startStreamingTime: undefined,
        url: TRANSPARENT_PIXEL
      };
    },
    created: function() {
    },
    mounted: function() {
      this.refreshURL();
    },
    unmounted: function () {
      stopStream(this.url);
    },
    beforeUnmount: function() {
      this.clearReloadTimer();
    },
    watch: {
      url: function(newValue, oldValue) {
        // console.log('DEBUG URL Changed!!!', newValue);
        stopStream(oldValue);
      },
      playing: function() {
        // console.log('DEBUG playing Changed!!!');
        this.refreshURL();
      },
      feedId: function() {
        // console.log('DEBUG feedID Changed!!!');
        this.refreshURL();
      }
    },
    methods: {
      refreshURL: function() {
        if (this.playing && this.feedId) {

          var url = '/api/stream_url?';

          if (this.host !== undefined) {
            url = 'http://' + this.host + url;
          }
          
          url += 'stream_name=' + this.feedId;

          fetch(url)
          .then( r => r.json() )
          .then( data => {
            this.url = data.url;
            console.log('URL=', this.url);
            this.setupReloadTimer();
          });
        } else {
          this.clearReloadTimer();
          this.url = TRANSPARENT_PIXEL
        }
      },
      setupReloadTimer: function() {
        if (!this.enableReloadTimer) {
          return;
        }
        this.clearReloadTimer();
        this.timer = setInterval( ()=>this.reloadTimer(), 500);
      },
      clearReloadTimer: function() {
        if (this.timer != undefined) {
          clearInterval(this.timer);
          this.timer = undefined;
        }
      },
      reloadTimer: function() {
        if (this.lastRefreshTime != undefined) {
          var now = new Date().getTime();
          var diff = now - this.lastRefreshTime;
          if ( diff > 600) {
            console.log('Reload Timer!!!!!');
            this.refreshURL();
          }
        }
        this.lastRefreshTime = new Date().getTime();
      },
      onLoad: function() {
        this.frameCount++;
        this.fps = this.frameCount / ((new Date() - this.startStreamingTime)/1000);
      }
    }
  }
</script>
