<template>
  <div class="general-wellness" :style="userType == 'free' ? {'pointer-events' : 'none'}: {'pointer-events' : 'auto'} ">
    <div class="overlay" v-if="userType == 'free'">
      <h2 class="text-center subscribe">{{t('message.subscribe')}}</h2></div>
    <nav class="navbar navbar-light">
      <div>
        <p class="title">{{t('vital_signs.general_wellness.title')}}</p>
        <p class="overall-score text-center">{{ generalWellness }}</p>
      </div>
      <div class="button-group float-right">
        <div class="d-inline-block" id="time-period">
        <button
          class="btn btn-small btn-clear"
          v-bind:class="{ active: activeChart === 0 }"
          @click="onPress(0)"
        >
          {{t('graph.day')}}
        </button>
        |
        <button
          class="btn btn-small btn-clear"
          v-bind:class="{ active: activeChart === 1 }"
          @click="onPress(1)"
        >
          {{t('graph.week')}}
        </button>
        |
        <button
          class="btn btn-small btn-clear"
          v-bind:class="{ active: activeChart === 2 }"
          @click="onPress(2)"
        >
          {{t('graph.month')}}
        </button>
        </div>
        | 
        <button
          class="btn btn-small btn-clear"
          id="trend-line"
          v-bind:class="{ active: trendLine }"
          @click="showTrendLine()"
        >
          {{t('graph.trend_line')}}
        </button>
      </div>
    </nav>
    <div class="container-full">
        <chart-daily
          v-if="activeChart === 0"
          :dataRange="100"
          :oneDayData="oneDayData"
          tag="generalWellness"
          referenceTag="generalWellness"
          :title="t('vital_signs.general_wellness.title')"
        />
        <chart-weekly
          v-if="activeChart === 1"
          :dataRange="100"
          :oneWeekData="oneWeekData"
          tag="generalWellness"
          referenceTag="generalWellness"
          :linearReg="linearReg"
          :trendLine="trendLine"
          :anomaly="getAnomaly"
          :title="t('vital_signs.general_wellness.title')"
        />
        <chart-monthly
          v-if="activeChart === 2"
          :dataRange="100"
          :oneMonthData="oneMonthData"
          tag="generalWellness"
          referenceTag="generalWellness"
          :movingAverage="movingAverage"
          :anomaly="getAnomaly"
          :trendLine="trendLine"
          :title="t('vital_signs.general_wellness.title')"
        />
    </div>
  </div>
</template>

<script>
import ChartDaily from "./chart-daily.vue";
import ChartWeekly from "./chart-weekly.vue";
import ChartMonthly from "./chart-monthly.vue";
import { checkOutlier, interquartile_bound } from "./statistics";
import { useI18n } from 'vue-i18n';
import { newUserManager } from '../../user/company-user-manager.ts';

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default {
  props: {
    oneDayData: Array,
    oneWeekData: Array,
    oneMonthData: Array,
    lastScan: Object,
  },
  setup() {
    const { t, locale } = useI18n()
    return {
      t, locale
    }
  },
  components: {
    ChartDaily,
    ChartWeekly,
    ChartMonthly,
  },
  data() {
    return {
      trendLine: true,
      activeChart: 2,
      userType: undefined
    };
  },
  async created() {
    const currentUser = newUserManager.currentUser;
    if (currentUser) {
      this.userType = currentUser.userSettings.planType;
    }
    // const currentUser = UserManager.currentUser();
    // if(currentUser && currentUser.userPlan) {
    //   this.userType = currentUser.userPlan.plan_type;
    // }
  },
  methods: {
    showTrendLine() {
      this.trendLine = !this.trendLine;
    },
    onPress(idx) {
      this.activeChart = idx;
    },
  },
  computed: {
    generalWellness() {
      if (this.lastScan == {} || !this.lastScan) {
        return "-";
      } else if (!this.lastScan.vital_sign.generalWellness) {
        return "-";
      } else {
        return this.lastScan.vital_sign.generalWellness.toFixed(1).toString();
      }
    },    
    linearReg() {
      if(this.oneWeekData === undefined) {
        return;
      }
      let x = [];
      let y = [];
      for (let i = 0; i < this.oneWeekData.length; i++) {
        if(!isNaN(this.oneWeekData[i]['generalWellness']) && this.oneWeekData[i]['generalWellness'] !== null) {
          x.push(i);
          y.push(this.oneWeekData[i]['generalWellness']);
        }
      }
      var lr = {};
      var n = y.length;
      var sum_x = 0;
      var sum_y = 0;
      var sum_xy = 0;
      var sum_xx = 0;
      var sum_yy = 0;

      for (var i = 0; i < y.length; i++) {
        sum_x += x[i];
        sum_y += y[i];
        sum_xy += x[i] * y[i];
        sum_xx += x[i] * x[i];
        sum_yy += y[i] * y[i];
      }

      lr["slope"] = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
      lr["intercept"] = (sum_y - lr.slope * sum_x) / n;
      lr["r2"] = Math.pow(
        (n * sum_xy - sum_x * sum_y) /
          Math.sqrt(
            (n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)
          ),
        2
      );
      return [[0,1,2,3,4,5,6].map((a) => {return {x:a, y:a*lr.slope+lr.intercept}}), lr];
    },
    movingAverage() {
      let window = 3;
      if (this.oneMonthData === undefined) {
        return;
      }
        let result = this.oneMonthData.map((d, index) => {
        
        let sum = d['generalWellness'] == null ? NaN : d['generalWellness'];
        let count = 1;
        if (index < window) {
          for (let i = index-1; i >=0 ; i--) {
            if(!isNaN(this.oneMonthData[i]['generalWellness']) && this.oneMonthData[i]['generalWellness'] !== null) {
              sum += this.oneMonthData[i]['generalWellness'];
              count++;
            }
          }
        } else {
          let i = 1;
          do {
            if (!isNaN(this.oneMonthData[index-i]['generalWellness']) && this.oneMonthData[index-i]['generalWellness'] !== null) {
              sum += this.oneMonthData[index-i]['generalWellness'];
              count++;
            }
            i++;
            if (count == window) {
              break;
            }
          }
          while (index - i >= 0)
        }
          return sum/count;
          
      })
      return result;
    },
    getAnomaly() {
      if (this.oneMonthData === undefined) {
        return;
      }
      const iqr_outlier = []

      const tag = 'generalWellness'
      const iqr = interquartile_bound(this.oneMonthData, tag);

      this.oneMonthData.map((data, index) => {
          let date = new Date();
          date.setDate(date.getDate()-29+index)
          if (checkOutlier(data[tag], iqr)) {iqr_outlier.push(date.toString().slice(3,15));}
        })
      return iqr_outlier;
    },
    weekData() {
      let scores;
      if (this.oneWeekData) {
        scores = this.oneWeekData.map((vitalSign) => {
          if (!vitalSign) {
            return 0.0;
          }
          if (!vitalSign.generalWellness) {
            return 0.0;
          }
          return vitalSign.generalWellness / 100.0;
        });
      } else {
        scores = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0];
      }

      return [6, 5, 4, 3, 2, 1, 0].map((day, index) => {
        var date = new Date();
        date.setDate(date.getDate() - day);

        var dateString = monthNames[date.getMonth()] + " " + date.getDate();
        return {
          date: dateString,
          score: scores[index],
        };
      });
    },
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  width: calc(100% - 137px);
  left: 112px;
  height: inherit;
  top: 89px;
  background-color: rgba(155, 145, 145, 0.694);
  z-index: 1;
  border-radius: 10px;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subscribe {
  opacity: 0.8  ;
}
.general-wellness {
  margin-right: 40px;
  margin-left: 40px;
  margin-bottom: 40px;

  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 20px;
}
.container {
  margin-top: 20px;
  margin-bottom: 20px;
}
.container-full {
  height: 500px
}
.title {
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-right: 30px;
  color: #f5b33b;
}
.overall-score {
  display: inline-block;
  width: 50px;
  height: 29px;
  border: 3px solid #00d700;
  box-sizing: border-box;
  border-radius: 10px;
}
.button-group {
  display: inline-block;
}

.button-group > div > .active, .button-group > .active {
  background: #f5b33b;
}

@media (max-width: 768px) {
  .overlay {
    left: 25px;
    width: calc(100% - 50px);
    top: 134px;
  }
}
@media (max-width: 1053px){
  .container-full {
    height: 600px;
  }
}
</style>
