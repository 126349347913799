<template>
  <div class="chart-container" ref="resizeRef">
    <svg ref="svgRef"></svg>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import * as d3 from "d3";
import { timeFormat } from "d3-time-format";
import { lineChunked } from 'd3-line-chunked'
import useResizeObserver from "./resizeObserver";
import { useI18n } from 'vue-i18n';
import { checkCondition, colors, getRange } from "./vitalRangeChecker";
import { newUserManager } from "../../user/company-user-manager.ts";

export default {
  name: "BarChart",
  props: {
    data: Array,
    dataRange: Number,
    tag: String,
    trendData: Array,
    activeChart: Number,
    anomaly: Array,
    trendLine: Boolean,
    title: String,
  }, 
  data() {
    return {
      chart: null,
    };
  },

  setup(props) {
    const { t, locale } = useI18n()

    const svgRef = ref(null);
    const { resizeRef, resizeState } = useResizeObserver();
    // const currentUser = UserManager.currentUser();
    const currentUser = newUserManager.currentUser;

    // Wait until DOM elements are mounted
    onMounted(async() => {
      const svg = d3.select(svgRef.value);
      // if (currentUser && !currentUser.userPlan) {
      //   await currentUser._readUserPlan();
      // }
      // Triggered on UI rerender
      watchEffect(() => {
        // Wipe all groups to clear the content of the svg
        svg.selectAll("*").remove();

        // Recalculate size of the graphs
        var { width, height } = resizeState.dimensions;
        if (window.innerWidth > 768) {
          if ( width < 660 ) {
            height = height * 0.85;
          }
          else if ( width < 900 ) {
            height = height * 0.9;
          }
          else {
            height = 400
          }
        } else {
          if ( width < 395 && props.title == t('vital_signs.general_wellness.title')) {
            height = height * 0.78;
          }
          else if ( width < 660 ) {
            height = height * 0.85;
          }
          else if ( width < 900 ) {
            height = height * 0.9;
          }
        }
        const margin =
          5 * parseFloat(getComputedStyle(document.documentElement).fontSize);
        var chartWidth;
        if (width > 900) {
          chartWidth = 11/12*(width - 2 * margin);
        } else if (width > 294){
          chartWidth = (width - margin*0.7);
        } else {
          chartWidth = 240;
        }
        const chartHeight = height - 1.1 * margin;
        const yRange = props.dataRange ? props.dataRange : 100;
        var yMax, yMin;

        if(props.tag === 'bloodPressure'){
            var systolic_max = d3.max(props.data.concat(props.trendData), function(d) { return d.score.systolic; })
            var systolic_min = d3.min(props.data.concat(props.trendData), function(d) { return d.score.systolic; })
            var diastolic_max = d3.max(props.data.concat(props.trendData), function(d) { return d.score.diastolic; })
            var diastolic_min = d3.min(props.data.concat(props.trendData), function(d) { return d.score.diastolic; })
            yMax = Math.round(systolic_max*yRange*10)/10+0.05*Math.round(systolic_max*yRange*10)/10;
            yMin = Math.round(diastolic_min*yRange*10)/10-0.05*Math.round(diastolic_min*yRange*10)/10;
        }
        else {
            var max = d3.max(props.data.concat(props.trendData), function(d) { return d.score; })
            var min = d3.min(props.data.concat(props.trendData), function(d) { return d.score; })
            yMax = Math.round(max*yRange*10)/10+0.05*Math.round(max*yRange*10)/10;
            yMin = Math.round(min*yRange*10)/10-0.05*Math.round(min*yRange*10)/10;
        }

        
        if (width !== 0 && height !== 0 && props.data) {
          const chart = svg
            .append("g")
            .attr("transform", `translate(${width > 900 ? margin : margin*0.5}, ${margin * 0.4})`);

          // Create element accessors
          const xAccessor = (d) => (d.date ? d.date : d.time);
          const yAccessor = (d) => d.score * yRange;

          // Create the axes
          const xScale = d3
            .scaleBand()
            .domain(props.data.map((x) => xAccessor(x)))
            .range([0, chartWidth])
            .padding(1);
          const xScale_lr = d3
            .scaleBand()
            .domain(props.trendData.map((x) => xAccessor(x)))
            .range([0, chartWidth])
            .padding(0.4);
          const yScale = d3
            .scaleLinear()
            .domain([yMin < 0 ? 0 : yMin , yMax > props.dataRange ? props.dataRange : yMax])
            .range([chartHeight, 0]);
          
          let fmt = props.data[0].date ? timeFormat("%d %b") : timeFormat("%H");

          chart
            .append("g")
            .attr("transform", `translate(0, ${chartHeight})`)
            .attr("class", "axis")
            .call(
              d3
                .axisBottom(xScale)
                .ticks(d3.timeDay)
                .tickFormat((x, i) => {
                  let tick = fmt(x);
                  if (width > 900) {
                    if (props.data.length > 10) {
                      tick = i % 3 == 2 ? tick : "";
                    }
                  } else if (width < 900 && width > 358) {
                    if (props.data.length < 10) {
                      tick = i % 3 == 0 ? tick : "";
                    } else {
                      tick = i % 7 == 5 ? tick : "";
                    }
                  } else if (width < 358) {
                    if (props.data.length < 10) {
                      tick = i % 4 == 0 ? tick : "";
                    } else {
                      tick = i % 10 == 5 ? tick : "";
                    }
                  }
                  return tick;
                })
            );
              
          chart
            .append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(yScale));

          if( width > 900 ) { 
          // x axis label
            svg
              .append("text")
              .attr("class", "label")
              .attr("x", chartWidth / 2 + margin)
              .attr("y", chartHeight + margin * 1.05)
              .attr("text-anchor", "middle")
              .text(t('graph.time'));

            // y axis label
            svg
              .append("text")
              .attr("class", "label")
              .attr("x", -(chartHeight / 2) - margin)
              .attr("y", margin * 0.25)
              .attr("transform", "rotate(-90)")
              .attr("text-anchor", "middle")
              .text(t('graph.measurement'));
          }

          // legend
          if (width > 900) {
            svg
              .append("linearGradient")
                .attr("id", "legend-gradient")
                .attr("x1", 0)
                .attr("y1", 1)
                .attr("x2", 0)
                .attr("y2", 0)
                .selectAll("stop")
                  .data([
                    {offset: 0, color: "#F12f00"},
                    {offset: 0.5, color: "#aeeb34"},
                    {offset: 1, color: "#00D700"}
                  ])
                .enter().append("stop")
                  .attr("offset", function(d) { return d.offset; })
                  .attr("stop-color", function(d) { return d.color; });

            svg
              .append("text")
              .attr("class", "legend")
              .attr("x", chartWidth + 2 * margin)
              .attr("y", 2/3*margin)
              .attr("text-anchor", "left")
              .text(t('graph.excellent'));

            svg
              .append("text")
              .attr("class", "legend")
              .attr("x", chartWidth + 2 * margin)
              .attr("y", chartHeight*2/3)
              .attr("text-anchor", "left")
              .text(t('graph.good'));

            svg
              .append("text")
              .attr("class", "legend")
              .attr("x", chartWidth + 2 * margin)
              .attr("y", chartHeight+1/2*margin)
              .attr("text-anchor", "left")
              .text(t('graph.poor'));

            svg
              .append("rect")
              .attr("class", "legend")
              .attr("x", chartWidth + 5/3 * margin)
              .attr("y", 1/2*margin)
              .attr("width", "15")
              .attr("height", chartHeight)
              .style("fill", "url(#legend-gradient)");
          } else {
          svg
            .append("linearGradient")
              .attr("id", "legend-gradient")
              .attr("x1", 1)
              .attr("y1", 0)
              .attr("x2", 0)
              .attr("y2", 0)
              .selectAll("stop")
                .data([
                  {offset: 0, color: "#F12f00"},
                  {offset: 0.5, color: "#aeeb34"},
                  {offset: 1, color: "#00D700"}
                ])
              .enter().append("stop")
                .attr("offset", function(d) { return d.offset; })
                .attr("stop-color", function(d) { return d.color; });

          svg
            .append("text")
            .attr("class", "legend")
            .attr("x", margin*0.5)
            .attr("y", chartHeight + 1.5 * margin)
            .attr("text-anchor", "left")
            .text(t('graph.excellent'));
 
          svg
            .append("text")
            .attr("class", "legend")
            .attr("x", 0.5 * chartWidth + 0.35 * margin)
            .attr("y", chartHeight + 1.5 * margin)
            .attr("text-anchor", "left")
            .text(t('graph.good'));

          svg
            .append("text")
            .attr("class", "legend")
            .attr("x", chartWidth-0.3*margin)
            .attr("y", chartHeight+ 1.5 * margin)
            .attr("text-anchor", "left")
            .text(t('graph.poor'));

          svg
            .append("rect")
            .attr("class", "legend")
            .attr("x", margin*0.5)
            .attr("y", chartHeight + 1 * margin)
            .attr("width", chartWidth*0.95)
            .attr("height", "15")
            .style("fill", "url(#legend-gradient)");
          }

          // gridlines in y axis function
          const make_y_gridlines = () => {		
              return d3.axisLeft(yScale)
                  .ticks(4, "+f")
          }

          // add the Y gridlines
          chart.append("g")			
              .attr("class", "grid")
              .attr("opacity", 0.15)
              .call(make_y_gridlines()
                  .tickSize(-chartWidth)
                  .tickFormat("")
                  )


          if (props.tag === "bloodPressure") {
            // Systolic line

            if(systolic_min !== systolic_max) {
            chart.append("linearGradient")
              .attr("id", "systolic-line-gradient")
              .attr("x1", 0)
              .attr("y1", 1)
              .attr("x2", 0)
              .attr("y2", 0)
              .selectAll("stop")
                .data(getRange("bloodPressureSystolic", systolic_min, systolic_max, props.dataRange))
              .enter().append("stop")
                .attr("offset", function(d) { return d.offset; })
                .attr("stop-color", function(d) { return d.color; });
            }

            var systolicLine = lineChunked()
                .defined(function (d) { return !isNaN(d.score.systolic); })
                .x((g) => xScale(xAccessor(g)))
                .y((g) => yScale(g.score.systolic*yRange))
                //curve
              .lineStyles({stroke: systolic_min===systolic_max ? colors[checkCondition('bloodPressureSystolic', systolic_min*props.dataRange)]:"url(#systolic-line-gradient)", "stroke-width":"3"})
                .curve(d3.curveCardinal)

            chart
              .append('g')
              .attr('id', 'systolicLine')
              .datum(props.data)
              .call(systolicLine)

            // dots 
            chart
              .append('g')
              .selectAll("dot")
              .data(props.data.filter(g => !isNaN(g.score.systolic)))
              .enter()
              .append("circle")
              .attr("cx", (g) => xScale(xAccessor(g)) )
              .attr("cy", (g) => yScale(g.score.systolic*yRange) )
              .attr("r", (g) => {
                      if (props.anomaly !== undefined) {
                        if (props.anomaly.find(element => element == g.date.toString().slice(3,15)) !== undefined) {
                          return 7;
                        } 
                      } 
                      return 4; 
                    })
              .style("fill", (g) => {
                      if (props.anomaly !== undefined) {
                        if (props.anomaly.find(element => element == g.date.toString().slice(3,15)) !== undefined) {
                          return "#8B0000";
                        } 
                      }
                      return colors[checkCondition('bloodPressureSystolic', g.score.systolic*yRange)];
                    })

              if (props.activeChart === 1) {
                if(props.trendLine) {
                  chart
                  .append('path')
                  .attr("class", "trend-line")
                  .attr("fill", "none")
                  .attr("stroke", "gray")
                  .style("opacity", 0.4)
                  .attr("stroke-width", 3)
                  .datum(props.trendData.filter(g => !isNaN(g.score.diastolic)))
                  .attr("d", d3.line()
                    .x((g) => xScale_lr(xAccessor(g))+ xScale_lr.bandwidth() / 2)
                    .y((g) => yScale(g.score.systolic*yRange))
                    );
                  }                
                }
            if( props.activeChart === 2 ) {
                if (props.trendLine) {
                  chart
                    .append('path')
                    .attr("class", "trend-line")
                    .attr("fill", "none")
                    .style("opacity", 0.4)
                    .attr("stroke", "#666666")
                    .attr("stroke-width", 2)
                    .datum(props.trendData.filter(g => !isNaN(g.score.diastolic)))
                    .attr("d", d3.line()
                      .defined(function (d) { return !isNaN(d.score.systolic); })
                      .x((g) => xScale_lr(xAccessor(g))+ xScale_lr.bandwidth() / 2)
                      .y((g) => yScale(g.score.systolic*yRange))
                      .curve(d3.curveCardinal)
                    );
                }
              }

            // // Diastolic line

            if(diastolic_min !== diastolic_max) {
            chart.append("linearGradient")
              .attr("id", "diastolic-line-gradient")
              .attr("x1", 0)
              .attr("y1", 1)
              .attr("x2", 0)
              .attr("y2", 0)
              .selectAll("stop")
                .data(getRange("bloodPressureDiastolic", diastolic_min, diastolic_max, props.dataRange))
              .enter().append("stop")
                .attr("offset", function(d) { return d.offset; })
                .attr("stop-color", function(d) { return d.color; });
            }

            var diastolicLine = lineChunked()
                .defined(function (d) { return !isNaN(d.score.diastolic); })
                .x((g) => xScale(xAccessor(g)))
                .y((g) => yScale(g.score.diastolic*yRange))
                //curve
              .lineStyles({stroke: diastolic_min===diastolic_max ? colors[checkCondition('bloodPressureDiastolic', diastolic_min*props.dataRange)]:"url(#diastolic-line-gradient)", "stroke-width":"3"})
                .curve(d3.curveCardinal)

            chart
              .append('g')
              .attr('id', 'diastolicLine')
              .datum(props.data)
              .call(diastolicLine)

            chart
              .append('g')
              .selectAll("dot")
              .data(props.data.filter(g => !isNaN(g.score.diastolic)))
              .enter()
              .append("circle")
              .attr("cx", (g) => xScale(xAccessor(g)) )
              .attr("cy", (g) => yScale(g.score.diastolic*yRange) )
              .attr("r", (g) => {
                      if (props.anomaly !== undefined) {
                        if (props.anomaly.find(element => element == g.date.toString().slice(3,15)) !== undefined) {
                          return 7;
                        } 
                      } 
                      return 4; 
                    })
              .style("fill", (g) => {
                      if (props.anomaly !== undefined) {
                        if (props.anomaly.find(element => element == g.date.toString().slice(3,15)) !== undefined) {
                          return "#8B0000";
                        } 
                      }
                      return colors[checkCondition('bloodPressureDiastolic', g.score.diastolic*yRange)];
                    })

              if (props.activeChart === 1) {
                if(props.trendLine) {
                  chart
                  .append('path')
                  .attr("class", "trend-line")
                  .attr("fill", "none")
                  .style("opacity", 0.4)
                  .attr("stroke", "gray")
                  .attr("stroke-width", 3)
                  .datum(props.trendData.filter(g => !isNaN(g.score.diastolic)))
                  .attr("d", d3.line()
                    .x((g) => xScale_lr(xAccessor(g))+ xScale_lr.bandwidth() / 2)
                    .y((g) => yScale(g.score.diastolic*yRange))
                    );
                  }                
                }
              if( props.activeChart === 2 ) {
                if (props.trendLine) {
                  chart
                    .append('path')
                    .attr("class", "trend-line")
                    .attr("fill", "none")
                    .attr("stroke", "#666666")
                    .attr("stroke-width", 2)
                    .style("opacity", 0.4)
                    .datum(props.trendData.filter(g => !isNaN(g.score.diastolic)))
                    .attr("d", d3.line()
                      .x((g) => xScale_lr(xAccessor(g))+ xScale_lr.bandwidth() / 2)
                      .y((g) => yScale(g.score.diastolic*yRange))
                      .curve(d3.curveCardinal)
                    );
                }
              }

            //tooltip    
            let focus = svg.append("g")
                .attr("class", "focus")
                .style("display", "none");


            chart.append("path") 
              .attr("class", "mouse-line")
              .style("stroke", "#A9A9A9")
              .style("stroke-width", "2px")
              .style("opacity", "0");


            let systolic_circle = focus.append("circle")
                .attr("id", "systolic-tooltip-circle")
                .attr("cx", width > 900 ? margin : margin*0.5)
                .attr("cy", margin*0.4)
                .attr("r", 5);

            let diastolic_circle = focus.append("circle")
            .attr("id", "diastolic-tooltip-circle")
            .attr("cx", width > 900 ? margin : margin*0.5)
            .attr("cy", margin*0.4)
            .attr("r", 5);
        
            let path = focus.append("path")
              .attr("fill", "white")
              .attr("stroke", "black");

            let text = focus.append("text")
            
            text.append("tspan")
                .attr("class", "tooltip-date")
                .attr("x", width > 900 ? margin : margin*0.5)
                .attr("y", margin*0.75)

            text.append("tspan")
                .attr("class", "tooltip-measurement")
                .attr("x", width > 900 ? margin : margin*0.5)
                .attr("y", margin)

            let bisectDate = d3.bisector(function(d) { return xAccessor(d); }).left;

            const scaleBandInvert = (scale) => {
              var eachBand = scale.step();
              return function (value) {
                var index = Math.floor(((value) / eachBand));
                return scale.domain()[index];
              }
            }
                svg.append("rect")
                    .attr("class", "overlay")
                    .attr("x", width > 900 ? margin : margin*0.5)
                    .attr("y", margin*0.4)
                    .attr("width", chartWidth)
                    .attr("height", chartHeight)
                    .on("mouseover", function() { focus.style("display", null); })                
                    .on("mouseout", function() { 
                      focus.style("display", "none"); 
                      d3.select(".mouse-line")
                        .style('opacity', 0);
                      })
                    .on("mousemove", ((event) => {
                      var x0 = scaleBandInvert(xScale)(d3.pointer(event)[0]-(width > 900 ? 1.3 : 0.5)*margin),
                        index = bisectDate(props.data, x0, 1),
                        i;
                      if (index === 0)  i = 1;
                      if (index === props.data.length) return;
                      else  i = index; 
                      var d0 = props.data[i - 1],
                        d1 = props.data[i],
                        d = x0 - xAccessor(d0) > xAccessor(d1) - x0 ? d1 : d0;
                    if (isNaN(d0.score.systolic)) {
                      d = d1;
                    } else if (isNaN(d1.score.systolic)) {
                      d = d0;
                    } 
                    if (isNaN(d0.score.systolic) && isNaN(d1.score.systolic)) {
                      return;
                    }
                    systolic_circle.attr("transform", "translate(" + xScale(xAccessor(d)) + "," + yScale(d.score.systolic*yRange) + ")");
                    diastolic_circle.attr("transform", "translate(" + xScale(xAccessor(d)) + "," + yScale(d.score.diastolic*yRange) + ")");
                    focus.select(".tooltip-date").text(fmt(xAccessor(d)));
                    focus.select(".tooltip-measurement").text(`${props.title}: ${Math.round(d.score.systolic * yRange * 10) / 10 + "/" + Math.round(d.score.diastolic * yRange * 10) / 10}`);
                    systolic_circle.style("fill", colors[checkCondition("bloodPressureSystolic", d.score.systolic * yRange)])
                    diastolic_circle.style("fill", colors[checkCondition("bloodPressureDiastolic", d.score.diastolic * yRange)])
                    const {y,  width: w, height: h} = text.node().getBBox();
                    text.attr("transform", `translate(${xScale(xAccessor(d))+ -w / 2},${yScale(d.score.systolic*yRange)+0.15*y})`);
                    path.attr("d", `M${-w / 2 + (width > 900 ? 1 : 0.5) * margin- 10},45H${width > 900 ? 75 : 35}l5,-6l5,6h${w / 2 + 10}v${h + 20}H${-w / 2 + (width > 900 ? 1 : 0.5) *margin- 10}z`);
                    path.attr("transform", `translate(${xScale(xAccessor(d))},${yScale(d.score.systolic*yRange)})`);
                    path.attr("stroke", colors[checkCondition(props.tag, d.score.systolic * yRange + "/" + d.score.diastolic * yRange)]);
                    d3.select(".mouse-line")
                        .attr("d", function () {
                          var data = "M" + xScale(xAccessor(d)) + "," + (chartHeight);
                          data += " " + xScale(xAccessor(d)) + "," + 0;
                          return data;
                        })
                        .style("opacity", 1);
                    }));
          } else {

            // All other lines

            if(max !== min) {
              chart.append("linearGradient")
                .attr("id", "line-gradient")
                .attr("x1", 0)
                .attr("y1", 1)
                .attr("x2", 0)
                .attr("y2", 0)
                .selectAll("stop")
                  .data(getRange(props.tag, min, max, props.dataRange))
                .enter().append("stop")
                  .attr("offset", function(d) { return d.offset; })
                  .attr("stop-color", function(d) { return d.color; });
              }

            var line = lineChunked()
                .defined(function (d) { return !isNaN(yAccessor(d)); })
                .x((g) => xScale(xAccessor(g)))
                .y((g) => yScale(yAccessor(g)))
                //curve
              .lineStyles({stroke: min===max ? colors[checkCondition(props.tag, min*props.dataRange)]:"url(#line-gradient)", "stroke-width":"3"})
                .curve(d3.curveCardinal)

            chart
              .append('g')
              .datum(props.data)
              .call(line)
                
            // dots
            chart
              .append('g')
              .selectAll("dot")
              .data(props.data.filter(g => !isNaN(g.score)))
              .enter()
              .append("circle")
              .attr("cx", (g) => xScale(xAccessor(g)) )
              .attr("cy", (g) => yScale(yAccessor(g)) )
              .attr("r", (g) => {
                      if (props.anomaly !== undefined) {
                        if (props.anomaly.find(element => element == g.date.toString().slice(3,15)) !== undefined) {
                          return 7;
                        } 
                      } 
                      return 4; 
                    })
              .style("fill", (g) => {
                      if (props.anomaly !== undefined) {
                        if (props.anomaly.find(element => element == g.date.toString().slice(3,15)) !== undefined) {
                          return "#8B0000";
                        } 
                      }
                      return colors[checkCondition(props.tag, yAccessor(g))];
                    })

            //tooltip    
            let focus = svg.append("g")
              .attr("class", "focus")
              .style("display", "none");

            chart.append("path") 
            .attr("class", "mouse-line")
            .style("stroke", "#A9A9A9")
            .style("stroke-width", "2px")
            .style("opacity", "0");

            focus.append("circle")
              .attr("id", "tooltip-circle")
              .attr("cx", width > 900 ? margin : margin*0.5)
              .attr("cy", margin*0.4)
              .attr("r", 5);
        
            let path = focus.append("path")
              .attr("fill", "white")
              .attr("stroke", "black");

            let text = focus.append("text")
            
            text.append("tspan")
                .attr("class", "tooltip-date")
                .attr("x", width > 900 ? margin : margin*0.5)
                .attr("y", margin*0.75)

            text.append("tspan")
                .attr("class", "tooltip-measurement")
                .attr("x", width > 900 ? margin : margin*0.5)
                .attr("y", margin)

            let bisectDate = d3.bisector(function(d) { return xAccessor(d); }).left;

            const scaleBandInvert = (scale) => {
              var eachBand = scale.step();
              return function (value) {
                var index = Math.floor(((value) / eachBand));
                return scale.domain()[index];
              }
            }

            if (currentUser) {
              if(currentUser.userSettings.planType !== 'free' || props.tag !== 'generalWellness') {
                svg.append("rect")
                    .attr("class", "overlay")
                    .attr("x", width > 900 ? margin : margin*0.5)
                    .attr("y", margin*0.4)
                    .attr("width", chartWidth)
                    .attr("height", chartHeight)
                    .on("mouseover", function() { focus.style("display", null); })
                    .on("mouseout", function() { 
                      focus.style("display", "none"); 
                      d3.select(".mouse-line")
                        .style('opacity', 0);
                      })
                    .on("mousemove", ((event) => {
                      var x0 = scaleBandInvert(xScale)(d3.pointer(event)[0]-(width > 900 ? 1.3 : 0.5)*margin),
                        index = bisectDate(props.data, x0, 1),
                        i
                      if (index === 0)  i = 1;
                      if (index === props.data.length)  return;
                      else  i = index; 
                      var d0 = props.data[i - 1],
                        d1 = props.data[i],
                        d = x0 - xAccessor(d0) > xAccessor(d1) - x0 ? d1 : d0;
                    if (isNaN(yAccessor(d0))) {
                      d = d1;
                    } else if (isNaN(yAccessor(d1))) {
                      d = d0;
                    } 
                    if (isNaN(yAccessor(d0)) && isNaN(yAccessor(d1))) {
                      return;
                    }
                    else {
                    focus.attr("transform", "translate(" + xScale(xAccessor(d)) + "," + yScale(yAccessor(d)) + ")");
                    const color = colors[checkCondition(props.tag, yAccessor(d))];
                    focus.select(".tooltip-date").text(fmt(xAccessor(d)));
                    focus.select(".tooltip-measurement").text(`${props.title}: ${Math.round(yAccessor(d) * 10) / 10}`);
                    focus.select("#tooltip-circle").style("fill", color)
                    
                    const {y, width: w, height: h} = text.node().getBBox();
                    text.attr("transform", `translate(${-w / 2},${-1.6*y})`);
                    if(yScale(yAccessor(d)) > h) {
                      path.attr("d", `M ${-w/2+(width > 900 ? 1 : 0.5)*margin-10} 20 H ${width > 900 ? 75 : 35} l 5 6 l 5 -6 h ${w/2+10} v ${-h - 15} H ${-w/2+(width > 900 ? 1 : 0.5)*margin-10} z`);
                    }
                    else {
                      path.attr("d", `M${-w / 2 + (width > 900 ? 1 : 0.5)*margin- 10},45H${width > 900 ? 75 : 35}l5,-6l5,6h${w / 2 + 10}v${h + 20}H${-w / 2 + (width > 900 ? 1 : 0.5)*margin- 10}z`);
                      text.attr("transform", `translate(${-w / 2},${0.15*y})`);
                    }
                    path.attr("stroke", color);
                    }
                    d3.select(".mouse-line")
                        .attr("d", function () {
                          var data = "M" + xScale(xAccessor(d)) + "," + (chartHeight);
                          data += " " + xScale(xAccessor(d)) + "," + 0;
                          return data;
                        })
                        .style("opacity", 1);
                    }));
              }
            }

              if (props.activeChart === 1) {
                if(props.trendLine) {
                  chart
                  .append('path')
                  .attr("class", "trend-line")
                  .attr("fill", "none")
                  .style("opacity", 0.4)
                  .attr("stroke", "gray")
                  .attr("stroke-width", 3)
                  .datum(props.trendData.filter(g => !isNaN(g.score)))
                  .attr("d", d3.line()
                    .x((g) => xScale_lr(xAccessor(g))+ xScale_lr.bandwidth() / 2)
                    .y((g) => yScale(yAccessor(g)))
                    );
                  }                
                }
              if( props.activeChart === 2 ) {
                if (props.trendLine) {
                  chart
                    .append('path')
                    .attr("class", "trend-line")
                    .attr("fill", "none")
                    .attr("stroke", "#666666")
                    .style('opacity', 0.4)
                    .attr("stroke-width", 2)
                    .datum(props.trendData.filter(g => !isNaN(g.score)))
                    .attr("d", d3.line()
                      .x((g) => xScale_lr(xAccessor(g))+ xScale_lr.bandwidth() / 2)
                      .y((g) => yScale(yAccessor(g)))
                      .curve(d3.curveCardinal)
                      );
                  }
                }
          }
        }
      });
    });
    return { svgRef, resizeRef, resizeState, t, locale };
  },
};
</script>
<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
  padding : 0px;
  overflow-x: visible;
}
svg {
  /* important for responsiveness */
  display: inline;
  width: 100%;
  height: 100%;
  overflow: visible;
}

svg text {
  fill: white;
  font: 10px sans-serif;
  text-anchor: end;
}

svg /deep/ .axis text {
  font-size: 1rem;
  font-weight: 600;
}

svg /deep/ .value-text {
  fill: #f5b33b;
  font-weight: 700;
  font-size: 1rem;
}

svg /deep/ .label {
  fill: #f5b33b;
  font-weight: 600;
  font-size: 1.1rem;
}

svg /deep/ .legend {
  font-size: 1rem;
}

svg /deep/ .overlay {
    fill: none;
    pointer-events: all;
}

svg /deep/ .focus text {
    font-size: 14px;
}

svg /deep/ #tooltip {
    fill: white;
}

svg /deep/ .tooltip-date, .tooltip-measurement {
    font-weight: bold;
}

@media (max-width: 1053px){
  .chart-container {
    height: 100%;
  }
}
</style>
