<template>
  <div class="homepage">
    <!-- <HomePageOnboard /> -->
    <LoadingScreen
      v-if="
        !Object.keys(this.$root.$data.doneLoading).every((key) => {
          return this.$root.$data.doneLoading[key];
        })
      "
    />
    <!-- <nav ref="nav" class="navbar fixed-top navbar-light"> -->
    <!--
      <div class="video" id="standby-screen" v-if="sysStatus=='standby'">
        <p v-if="!starting" class="system-message text-center">{{ message || t('message.startup')}}</p>
        <p v-if="starting" class="system-message text-center">{{ message || t('message.loading')}}</p>
      </div>
      -->

    <!-- button navigation -->
    <div class="buttons">
      <!-- <img class="quick-help-button btn navbar-btn mx-2 my-4" 
        src="./assets/help_icon.svg" 
        v-on:click="showGuide"/> -->
      <!-- <div
        class="btn navbar-btn mx-2 my-4 disableBox"
        data-bs-toggle="modal"
        data-bs-target="#quickTutorialModal"
      > -->
      <div
        class="btn navbar-btn mx-2 my-4"
        data-bs-toggle="modal"
        data-bs-target="#quickTutorialModal"
      >
        <img class="quick-help-button" src="./assets/help_icon.svg" />
      </div>

      <button
        class="navbar-btn mx-2 my-4 mx-lg-0 button-rounded bg-orange color-white"
        id="power-on-button"
        v-show="!starting"
        :disabled="starting"
        @click="startSystem()"
      >
        <span
          v-show="starting"
          class="spinner-border spinner-border-sm button-spinner2"
          role="status"
          aria-hidden="true"
        ></span>
        {{ t("button.power_on") }}
      </button>
      <button
        class="navbar-btn mx-2 my-4 mx-lg-0 button-rounded bg-orange color-white"
        v-show="starting"
        :disabled="!starting || vsStatus == 'measuring'"
        v-on:click="stopSystem()"
      >
        <span
          v-show="!starting"
          class="spinner-border spinner-border-sm button-spinner2"
          role="status"
          aria-hidden="true"
        ></span>
        {{ t("button.shutdown") }}
      </button>
      <!-- <button
        class="btn btn-light navbar-btn mx-2 my-4 full-screen"
        v-on:click="fullScreen()"
      >
        {{ t("button.full_screen") }}
      </button> -->
    </div>
    <!-- </nav> -->

    <!-- popup tutorial page -->
    <scan-tutorial />

    <!-- popup user profile -->
    <user-profile-input
      v-if="form"
      @updateProfile="updateProfile"
      @updateAgreeTnC="updateAgreeTnC"
      :agreeTnC="agreeTnC"
      :profileFilled="profileFilled"
      :form="form"
    />

    <!-- video container -->
    <div id="video-container" ref="videoContainer">
      <!-- condition checking & instructions -->
      <!-- <div v-if="vsStatus=='measuring'" class="instruction-box">
          {{ t("instruction.stay_still") }}
      </div> -->
      <div>
        <!--
          <web-cam ref='webcam'
              :videoWidth=1920
              :videoHeight=1080
              :videoFrameRate=60
              :enable="starting"
          />
          <bounding-box-view v-if='detectionResult !== undefined && aspectRatio !== undefined' :result='detectionResult' :aspectRatio="aspectRatio"/>
          <face-mesh-view v-if='detectionResult !== undefined && aspectRatio !== undefined' :result='detectionResult' :aspectRatio="aspectRatio"/>
          -->

        <face-detection-view
          ref="face_detection"
          :enable="starting"
          :vsStatus="vsStatus"
          @finishMeasurement="finishMeasure"
          @updateTimeLeft="updateTimeLeft"
          @detectionResult="updateDetectionResult"
          @scanParameters="updateScanParameters"
          :device="devices.length > 1 ? devices[cameraIndex].id : ''"
          :failedScan="failedScan"
          :online="online"
        />
      </div>

      <!-- <div v-if="simulation && sysStatus=='running'">
        <img class="video" src="./assets/dummy_user.png">
        <p v-if="message" class="running-message">{{message}}</p>
      </div>
      <div v-if="!simulation && sysStatus=='running'">
        <video-feed ref="video" class="video" feed-id="CAMERA" :enableReloadTimer="false"/>
        <p v-if="message" class="running-message">{{message}}</p>
      </div>
          <div class="huds-container" v-if="!conditions && vitalSign && vitalSign.spo2 && !stopping">
      -->
      <img id="logo" src="./assets/mannings_logo.png" />
      <!-- signal quality box -->

      <div v-if="failedScan" class="instruction-text">
        <p class="w-75" v-html="t('message.face_lost')">
        </p>
      </div>
      <div class="huds-container" v-if="vitalSign">
        <div class="row gx-0 mb-5 justify-content-between mx-5">
          <signal-quality-box :scanParameters="scanParameters" />
          <wellness
            :data="vitalSign.generalWellness"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
          />
        </div>

        <!-- <div class="huds-container" v-if="starting && vitalSign"> -->
        <div class="row gx-0 mb-5 justify-content-between mx-5">
          <heart-rate :data="vitalSign.heartRate" class="col-2" />
          <facial-skin-age :data="vitalSign.facialSkinAge" class="col-2" />
          <!-- <hrv-ibi :data="vitalSign.ibi" class="col-2" /> -->
        </div>
        <div class="row gx-0 my-5 justify-content-between mx-5">
          <o2-saturation :data="vitalSign.spo2" class="col-2" />
          <!-- <hrv-ibi :data="vitalSign.ibi" class="col-2" /> -->
          <respiratory-rate
            :data="vitalSign.respiratoryRate"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
          />
        </div>
        <div class="row gx-0 my-5 justify-content-between mx-5">
          <hrv-sdnn
            :data="vitalSign.hrvSdnn"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
          />
          <stress-rating
            :data="vitalSign.stress"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
          />
        </div>

        <div class="row gx-0 my-5 justify-content-between mx-5">
          <blood-pressure
            :data="vitalSign.bloodPressure"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
          />
        </div>
        <!--
        <CVDRisk :data="vitalSign.cvd_risk_general"/>
        <covid-risk :data="vitalSign.covid_risk"/>
        <temperature :data="vitalSign.temperature"/>
        <bmi :data="vitalSign.bmi"/>
        <hrv-rmssd :data="vitalSign.hrv_rmssd"/>
        -->
      </div>

      <!-- conditions -->
      <div
        v-if="
          vsStatus != 'measuring' &&
          conditions &&
          sysStatus == 'running' &&
          !stopping
        "
      >
        <conditions-hud :conditions="conditions" />
      </div>
      <!-- Vital Sign Button Row -->
      <div class="info-bar align-items-center" v-if="starting">
        <div class="d-flex flex-column w-30 user-info-buttons">
          <button
            class="btn btn-light info-button"
            type="button"
            @click="showHealthReport"
            :disabled="!profileFilled || vsStatus == 'measuring' || !vitalSign"
          >
            {{ t("button.show_health_report") }}
          </button>
          <button
            class="btn btn-light info-button"
            type="button"
            @click="removeProfile"
            :disabled="!profileFilled || vsStatus == 'measuring'"
          >
            {{ t("button.clear_user_information") }}
          </button>
        </div>
        <div class="">
          <vital-sign-button
            class="vsButton"
            :radius="64"
            :enable="detectionResult"
            :timeLeft="getTimeLeft()"
            :online="online"
            @vsMeasure:start="startMeasurement"
            @vsMeasure:stop="finishMeasure"
          />
        </div>
        <div class="w-30 camera-selection">
          <div
            class="dropup"
            v-if="devices && devices.length > 1 && vsStatus !== 'measuring'"
          >
            <button
              class="camera-button"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src="./assets/switch-camera.svg" alt="" />
            </button>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuButton1"
              role="menu"
            >
              <li v-for="(device, index) in devices" :key="index">
                <div
                  :class="`dropdown-item ${
                    cameraIndex == index ? 'selected-camera' : ''
                  }`"
                  @click="() => switchCamera(index)"
                >
                  {{ device.text }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="copyright text-center">
        <p>{{ t("copyright") }}</p>
      </div>
    </div>

    <side-bar />
  </div>
</template>

<script>
import Logger from "../../common/logger.js";
import { ref, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import api from "../../common/api.js";
import adapter from "webrtc-adapter";
import LoadingScreen from "./loading-screen.vue";
import ConditionsHud from "./conditions-hud.vue";
import FaceDetectionView from "./face-detection-view.vue";
// import HomePageOnboard from "../onboarding/home-page-onboard.vue";
import SideBar from "../sidebar/sidebar.vue";
import ScanTutorial from "./scan-tutorial.vue";
import SignalQualityBox from "./signal-quality-box-floating.vue";
import userProfileInput from "./user-profile-input-lite.vue";
import { newUserManager } from "../../user/company-user-manager.ts";
import VitalSignButton from "./vital-sign-button-js.vue";
// vital signs
import HeartRate from "./vital-signs/heart-rate.vue";
import RespiratoryRate from "./vital-signs/respiratory-rate.vue";
import O2Saturation from "./vital-signs/o2-saturation.vue";
import StressRating from "./vital-signs/stress-rating.vue";
import HrvSdnn from "./vital-signs/hrv-sdnn.vue";
import Wellness from "./vital-signs/wellness.vue";
import BloodPressure from "./vital-signs/blood-pressure.vue";
import FacialSkinAge from "./vital-signs/facial-skin-age.vue";
import { resetMovementArray } from "@/plugin/plugin_helpers.js";
/*
import HrvIbi from "./vital-signs/hrv-ibi.vue";
import CVDRisk from './vital-signs/cvd-risk.vue';
import CovidRisk from './vital-signs/covid-risk.vue';
import Temperature from './vital-signs/temperature.vue';
import bmi from './vital-signs/bmi.vue';
import HrvRmssd from './vital-signs/hrv-rmssd.vue'
*/
// import WebCam from './web-cam.vue';
// import VideoFeed from './video-feed.vue'
// import io from "socket.io-client";  // for python backend

export default {
  name: "App",
  props: {
    simulation: Boolean,
  },
  setup() {
    const { idle, updateIdle } = inject("idle");
    const { profileFilled, updateProfileFilled } = inject("profileFilled");
    const { t, locale } = useI18n();
    const router = useRouter();

    var currentUser = newUserManager.currentUser
      ? newUserManager.currentUser.currentUser
      : null;
    let vitalSign = null;
    let scanParameters = null;
    if (currentUser) {
      vitalSign = currentUser.lastVitalSigns;
      scanParameters = currentUser.lastHealth
        ? currentUser.lastHealth.scanParameters
        : null;
    } else {
      router.push("/");
    }

    return {
      t,
      locale,
      router,
      starting: ref(idle),
      stopping: ref(true),
      sysStatus: ref("standby"),
      vsStatus: ref("idle"),
      flask_fps: ref(),
      vitalSign: ref(vitalSign),
      scanParameters: ref(scanParameters),
      conditions: ref(),
      message: ref(),
      disclaimer: ref(),
      pythonBackend: ref(false),
      timeLeft: ref(null),
      user: ref(currentUser),
      idle,
      updateIdle,
      showTutorial: ref(false),
      profileFilled,
      updateProfileFilled,
    };
  },
  mounted() {
    // console.log(newUserManager.currentUser)
    // console.log(newUserManager.currentUser.currentUser)
    // console.log(newUserManager.currentUser.currentUser.getUserSettings())
    // load user / customer form

    this.online = window.navigator.onLine;

    window.addEventListener("online", () => (this.online = true));
    window.addEventListener("offline", () => (this.online = false));

    setTimeout(async () => {
      let currentUser = newUserManager.currentUser.currentUser;
      if (currentUser) {
        this.form = await currentUser.getUserSettings();
      }
    }, 50);
  },
  beforeUnmount() {
    window.removeEventListener("online", () => (this.online = true));
    window.removeEventListener("offline", () => (this.online = false));
  },
  async created() {
    await this.getUserType();
    if (window.sessionStorage.userSettings) {
      this.form = JSON.parse(window.sessionStorage.userSettings);
    }
    this.checkLocale(this.locale);
  },
  watch: {
    failedScan(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.failedScan = false;
        }, 5000);
      }
    },
    locale(locale) {
      this.checkLocale(locale);
    },
    devices() {
      if (this.devices[0].text !== "") {
        clearInterval(this.checkDevice);
      }
    },
    vsStatus(newValue) {
      if (newValue == "measuring") {
        this.conditions = undefined;
        this.message = undefined;
      }
    },
    sysStatus(status) {
      Logger.log(status);
      Logger.info(`Status changed from "${this.sysStatus}" to "${status}".`);
      if (status == "running") {
        this.starting = false;
        this.message = undefined;
      }

      if (status == "standby") {
        this.stopping = false;
        this.message = undefined;
      }
    },
    healthObject: {
      deep: true,
      handler: function () {
        this.vitalSign = newUserManager.currentUser.currentUser.lastVitalSigns;
      },
    },
  },
  data() {
    return {
      detectionResult: undefined,
      aspectRatio: undefined,
      form: undefined,
      userType: undefined,
      cameraIndex: 0,
      devices: [{ text: "", id: "", deviceIndex: 0 }],
      checkDevice: undefined,
      font: "MElleHK",
      failedScan: false,
      online: true,
      agreeTnC: false,
    };
  },
  async beforeRouteLeave() {
    if (this.starting) {
      await this.$refs.face_detection.stopCamera();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.idle) {
        vm.enumerateDevice();
      }
    });
  },
  methods: {
    updateAgreeTnC(agree) {
      this.agreeTnC = agree;
    },
    checkLocale(locale) {
      if (locale == "En") {
        this.font = "Gilroy";
      } else if (locale == "中文") {
        this.font = "MElleHK";
      }
    },
    enumerateDevice() {
      this.checkDevice = setInterval(async () => {
        let devices = await navigator.mediaDevices.enumerateDevices();
        devices = devices.filter((device) => device.kind === "videoinput");
        this.devices = devices.map((device, index) => {
          return {
            text: device.label,
            id: device.deviceId,
            deviceIndex: index,
          };
        });
      }, 1000);
    },
    switchCamera(index) {
      this.cameraIndex = index;
    },
    async getUserType() {
      setTimeout(() => {
        if (!this.currentUser) return;
        else {
          this.userType = this.currentUser.userSettings.planType;
        }
      }, 2000);
    },
    showGuide() {
      this.showTutorial = !this.showTutorial;
    },
    startMeasurement() {
      // check conditions
      this.vitalSign = null;
      this.scanParameters = null;
      if (newUserManager.currentUser) {
        let currentUser = newUserManager.currentUser.currentUser;
        if (currentUser) {
          currentUser.clearHealth();
        }
      }
      this.vsStatus = "measuring";
      this.failedScan = false;
    },
    updateTimeLeft(newValue) {
      this.timeLeft = newValue;
      // console.log("home-page time left: ", this.timeLeft);
    },
    getTimeLeft() {
      return this.timeLeft;
    },
    finishMeasure(failedScan) {
      this.vsStatus = "idle";
      this.failedScan = failedScan;
      resetMovementArray();
    },
    updateDetectionResult(newValue) {
      this.detectionResult = newValue;
    },
    updateScanParameters(newValue) {
      this.scanParameters = newValue;
    },
    getAspectRatio(ar) {
      this.aspectRatio = ar;
    },
    onResult(result) {
      this.detectionResult = result;
    },
    refreshStatus() {
      api.refreshStatus().then((data) => {
        this.flask_fps = data.fps;

        if (data.status == "running") {
          this.starting = false;
        }

        if (data.status == "standby") {
          this.stopping = false;
        }

        if (this.sysStatus !== data.status) {
          Logger.info(
            `Status changed from "${this.sysStatus}" to "${data.status}".`
          );
        }

        this.sysStatus = data.status;
      });
    },
    startSystem: function () {
      Logger.info("Start button pressed");
      this.enumerateDevice();
      // this.enableSound();

      this.updateIdle();
      this.starting = true;
      this.stopping = false;
      this.conditions = undefined;
      const lastResult = window.sessionStorage.lastHealthResult;
      if (lastResult) {
        newUserManager.currentUser.currentUser.setHealth(JSON.parse(lastResult));
        this.vitalSign = newUserManager.currentUser.currentUser.lastVitalSigns;
        this.scanParameters = newUserManager.currentUser.currentUser.lastHealth.scanParameters;
      } else {
        this.vitalSign = undefined;
      }
      if (this.pythonBackend) {
        api
          .startSystem()
          .then(() => {
            this.refreshStatus();
          })
          .catch((err) => {
            Logger.error(err);
            console.error(err);
            alert(err.message);
            this.starting = false;
          });
        return false;
      } else {
        //this.sysStatus = "running";
        return false;
      }
    },
    stopSystem: function () {
      Logger.info("Stop button pressed.");

      this.updateIdle();
      this.stopping = true;
      this.starting = false;
      this.vitalSign = undefined;
      this.conditions = undefined;
      this.detectionResult = undefined;
      this.vsStatus = "idle";
      this.failedScan = false;
      resetMovementArray();

      if (this.pythonBackend) {
        api.stopSystem().then(() => {
          this.starting = false;
          this.stopping = true;
          this.refreshStatus();
        });
        return false;
      } else {
        this.sysStatus = "standby";
        return false;
      }
    },
    fullScreen() {
      this.$refs.videoContainer.requestFullscreen();
    },
    showHealthReport() {
      this.$router.push("/email");
    },
    async removeProfile() {
      await newUserManager.currentUser.currentUser.clearUserSettings();
      this.form = newUserManager.currentUser.currentUser.userSettings;
      this.updateProfileFilled(false);
      this.agreeTnC = false;
      this.vitalSign = null;
      this.scanParameters = null;
      this.failedScan = false;
    },
    async updateProfile(form) {
      await newUserManager.currentUser.currentUser.setUserSettings(form);
      this.form = newUserManager.currentUser.currentUser.userSettings;
      this.updateProfileFilled(true);
      Logger.log("updateProfile", "", this.form);
    },
  },
  computed: {
    healthObject() {
      if (this.$root.$data.healthObject !== undefined) {
        return "healthObject updated.";
      } else {
        return "no health result";
      }
    },
    gap() {
      Logger.log(
        `%ccurrent Browswer: [${adapter.browserDetails.browser}]`,
        "color: #DDDDDD"
      );
      if (adapter.browserDetails.browser == "safari") {
        return "20px";
      } else {
        return 0;
      }
    },
  },
  components: {
    // VideoFeed,
    VitalSignButton,
    HeartRate,
    RespiratoryRate,
    O2Saturation,
    StressRating,
    // HrvIbi,
    FacialSkinAge,
    HrvSdnn,
    BloodPressure,
    //CVDRisk,
    //CovidRisk,
    Wellness,
    ConditionsHud,
    SideBar,
    FaceDetectionView,
    //WebCam,
    // HomePageOnboard,
    ScanTutorial,
    SignalQualityBox,
    userProfileInput,
    LoadingScreen,
  },
};
</script>

<style>
body {
  background-color: lightgray;
  /* overflow: hidden; */
}
.bg-orange {
  background-color: rgb(255, 130, 0);
}
.color-white {
  color: white;
}
.color-orange {
  color: rgb(255, 130, 0);
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<style scoped>
.homepage {
  font-family: v-bind(font);
}
.quick-help-button {
  /* display: inline-block; */
  opacity: 0.7;
  min-width: 40px;
  min-height: 40px;
  vertical-align: middle;
  z-index: 2;
}
button {
  border: none;
}
.buttons {
  display: flex;
  position: absolute;
  vertical-align: middle;
  top: 0px;
  right: 13px;
  z-index: 1;
}
.video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

#logo {
  position: absolute;
  width: 300px;
  left: 107px;
  top: 24px;
}
.navbar-button {
  border: none;
}
.navbar-btn:disabled {
  background-color: rgba(255, 130, 0, 0.7);
  color: white;
  border: none;
}
.system-message {
  margin-top: 20%;
}
.instruction-box {
  position: absolute;
  top: 10%;
  width: calc(100% - 87px);
  font-size: 48px;
  text-align: center;
  color: #ff3535;
  text-shadow: 1px 1px white;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
#video-container {
  overflow: hidden;
  height: 100vh;
  margin-left: 87px;
  z-index: 0;
}
.running-message {
  margin-top: 20%;
  font-size: 48px;
  color: white;
  text-align: center;
  text-shadow: 0px 0px 5px #000000;
}
.disclaimer {
  position: absolute;
  left: 40%;
  right: 40%;
  top: 20px;
  padding: 10px;
  color: black;
  background-color: lightgrey;
  opacity: 0.8;
  font-size: 0.9em;
  text-align: center;
}
.vsButton {
  overflow: hidden;
}
.button-rounded {
  border-radius: 50px;
  min-width: 120px;
}
.copyright {
  position: relative;
  font-size: 14px;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%, 0);
}
#icon-mobile {
  display: none;
}
.huds-container {
  position: absolute;
  top: 15%;
  width: calc(100% - 87px);
  height: 80%;
  overflow-y: scroll;
  /* height: fit-content; */
}
.huds-container::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.huds-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.disableBox {
  pointer-events: none;
  opacity: 0.7;
}
.info-bar {
  position: absolute;
  left: calc(87px + 20%);
  width: calc(60% - 87px);
  bottom: 6%;
  display: flex;
  justify-content: space-evenly;
}
.info-button {
  font-size: large;
  padding: 10px 15px;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: auto;
  margin-bottom: auto;
}
.instruction-text {
  position: absolute;
  display: flex;
  justify-content: center;
  width: calc(100% - 87px);
  top: 12%;
  font-size: 30px;
  color: white;
  text-shadow: 0px 0px 5px black;
  z-index: 3;
  text-align: center;
  pointer-events: none;
}
.w-30 {
  text-align: center;
  width: 30%;
}
.user-info-buttons {
  row-gap: 20px;
}
.camera-selection {
  z-index: 4;
}
.camera-button {
  border: none;
  background: transparent;
}
.selected-camera {
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 500;
}
@media (max-width: 1044px) {
  .huds-container {
    position: absolute;
    top: auto;
    left: 87px;
    bottom: 200px;
    width: calc(100% - 87px);
    height: 15%;
    overflow-y: scroll;
    overflow-x: scroll;
    display: flex;
    /* justify-content: center; */
    gap: v-bind(gap);
  }
  .row {
    justify-content: flex-start !important;
    flex-wrap: nowrap;
    margin: auto 2px !important;
  }
  .info-bar {
    position: absolute;
    left: 87px;
    width: calc(100% - 87px);
    bottom: 6%;
    display: flex;
    justify-content: space-evenly;
  }
}
@media (max-width: 768px) {
  #video-container {
    margin-left: 0px;
  }
  #logo {
    position: absolute;
    width: 200px;
    height: 30px;
    left: 20px;
    top: 25px;
  }
  #icon-mobile {
    display: inline;
    position: absolute;
    width: 35px;
    height: auto;
    left: 50vw;
    top: 4vh;
  }
  button {
    display: block;
    z-index: 1;
    font-size: 12px;
  }
  .buttons {
    min-width: 150px;
  }
  .full-screen {
    display: none;
  }
  .huds-container {
    width: 100%;
    left: 0;
  }
  .instruction-box {
    width: 100%;
  }
  .info-bar {
    left: 0px;
    width: 100%;
    bottom: 8%;
  }
  /* .info-button {
    width: 150px;
    height: 75px;
    min-height: 50px;
    font-size: large;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    margin-top: auto;
    margin-bottom: auto;
  } */
}

@media (max-width: 700px) {
  .huds-container {
    justify-content: flex-start;
    height: 20%;
    bottom: 170px;
    /* height: fit-content; */
  }
  .instruction-box {
    width: 100%;
  }
  .info-bar {
    left: 0px;
    width: 100%;
    bottom: 11%;
  }
  .info-button {
    width: 100px;
    height: 70px;
    min-height: 50px;
    font-size: small;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    margin-top: auto;
    margin-bottom: auto;
  }
}
</style>
