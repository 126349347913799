<template>
  <div class="face-mesh-container" ref="container">
    <canvas ref='canvas' :style="style"/>
  </div>
</template>

<script>
import { FACEMESH_TESSELATION } from '@mediapipe/face_mesh'
import { drawConnectors } from '@mediapipe/drawing_utils'

const CANVAS_WIDTH = 640
const CANVAS_HEIGHT = 480

export default {
  name: 'face-mesh-view',
  props: {'result': Object},
  setup() {
    return {
      style: {},
      aspectRatio: CANVAS_WIDTH/CANVAS_HEIGHT
    }
  },
  watch: {
    result() {
      this.updateStyle()
      this.redraw()
    },
  },
  methods: {
    updateStyle() {
      const video = document.getElementsByTagName('video')[0].srcObject.getVideoTracks()[0];
      if (video) {
        var height = video.getSettings().height;
        var width = video.getSettings().width;
        var aspectRatio = width / height;
        this.aspectRatio = aspectRatio;
      }
      let style = {
        left: '0%',
        top: '0%',
        aspectRatio: aspectRatio
      }
      let containerAspectRatio = this.$refs.container.clientWidth/this.$refs.container.clientHeight
      if (containerAspectRatio<aspectRatio) {
        style.left = `${ (containerAspectRatio-aspectRatio)/containerAspectRatio/2*100 }%`
        style.height = '100%'
      } else {
        style.top = `${ (1/containerAspectRatio-1/aspectRatio )*containerAspectRatio/2*100 }%`
        style.width = '100%'
      }
      this.style = style
    },
    redraw() {
      let canvas = this.$refs.canvas
      canvas.width = CANVAS_WIDTH
      canvas.height = CANVAS_WIDTH / this.aspectRatio
      const ctx = canvas.getContext('2d');

      ctx.save()
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      drawConnectors(
        ctx,
        this.result.landmarks,
        FACEMESH_TESSELATION,
        {
          color: "lightgreen",
          lineWidth: .2,
        }
      )
      ctx.restore()
    }
  },
}
</script>

<style>
.face-mesh-container {
  width: 100%;
  height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
}
canvas {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    position: absolute;
}
 
@import './face-mesh-view.css';

</style>