<template>
  <div class="row h-100">
    <d-3-bar-chart 
      :data="dayData" 
      :dataRange="dataRange" 
      :tag="referenceTag" 
      :trendData="dayData"
      :activeChart="activeChart"
      :trendLine="showTrendLine"
      :title="title"
    />
  </div>
</template>

<script>
import D3BarChart from "./d3-barchart.vue";

export default {
  props: {
    oneDayData: Array,
    dataRange: Number,
    tag: String,
    referenceTag: String,
    trendLine: Boolean,
    title: String,
  },
  components: {
    D3BarChart,
  },
  data() {
    return {
      showTrendLine:true,
      activeChart: 0
    };
  },  
  computed: {
    dayData() {
      let output = Array(24)
        .fill()
        .map((_, index) => {
          return {
            time: index,
            score:
              this.tag && this.tag === "bloodPressure"
                ? { systolic: NaN, diastolic: NaN }
                : NaN,
          };
        });
      // if (this.oneDayData) {
      //   let scores = this.oneDayData.map((data) => {
      //     // Define base return
      //     let score = {
      //       time: new Date(data.date.seconds * 1000),
      //       score: NaN,
      //     };
      //     if (this.tag === "bloodPressure")
      //       score.score = { systolic: NaN, diastolic: NaN };

      //     // Error checks
      //     if (!data.vital_sign) return score;
      //     if (!this.tag || !data.vital_sign[this.tag]) return score;

      //     // Calculate score
      //     score = Math.min(data.vital_sign[this.tag] / this.dataRange, 1.0);
      //     if (this.tag === "bloodPressure") {
      //       let sp = data.vital_sign[this.tag].split("/");
      //       let [systolic, diastolic] = sp;
      //       score = {
      //         systolic: Math.min(parseFloat(systolic) / this.dataRange, 1.0),
      //         diastolic: Math.min(parseFloat(diastolic) / this.dataRange, 1.0),
      //       };
      //     }

      //     return {
      //       time: new Date(data.date.seconds * 1000),
      //       score: score,
      //     };
      //   });

      //   // Configure score to correct hour bin
      //   scores.map((data) => {
      //     output[data.time.getHours()].score = data.score;
      //   });
      // }
      if (this.oneDayData) {
        let scores = this.oneDayData.map((data) => {

          // Define base return
          let score = {
            time: data.datetime,
            score: NaN,
          };
          if (this.tag === "bloodPressure")
            score.score = { systolic: NaN, diastolic: NaN };

          // Error checks
          if (!data.vitalSigns) return score;
          if (!this.tag || !data.vitalSigns[this.tag]) return score;

          // Calculate score
          score = Math.min(data.vitalSigns[this.tag] / this.dataRange, 1.0);
          if (this.tag === "bloodPressure") {
            let sp = data.vitalSigns[this.tag].split("/");
            let [systolic, diastolic] = sp;
            score = {
              systolic: Math.min(parseFloat(systolic) / this.dataRange, 1.0),
              diastolic: Math.min(parseFloat(diastolic) / this.dataRange, 1.0),
            };
          }

          return {
            time: data.datetime,
            score: score,
          };
        });

        // Configure score to correct hour bin
        scores.map((data) => {
          output[data.time.getHours()].score = data.score;
        });
      }
      let res = output.map((data) => {
        let date = new Date();
        date.setHours(data.time);
        return { time: date, score: data.score };
      });
      return res;
    },
  },
};
</script>
<style scoped>
#dailyHistory {
  overflow: hidden;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  cursor: grab;
}

.details-history-chart {
  --color: transparent;
}
.details-history-chart.column {
  height: 40vh;
  width: 100%;
  margin: 0 auto;
}
.details-history-chart > .data-axis {
  grid-area: data-axis;
  writing-mode: tb-rl;
  transform: rotateZ(180deg);
}
.details-history-bar {
  height: 100%;
  background: #c4c4c4;
  width: 20px;
}

.bar-tip {
  transform: translate(-8px, -50%);
}
.label {
  padding-top: 15px;
}
.y-axis {
  position: relative;
  right: 0px;
  width: 100%;
}
</style>
<style src="charts.css"></style>
