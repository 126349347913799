<template>
  <div class="d-flex flex-row" :style="pdf ? {} : {marginTop: '30px'}">
    <div class="text-justify" :style="{ 'font-size': pdf ? '8px' : '12px' }">
      <p>{{ t("health_report.disclaimer.title") }}:</p>
      <p v-html="t('health_report.disclaimer.p1')"></p>
      <p>{{ t("health_report.disclaimer.p2") }}</p>
    </div>
  </div>
  <div>
    <img src="./assets/panoptic.png" alt="" class="panoptic-logo" />
  </div>
</template>

<script>
import {useI18n} from "vue-i18n"
export default {
  setup() {
    const {t} = useI18n();
    return {
      t
    }
  },
  props: {
    pdf: Boolean
  }
}
</script>

<style scoped>
p {
  margin-bottom: 8px;
}

.panoptic-logo {
  width: 200px;
  padding: 0;
}
</style>