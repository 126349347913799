import * as faceapi from "face-api.js";
import Logger from "../common/logger";

// patch nodejs environment, we need to provide an implementation of
// HTMLCanvasElement and HTMLImageElement
// const { Canvas, Image, ImageData } = canvas
faceapi.env.monkeyPatch({
  Canvas: HTMLCanvasElement,
  Image: HTMLImageElement,
  ImageData: ImageData,
  Video: HTMLVideoElement,
  createCanvasElement: () => document.createElement("canvas"),
  createImageElement: () => document.createElement("img"),
});

class FaceDetector {
  constructor() {}

  async init() {
    /* load age detection module */
    await faceapi.nets.ssdMobilenetv1.loadFromUri("/models");
    await faceapi.nets.ageGenderNet.loadFromUri("/models");
    await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
  }

  async warmUp() {
    // Logger.log("age estimator", document.getElementById("img"))
    const imageData = document.createElement("img");
    imageData.id = "img";
    imageData.src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAEYklEQVR4Xu3UAQkAAAwCwdm/9HI83BLIOdw5AgQIRAQWySkmAQIEzmB5AgIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw";
    const tmp = this.estimateAge(imageData);
    Logger.log("AgeDetector is warmed up.", tmp);
  }

  async detectFace(imageData) {
    const results = await faceapi
      .detectSingleFace(imageData)
      .withFaceLandmarks();
    if (results !== undefined) {
      let imageWidth = results.detection.imageWidth;
      let imageHeight = results.detection.imageHeight;

      let xCenter =
        (results.detection.box.x + results.detection.box.width / 2) /
        imageWidth;
      let yCenter =
        (results.detection.box.y + results.detection.box.height / 2) /
        imageHeight;
      let width = results.detection.box.width / imageWidth;
      let height = results.detection.box.height / imageHeight;
      return {
        faceBox: {
          xCenter: xCenter,
          yCenter: yCenter,
          width: width,
          height: height,
        },
        landmarks: {},
      };
    }
    return results;
  }

  async estimateAge(imageData) {
    const detectionWithAgeAndGender = await faceapi
      .detectSingleFace(imageData)
      .withAgeAndGender();
    if (detectionWithAgeAndGender !== undefined) {
      return detectionWithAgeAndGender;
    } else {
      return null;
    }
  }
}

// module.exports = FaceDetector;
export { FaceDetector };
