<template>
  <VOnboardingWrapper ref="wrapper" :steps="steps">
    <template #default="{ previous, next, step, exit, isFirst, isLast, index }">
      <VOnboardingStep>
        <div
          class="p-4 bg-white rounded shadow-p"
          :style="{ width: '350px' }"
          :class="[index === 1 ? 'side-bar-step' : '']"
        >
          <div class="d-flex justify-content-between">
            <span
              v-if="step.content?.title"
              class="text-xl fs-4 leading-normal"
              >{{ step.content?.title }}</span
            >
            <button
              @click="
                exit();
                finished(true);
              "
              class="btn btn-light btn-sm"
            >
              x
            </button>
          </div>
          <p
            v-if="step.content?.description"
            class="text-sm text-gray-600 mt-2"
          >
            {{ step.content.description }}
          </p>
          <div class="d-flex mt-4 space-x-2">
            <button
              v-if="!isFirst"
              type="button"
              @click="previous"
              class="inline-flex flex-1 p-2 items-center justify-center border border-solid text-base font-medium rounded-full bg-transparent cursor-pointer border-gray-400 text-gray-800 hover:bg-gray-100"
            >
              Previous
            </button>
            <button
              @click="
                next();
                finished(isLast);
              "
              type="button"
              class="inline-flex flex-1 p-2 items-center justify-center border border-solid text-base font-medium rounded-full bg-transparent cursor-pointer border-transparent text-gray bg-indigo-600 hover:bg-indigo-700"
            >
              {{ isLast ? "Finish" : "Next" }}
            </button>
          </div>
        </div>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
</template>

<script>
import { ref } from "vue";
import { VOnboardingWrapper, VOnboardingStep } from "v-onboarding";
import "v-onboarding/dist/style.css";
import { newUserManager } from "../../user/company-user-manager.ts";
import { useI18n } from "vue-i18n";

// on-boarding steps for dashboard

export default {
  name: "DashboardOnboard",
  setup() {
    const wrapper = ref(null);
    const { t, locale } = useI18n();
    // const steps = stepsDashboard
    return {
      t,
      locale,
      wrapper,
      // steps,
    };
  },
  async mounted() {
    // delay the onboarding and wait for the user data to be loaded
    let timeout = 5000; // 5 second
    setTimeout(() => {
      let currentUser = newUserManager.currentUser;
      if (currentUser) {
        this.form = currentUser.companySettings;
        if (this.wrapper && this.form.firstTime.dashboard) { this.wrapper.start(); }
      } 
    }, timeout);
  },
  computed: {
    steps() {
      const stepsDashboard = [
        {
          attachTo: {
            element: "#chart",
          },
          content: {
            title: this.t("onboarding.dashboard.step1.title"),
            description: this.t("onboarding.dashboard.step2.description"),
          },
        },
        {
          attachTo: {
            element: "#time-period",
          },
          content: {
            title: this.t("onboarding.dashboard.step2.title"),
            description: this.t("onboarding.dashboard.step2.description"),
          },
          options: {
            popper: {
              placement: "bottom-end",
            },
          },
        },
        {
          attachTo: {
            element: "#trend-line",
          },
          content: {
            title: this.t("onboarding.dashboard.step3.title"),
            description: this.t("onboarding.dashboard.step3.description"),
          },
          options: {
            popper: {
              placement: "bottom-end",
            },
          },
        },
        {
          attachTo: {
            element: ".bottom-panel",
          },
          content: {
            title: this.t("onboarding.dashboard.step4.title"),
            description: this.t("onboarding.dashboard.step4.description"),
          },
          options: {
            popper: {
              placement: "top",
            },
          },
        },
        {
          attachTo: {
            element: "#heart_rate_info",
          },
          content: {
            title: this.t("onboarding.dashboard.step5.title"),
            description: this.t("onboarding.dashboard.step5.description"),
          },
        },
        {
          attachTo: {
            element: "#heart_rate_share",
          },
          content: {
            title: this.t("onboarding.dashboard.step6.title"),
            description: this.t("onboarding.dashboard.step6.description"),
          },
        },
        {
          attachTo: {
            element: "#heart_rate_explanation",
          },
          content: {
            title: this.t("onboarding.dashboard.step7.title"),
            description: this.t("onboarding.dashboard.step7.description"),
          },
        },
      ];

      return stepsDashboard;
    },
  },
  data() {
    return {
      form: undefined,
    };
  },
  methods: {
    async finished(isLast) {
      if (isLast) {
        let currentUser = newUserManager.currentUser;
        if (!currentUser) {
          return;
        } else {
          // read current user settings and update
          this.form = currentUser.companySettings;
          this.form.firstTime.dashboard = false;
          await currentUser.setCompanySettings(this.form);
          // console.log("finished onBoarding for Dashboard", response)
        }
      }
    },
  },
  components: {
    VOnboardingWrapper,
    VOnboardingStep,
  },
};
</script>

<style scoped></style>
