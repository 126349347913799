class Logger {
  static defaultMessage = "Hello World";
  static separator = "$%^&"
  static maxEntriesToStore = 5;

  static info(message) {
    if (process.env.NODE_ENV === "development") {
      console.log(`%c$$$$ [Info] $$$$\n  `, "color:blue;", message)
    }
  }

  static log(message, color="", ...data) {
    if (process.env.NODE_ENV === "development") {
      console.log(message, color, ...data)
    }
  }

  static error(message) {
    let lastErrors = this.getErrors();
    if (!lastErrors) {
      lastErrors = {"datetime": [new Date()], "message": [message.stack]}
    } else {
      lastErrors.datetime.push(new Date())
      lastErrors.message.push(message.stack)
      if (lastErrors.datetime.length > this.maxEntriesToStore) {
        lastErrors.datetime.shift()
        lastErrors.message.shift()
      } 
    }
    window.localStorage.setItem("error", JSON.stringify(lastErrors))
    // console.error(`${lastErrors.datetime}\n`, message)
  }

  static clear() {
    window.localStorage.removeItem("error")
  }

  static getErrors() {
    let error = window.localStorage.getItem("error");
    return JSON.parse(error)
  }
}

export default Logger;

// export default {

//   log: function(message, category) {

//     return fetch('/api/log', {
//       method: 'PUT',
//       headers: { 'Content-type': 'application/json; charset=UTF-8'},
//       body: JSON.stringify({
//         category: category,
//         message: message
//       })
//     });
//   },

//   info: function(message) {
//     this.log(message, 'info');
//   },

//   debug: function(message) {
//     this.log(message, 'debug');
//   },

//   error: function(message) {
//     this.log(message, 'error');
//   }

// };