<template>
  <div class="content">
    <div class="container-full" ref='top'>
      <div class="row mb-4 title" ref='title'>PanopticAI - {{t('page.help')}}</div>
      <div class="row">
        <div class="col-md-2 col-sm-12 mt-md-5 mt-2 content-list"> 
          <div class="sticky-top">

            <!-- Disclaimer -->
            <button class="btn btn-small btn-clear text-start fs-5 d-grid"
            v-bind:class="{ active: activeSection === 'disclaimer' }"
            @click="changeSection('disclaimer'); scrollToTop();"
            > 
              {{t('help_content.disclaimer.title')}}  
            </button>

            <!-- Overview -->
            <!-- <button class="btn btn-small btn-clear text-start fs-5 d-grid"
            v-bind:class="{ active: activeSection === 'overview' }"
            @click="changeSection('overview'); scrollTo('#overview');"
            > 
              Overview  
            </button>
            <button class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid "
              v-if="activeSection === 'overview'"
              @click="scrollTo('#login-page')"
            > 
              Login Page  
            </button>
            <button class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid "
              v-if="activeSection === 'overview'"
              @click="scrollTo('#navigation')"
            > 
              Navigation  
            </button>
            <button class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid "
              v-if="activeSection === 'overview'"
              @click="scrollTo('#homepage')"
            > 
              Homepage  
            </button>
            <button class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid "
              v-if="activeSection === 'overview'"
              @click="scrollTo('#supervisor-view')"
            > 
              Supervisor View  
            </button>
            <button class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid "
              v-if="activeSection === 'overview'"
              @click="scrollTo('#settings')"
            > 
              Settings  
            </button> -->
            <!-- <button class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid "
              v-if="activeSection === 'overview'"
              @click="scrollTo('#logout')"
            > 
              Logout
            </button> -->

            <!-- Set-up Guide -->
            <button class="btn btn-small btn-clear text-start fs-5 d-grid"
            v-bind:class="{ active: activeSection === 'guidelines' }"
            @click="changeSection('guidelines'); scrollToTop();"
            > 
              {{t('help_content.setup.title')}}
            </button>
            <button class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid "
              v-if="activeSection === 'guidelines'"
              @click="scrollTo('#dos')"
            > 
              {{t('help_content.setup.dos.title')}}
            </button>
            <button class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid "
              v-if="activeSection === 'guidelines'"
              @click="scrollTo('#donts')"
            > 
              {{t('help_content.setup.donts.title')}}
            </button>
            <button class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid "
              v-if="activeSection === 'guidelines'"
              @click="scrollTo('#low-accuracy')"
            > 
              {{t('help_content.setup.low_accuracy.title')}}
            </button>


            <!-- Operation -->
            <button class="btn btn-small btn-clear text-start fs-5 d-grid"
            v-bind:class="{ active: activeSection === 'operation' }"
            @click="changeSection('operation'); scrollToTop();"
            > 
              {{t("help_content.operation.title")}}  
            </button>
            <button class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid "
              v-if="activeSection === 'operation'"
              @click="scrollTo('#contact-based-device')"
            > 
            {{t('help_content.operation.ground_truth.title')}}
            </button>
            <button class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid "
              v-if="activeSection === 'operation'"
              @click="scrollTo('#scanning-system')"
            > 
            {{t('help_content.operation.scanning.title')}}
            </button>
          </div>
        </div>
        <div class="col mt-5 mx-1 mx-md-5 help-content" ref="text">
          <disclaimer v-if="activeSection === 'disclaimer'"/>
          <overview v-if="activeSection === 'overview'"/>
          <setup-guidelines v-if="activeSection === 'guidelines'"/>
          <operation v-if="activeSection === 'operation'"/>
        </div>
      </div>
      <div class="copyright text-center mb-3"><br><br>{{ t('copyright') }}</div>
    </div>
  </div>
  <side-bar />
</template>

<script>
import SideBar from "../sidebar/sidebar.vue";
import Overview from "./overview.vue";
import Operation from "./operation.vue";
import Disclaimer from "./disclaimer.vue";
import SetupGuidelines from "./setup-guidelines.vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router'
import { newUserManager } from "../../user/company-user-manager.ts";

export default {
  setup() {
    const { t, locale } = useI18n()
    const router = useRouter()

    var currentUser = newUserManager.currentUser;
    if (!currentUser) {
      router.push('/')
    }

    return {t, locale}
  },
  components: {
    SideBar,
    Overview,
    Operation,
    Disclaimer,
    SetupGuidelines
  },
  data() {
    return {
      activeSection: 'disclaimer',
      font: "MElleHK"
    }
  },
  methods: {
    changeSection(section){
      this.activeSection = section;
    },
    scrollTo(section) {
      this.$refs.text.querySelector(section).scrollIntoView();
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    checkLocale(locale) {
      if (locale == 'En') {
        this.font = "Gilroy"
      } else if (locale == '中文') {
        this.font = "MElleHK"
      }
    }
  },
  created() {
    this.checkLocale(this.locale);
  },
  watch: {
    locale(locale) {
      this.checkLocale(locale);
    },
  }
};
</script>

<style scoped>
.content {
  font-family: v-bind(font);
  position: static;
  padding-left: 87px;
  right: 0px;
  height: 100vh;
  min-height: max-content;
  background: #e4e3e3;
}

.container-full {
  padding-left: 41px;
  padding-top: 31px;
  padding-right: 41px;
  height: 100vh;
  overflow-y: scroll;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  color: #000000;
}
.copyright {
  position: relative;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0);
}

button > .active {
  font-weight: bold;
  background: #f5b33b;
}

.help-content {
  border-left: 1px solid; 
}

@media (max-width: 768px) {
  .content {
    padding-left: 0px;
    height: calc(100vh - 87px);
  }
  .help-content {
    border-left: none;
    margin-bottom: 90px;
  }
  .copyright {
    bottom: 90px;
  }
  .content-list {
    border-bottom: 1px solid;
  }
}
</style>
