import * as d3 from 'd3';
import { checkCondition } from './vitalRangeChecker';

const std_bound = (data, tag) => {
  const arr = [];
  
  data.map(x => {
    arr.push(x[tag])
  });


  const mean = d3.mean(arr);
  const std = d3.deviation(arr);

  const maxBound = mean + 2*std;
  const minBound = mean - 2*std;

  return [maxBound, minBound];
}

const interquartile_bound = (data, tag) => {
  const values = [];
  data.map(x => {
    values.push(x[tag]);
  })

  values.sort(d3.ascending);

  const q1 = d3.quantile(values, .25);

  const q3 = d3.quantile(values, .75);

  const iqr = q3-q1;

  const maxBound = q3 + iqr*1.5;
  const minBound = q1 - iqr*1.5;

  return [maxBound, minBound];
}

const checkOutlier = (tag, point, bound) => {
  const condition = checkCondition(tag, point).split('_')
  if(condition[0] === 'excellent' || condition[0] === 'good') {
    return false;
  }
  if (isNaN(point)){
    return false;
  }
  return !(point <= bound[0] && point >= bound[1]);
}

export {checkOutlier, interquartile_bound, std_bound};