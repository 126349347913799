<template>
  <div class="row h-100">
    <d-3-bar-chart
      :data="monthData"
      :dataRange="dataRange"
      :tag="referenceTag"
      :trendData="trendData"
      :activeChart="activeChart"
      :anomaly="anomaly"
      :trendLine="trendLine"
      :title="title"
    />
  </div>
</template>
<script>
import D3BarChart from "./d3-barchart.vue";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default {
  props: {
    oneMonthData: Array,
    dataRange: Number,
    tag: String,
    referenceTag: String,
    movingAverage: Array,
    anomaly: Array,
    trendLine: Boolean,
    title: String,
  },
  components: {
    D3BarChart,
  },
  methods: {
    codeRed(score) {
      return score < 0.75;
    },
    getDateString(day) {
      let date = new Date();
      date.setDate(date.getDate() - day);
      return monthNames[date.getMonth()] + " " + date.getDate();
    },
  },
  data() {
    return {
      showTrendLine: true,
      activeChart: 2
    };
  },
  computed: {
    trendData() {
      let scores;
      if (this.movingAverage) {
        scores = this.movingAverage.map((vitalSign) => {
          // Define defaults
          let score = NaN;
          if (this.tag === "bloodPressure")
            score = { systolic: NaN, diastolic: NaN };
          // Error handling
          if (!vitalSign) return score;

          // Actual score calculation
          score = Math.min(vitalSign / this.dataRange, 1.0);
          if (this.tag === "bloodPressure") {
            score = {
              systolic: Math.min(parseFloat(vitalSign.systolic) / this.dataRange, 1.0),
              diastolic: Math.min(parseFloat(vitalSign.diastolic) / this.dataRange, 1.0),
            };
          }
          return score;
        });
      } else {
        scores = Array(30).fill(
          this.tag === "bloodPressure"
            ? { systolic: 0.0, diastolic: 0.0 }
            : 0.0
        );
      }

      return Array(30)
        .fill()
        .map((_, index) => 29 - index)
        .map((day, index) => {
          let date = new Date();
          date.setDate(date.getDate() - day);
          // let dateString = this.getDateString(day);

          return {
            date: date,
            score: scores[index],
          };
        });
    },
    monthData() {
      let scores;
      if (this.oneMonthData) {
        scores = this.oneMonthData.map((vitalSign) => {
          // Define defaults
          let score = NaN;
          if (this.tag === "bloodPressure")
            score = { systolic: NaN, diastolic: NaN };

          // Error handling
          if (!vitalSign) return score;
          if (!this.tag || !vitalSign[this.tag]) return score;

          // Actual score calculation
          score = Math.min(vitalSign[this.tag] / this.dataRange, 1.0);
          if (this.tag === "bloodPressure") {
            let sp = vitalSign[this.tag].split("/");
            let [systolic, diastolic] = sp;
            score = {
              systolic: Math.min(parseFloat(systolic) / this.dataRange, 1.0),
              diastolic: Math.min(parseFloat(diastolic) / this.dataRange, 1.0),
            };
          }
          return score;
        });
      } else {
        scores = Array(30).fill(
          this.tag === "bloodPressure"
            ? { systolic: 0.0, diastolic: 0.0 }
            : 0.0
        );
      }

      return Array(30)
        .fill()
        .map((_, index) => 29 - index)
        .map((day, index) => {
          let date = new Date();
          date.setDate(date.getDate() - day);
          // let dateString = this.getDateString(day);

          return {
            date: date,
            score: scores[index],
          };
        });
    },
  },
};
</script>

<style scoped>
#monthlyHistory {
  font-size: 1.1rem;
  overflow: hidden;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  cursor: grab;
}

.details-history-chart {
  --color: transparent;
}
.details-history-chart.column {
  height: 40vh;
  width: 100%;
  margin: 0 auto;
}
.details-history-chart > .data-axis {
  grid-area: data-axis;
  writing-mode: tb-rl;
  transform: rotateZ(180deg);
}
.details-history-bar {
  height: 100%;
  background: #c4c4c4;
  width: 20px;
}

.bar-tip {
  transform: translate(-8px, -50%);
}
.label {
  padding-top: 15px;
}
.y-axis {
  position: relative;
  right: 0px;
  width: 100%;
}
</style>
<style src="charts.css"></style>
