<template>
  <div class="container-fluid text-center">
    <img id="logo" src="../sidebar/assets/icon.png" />
    <img id="panoptic-ai" src="../home/assets/panoptic_ai.svg" />
    <div class="app-title">{{ appTitle }}</div>
    <section id="firebaseui-auth-container"></section>
    <a class="my-1" v-if="isReady" href="#" @click="loginAsGuest">{{
      t("guest_signin")
    }}</a>
  </div>
  <div class="copyright text-center">
    <p>{{ t("copyright") }}</p>
  </div>
</template>

<script>
// https://softauthor.com/firebaseui-vue-facebook-google-auth/

import { defineComponent } from "vue";
import "firebaseui/dist/firebaseui.css";
import { newUserManager } from "../../user/company-user-manager.ts";
import { cloudBackend } from "../../user/company-user-manager-definitions.ts";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import Logger from "@/common/logger";

export default defineComponent({
  setup() {
    const { t, locale } = useI18n();
    const isReady = ref(false);
    return {
      t,
      locale,
      isReady,
    };
  },
  async mounted() {
    let currentCloudBackend = cloudBackend.firebase;
    if (newUserManager.currentUser) {
      let currentUser = newUserManager.currentUser;
      // check if the user has logged in or not
      if (currentUser && currentUser.cloudInfo) {
        Logger.log("already logged in: ", currentUser);
        this.$router.push("/input-profile");
        // this.$router.push("/home");
        return;
      }

      // log user in
      switch (currentUser.userType) {
        case cloudBackend.local: {
          break;
        }
        case cloudBackend.firebase: {
          await newUserManager.login(cloudBackend.firebase);
          break;
        }
      }
      Logger.log("logged in: ", newUserManager.currentUser);
      // this.$router.push("/home")
      this.$router.push("/input-profile");
      return;
    } else {
      await newUserManager.ready(cloudBackend.firebase);
      this.isReady = true && this.$root.debugMode;
      await newUserManager.login(currentCloudBackend);
      // this.$router.push("/home");
      this.$router.push("/input-profile");
    }

    // await userManager.ready("firebase");

    // await newUserManager.login(currentCloudBackend)
    // this.$router.push("/home");

    // await userManager.login("firebase");
    // this.$router.push("/home");
  },
  methods: {
    loginAsGuest() {
      newUserManager.login(cloudBackend.local);
      this.$router.push("/home");
    },
  },
  computed: {
    appTitle() {
      return this.$root.$data.appTitle ? this.$root.$data.appTitle : "";
    },
  },
});
</script>

<style scoped>
#panoptic-ai {
  margin-top: 10%;
  margin-bottom: 10%;
  width: 600px;
  max-width: 90%;
}
#logo {
  width: 80px;
  margin: 2em;
}
.app-title {
  font-size: x-large;
  color: #696969;
  text-shadow: 1px 1px #acacac;
}
.copyright {
  position: absolute;
  font-size: 14px;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, 0);
}
</style>
