<template>

    <div class="locale-changer normal">
    <select v-model="$i18n.locale" id="locale-selector" width="50px">
      <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
    </select>
    </div>

</template>

<script>
import { useI18n } from 'vue-i18n'; 

export default {
  name: "locale-button",
  setup() {
    const { t, locale } = useI18n();
    return {
      t, locale
    }
  },
  data() {
    return {
      font: "MElleHK"
    }
  },
};
</script>

<style>
</style>
