<template>
  <div class="history-chart">
    <nav class="navbar navbar-light">
      <div class="button-group float-right">
        <button
          class="btn btn-small btn-clear"
          v-bind:class="{ active: activeChart === 0 }"
          @click="onPress(0)"
        >
          {{t('graph.day')}}
        </button>
        |
        <button
          class="btn btn-small btn-clear"
          v-bind:class="{ active: activeChart === 1 }"
          @click="onPress(1)"
        >
          {{t('graph.week')}}
        </button>
        |
        <button
          class="btn btn-small btn-clear"
          v-bind:class="{ active: activeChart === 2 }"
          @click="onPress(2)"
        >
          {{t('graph.month')}}
        </button>
        | 
        <button
          class="btn btn-small btn-clear"
          v-bind:class="{ active: trendLine }"
          @click="showTrendLine()"
        >
          {{t('graph.trend_line')}}
        </button>
      </div>
    </nav>
    <div class="container-fluid">
      <chart-daily
        v-if="activeChart === 0"
        :dataRange="dataRange"
        :oneDayData="oneDayData"
        :tag="vsTag"
        :referenceTag="referenceTag"
        :trendLine="trendLine"
        :title="title"
      />
      <chart-weekly
        v-if="activeChart === 1"
        :dataRange="dataRange"
        :oneWeekData="oneWeekData"
        :tag="vsTag"
        :referenceTag="referenceTag"
        :linearReg="linearReg"
        :anomaly="anomaly"
        :trendLine="trendLine"
        :title="title"
      />
      <chart-monthly
        v-if="activeChart === 2"
        :dataRange="dataRange"
        :oneMonthData="oneMonthData"
        :tag="vsTag"
        :referenceTag="referenceTag"
        :movingAverage="movingAverage"
        :anomaly="anomaly"
        :trendLine="trendLine"
        :title="title"
      />
    </div>
  </div>
</template>

<script>
import ChartDaily from "./chart-daily.vue";
import ChartWeekly from "./chart-weekly.vue";
import ChartMonthly from "./chart-monthly.vue";
import { useI18n } from 'vue-i18n';

export default {
  props: {
    oneDayData: Array,
    oneWeekData: Array,
    oneMonthData: Array,
    lastScan: Object,
    vsTag: String,
    dataRange: Number,
    referenceTag: String,
    linearReg: Array,
    movingAverage: Array,
    anomaly: Array,
    title: String,
  },
  setup() {
    const { t, locale } = useI18n()
    return {
      t, locale
    }
  },
  data() {
    return {
      trendLine: true,
      activeChart: 2,
    };
  },
  components: {
    ChartDaily,
    ChartWeekly,
    ChartMonthly,
  },
  methods: {
    showTrendLine() {
      this.trendLine = !this.trendLine;
    },
    onPress(idx) {
      this.activeChart = idx;
    },
  },
};
</script>

<style scoped>
.navbar {
  justify-content: flex-end;
}

.history-chart {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 20px;
  height: 500px;
}
.container {
  margin-top: 20px;
  margin-bottom: 20px;
}
.container-fluid {
  height: 500px
}
.title {
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-right: 30px;
  color: #f5b33b;
}
.overall-score {
  display: inline-block;
  width: 50px;
  height: 29px;
  border: 3px solid #00d700;
  box-sizing: border-box;
  border-radius: 10px;
}
.button-group {
  display: inline-block;
}

.button-group > .active {
  background: #f5b33b;
}

@media (max-width: 1053px) {
  .history-chart {
    height: 700px;
  }
  .container-fluid {
    height: 600px;
  }
}
</style>
