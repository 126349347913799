<template>
  <div class="email">
    <loading-screen v-if="sendingEmail" :text="t('labels.sending')" />
    <div
      class="content"
      v-if="currentUser && currentUser.currentUser.lastVitalSigns"
    >
      <!-- <div class="content" v-if="currentUser && currentUser.currentUser.lastVitalSigns && profileFilled"> -->
      <div class="container-full">
        <!-- <div class="row mb-4 justify-content-end"> -->

        <div>
          <button class="btn bg-orange color-white mb-4" @click="goToHome">
            &lt; {{ t("button.back_to_scan") }}
          </button>
        </div>
        <div class="d-flex flex-row pb-4">
          <!-- <feedback class="col-6" /> -->
          <div class="d-flex col align-self-start">
            <img id="logo" src="./assets/mannings_logo.svg" />
          </div>
          <div class="col-5 email-button">
            <button
              class="border-0 p-2 color-white opacity-border-radius bg-orange font-sarabun email-button"
              type="button"
              @click="sendToEmail"
              :disabled="sendingEmail"
            >
              {{ t("button.send_to_email") }}
            </button>
          </div>
        </div>
        <!-- <div class="row justify-content-end mb-4">
                    <div class="col-2">
                        <img id="logo" src="./assets/mannings-logo.svg" />
                    </div>
                </div> -->
        <div class="d-flex flex-row mt-2 mb-4">
          <h1 class="fw-bolder color-orange">
            {{ t("health_report.title") }}
          </h1>
        </div>
        <div
          class="d-flex flex-row bg-light py-4 px-2 content-card fw-bold px-4"
        >
          <div class="col-6">
            <div
              class="d-flex flex-row justify-content-start color-orange fw-bolder fs-2 text-center px-0"
            >
              {{ userName }}
            </div>
            <div
              class="d-flex flex-row justify-content-start color-gray fs-5 px-0"
              :style="{ 'word-break': 'break-all' }"
            >
              {{ t("health_report.email") }}:
              {{
                currentUser ? currentUser.currentUser.userSettings.email : ""
              }}
            </div>
            <div class="d-flex flex-row fs-5">
              <div class="col-4 color-gray px-0">
                <div class="">{{ t("health_report.age") }}: {{ age }}</div>
                <div class="">
                  {{ t("health_report.sex") }}:
                  {{
                    currentUser !== undefined
                      ? t(
                          "health_report." +
                            currentUser.currentUser.userSettings.gender
                        )
                      : ""
                  }}
                </div>
                <div class="">
                  {{ t("health_report.bmi") }}:
                  {{
                    currentUser.currentUser.lastVitalSigns
                      ? currentUser.currentUser.lastVitalSigns.bmi.toFixed(1)
                      : ""
                  }}
                </div>
              </div>
              <div class="col-8 color-gray my-auto">
                <div class="d-flex flex-row">
                  <img
                    src="./assets/location.svg"
                    alt="location"
                    class="icon p-0 me-2"
                  />
                  <div class="col">
                    {{ currentUser.companySettings.location }}
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <img
                    src="./assets/calendar.svg"
                    alt="date"
                    class="icon p-0 me-2"
                  />
                  <div class="col">
                    {{ lastScanTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex flex-row color-gray align-items-center h-100">
              <div class="col-6 pe-1 h-100">
                <div class="general-score">
                  <div class="justify-content-center text-center">
                    {{ t("health_report.wellness.stress.title") }}
                  </div>
                  <div
                    class="d-flex flex-row justify-content-center mt-2 mb-3 fs-3 color-orange"
                  >
                    <img
                      src="./assets/stress-top.svg"
                      alt="covid"
                      class="ps-0 icon-profile-vitals me-2"
                    />
                    {{ this.getData("stress").toFixed(1) }}
                  </div>
                </div>
              </div>
              <div class="col-6 px-1 h-100">
                <div class="general-score">
                  <div class="justify-content-center text-center">
                    {{ t("health_report.general_wellness") }}
                  </div>
                  <div
                    class="d-flex flex-row justify-content-center mt-2 mb-3 fs-3 color-orange"
                  >
                    <img
                      src="./assets/general-wellness.svg"
                      alt="covid"
                      class="ps-0 icon-profile-vitals me-2"
                    />
                    {{ this.getData("generalWellness").toFixed(1) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Wellness -->
        <vitals-type
          :vitalSign="['generalWellness', 'stress']"
          :type="'wellness'"
          :pdf="false"
        />

        <!-- Respiratory -->
        <vitals-type
          :vitalSign="['respiratoryRate', 'spo2']"
          :type="'respiratory'"
          :pdf="false"
        />

        <!-- Facial -->
        <vitals-type
          :vitalSign="['facialSkinAge']"
          :type="'facial'"
          :pdf="false"
        />

        <!-- Cardiovascular -->
        <vitals-type
          :vitalSign="[
            'heartRate',
            'hrvSdnn',
            'bloodPressureSystolic',
            'bloodPressureDiastolic',
          ]"
          :type="'cardiovascular'"
          :pdf="false"
        />

        <!-- <div class="row justify-content-center my-5">
                    <img src="./assets/mannings-logo.svg" alt="" class="w-75">
                </div> -->
        <disclaimer :pdf="false" />
        <!-- copyright message -->
        <div class="copyright text-center">{{ t("copyright") }}<br /></div>
      </div>
    </div>
    <side-bar />
    <div class="pdf">
      <report-pdf ref="pdf" />
    </div>
  </div>
</template>


<script>
import { useI18n } from "vue-i18n";
import { newUserManager } from "../../user/company-user-manager.ts";
import ReportPdf from "./report-pdf.vue";
import SideBar from "../sidebar/sidebar.vue";
import { useRouter } from "vue-router";
import html2pdf from "html2pdf.js";
import { VitalSignEngine } from "vital-sign-engine";
import { inject } from "vue";
import Logger from "@/common/logger";
import VitalsType from "./vitals-type.vue";
import Disclaimer from "./disclaimer.vue";
import LoadingScreen from "../home/loading-screen.vue";
// import Copyright from '../../common/copyright.vue';

export default {
  name: "email-page",
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();
    const { profileFilled } = inject("profileFilled");
    var currentUser = newUserManager.currentUser;

    // if(!currentUser || !currentUser.currentUser.lastVitalSigns || !profileFilled) {
    if (!currentUser) {
      router.push("/");
    }

    return {
      t,
      locale,
      currentUser,
      profileFilled,
    };
  },
  components: {
    SideBar,
    ReportPdf,
    VitalsType,
    Disclaimer,
    LoadingScreen,
    // Copyright,
  },
  watch: {
    locale(locale) {
      this.checkLocale(locale);
    },
  },
  data() {
    return {
      sendingEmail: false,
      font: "MElleHK",
    };
  },
  created() {
    if (this.$root.$data.debugMode) {
      let currentUser = newUserManager.currentUser
        ? newUserManager.currentUser.currentUser
        : null;
      if (currentUser && !currentUser.lastHealth) {
        if (!currentUser.email) {
          currentUser._genUserInfo();
          Logger.log(
            "%c<email-page debug>generated user info",
            "color:magenta"
          );
        }
        if (!currentUser.lastHealth) {
          currentUser._genLastHealth();
          Logger.log(
            "%c<email-page debug>generated last health",
            "color:magenta"
          );
        }
        Logger.log("%cgenerated currentUser: ", "color:magenta", currentUser);
      }
    } else {
      if (
        !this.currentUser ||
        !this.currentUser.currentUser.lastVitalSigns ||
        !this.profileFilled
      ) {
        this.$router.push("/");
      }
    }
    this.checkLocale(this.locale);
  },
  computed: {
    userName() {
      if (!this.currentUser) {
        return "";
      }
      if (this.currentUser.currentUser.name == "Guest") {
        return this.t("user.guest");
      }
      return this.currentUser.currentUser.name;
    },
    age() {
      if (!this.currentUser) {
        return "";
      }
      const age = this.currentUser.currentUser.userSettings.age;
      if (age) {
        return age;
      }
      const dateString = this.currentUser.currentUser.userSettings.birthday;
      var ageInMilliseconds = new Date() - new Date(dateString);
      return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
    },
    lastScanTime() {
      if (!this.currentUser.currentUser.lastVitalSigns) {
        return;
      }
      const d = new Date(this.currentUser.currentUser.lastVitalSigns.datetime);
      let month = String(d.getMonth() + 1);
      let day = String(d.getDate());
      const year = String(d.getFullYear());

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return `${day}/${month}/${year}`;
    },
  },
  methods: {
    goToHome() {
      this.$router.push("/home");
    },
    async sendToEmail() {
      this.sendingEmail = true;
      setTimeout(() => {
        let pdfRef1 = this.$refs.pdf.$refs.report_pdf;

        if (!pdfRef1) {
          alert(this.t("message.email_failed"));
          this.sendingEmail = false;
          return;
        }

        var opt = {
          filename: "AIWellnessScanReport.pdf",
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: { after: "#pdf-1" },
        };

        html2pdf()
          .set(opt)
          .from(pdfRef1)

          // for debug (downloading the pdf)
          //   .save();
          // this.sendingEmail = false;

          // for sending the report through email to the user
          .outputPdf()
          .then(async (pdf) => {
            // This logs the right base64
            let base64Report = btoa(pdf);

            let currentUser = newUserManager.currentUser.currentUser;
            if (currentUser) {
              try {
                let emailTemplate = {
                  emailContent: this.t("email_template.email_content", {
                    username: currentUser.name,
                  }),
                  emailSubject: this.t("email_template.email_subject"),
                  fileName: this.t("email_template.file_name"),
                };
                await VitalSignEngine.sendHealthReport(
                  [currentUser.userSettings.email],
                  base64Report,
                  emailTemplate,
                  "no-reply@panoptic.ai"
                );
                alert(
                  `${this.t("message.email")} ${currentUser.name} @ {${
                    currentUser.userSettings.email
                  }}`
                );
              } catch (e) {
                alert(this.t("message.email_failed"));
                return;
              }
            } else {
              alert(this.t("message.no_email"));
            }
            this.sendingEmail = false;
          });
      }, 300);
    },
    getData(tag) {
      if (
        this.currentUser.currentUser.lastVitalSigns &&
        this.currentUser.currentUser.lastVitalSigns[tag]
      ) {
        return this.currentUser.currentUser.lastVitalSigns[tag];
      } else {
        return 0;
      }
    },
    checkLocale(locale) {
      if (locale == "En") {
        this.font = "Gilroy";
      } else if (locale == "中文") {
        this.font = "MElleHK";
      }
    },
  },
};
</script>


<style scoped>
.email {
  font-family: v-bind(font);
}
.opacity-border-radius {
  border-radius: 10px 10px 0px 0px;
}

.border-orange {
  border-bottom: solid;
  border-bottom-color: rgb(255, 130, 0);
}

.general-score {
  background-color: #eeeff3;
  border-radius: 9px;
  padding: 30px 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.color-gray {
  color: #747579;
}

.container-full {
  padding: 50px;
  height: 100vh;
}
.email-button {
  display: flex;
  justify-content: flex-end;
}
.email-message {
  color: red;
  margin: auto 6px;
}
.content {
  position: static;
  padding-left: 87px;
  right: 0px;
  height: 100vh;
  min-height: max-content;
  background: white;
  overflow-y: scroll;
  font-size: large;
}
.content-card {
  border-radius: 10px;
  box-shadow: 4px 4px 4px 0px #00000040;
}

.icon {
  width: 22px;
  height: 30px;
}

.icon-facts {
  width: 35px;
  height: 25px;
}

.icon-profile-vitals {
  width: 50px;
  height: 50px;
  margin-right: 5px;
}

.icon-vital-signs {
  width: 80px;
}

#logo {
  float: right;
  height: 30px;
}

#profile-img {
  margin: auto auto;
  margin-bottom: 5px;
  max-width: 60%;
}
.age-number {
  font-size: xx-large;
}
/deep/ .text-justify {
  text-align: justify;
}
.copyright {
  position: relative;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 0;
}
.panoptic-logo {
  width: 300px;
  padding: 0;
}
.email-button:disabled {
  opacity: 0.4;
}

#logo {
  width: 300px;
  height: 100%;
}

@media (max-width: 768px) {
  .content {
    padding-left: 0px;
    margin-bottom: 50px;
  }
}

/* pdf production */
.pdf {
  position: absolute;
  left: -10000px;
  top: auto;
  overflow: hidden;
  pointer-events: none;
}

/* pdf debug */
/* .pdf {
  position: absolute;
  overflow: hidden;
  z-index: 100;
} */
</style>