<template>
    <data-hud class="data-hud" :title="t('vital_signs.spo2.title')" unit="%" :data="data"/>
</template>

<script>
import DataHud from "./data-hud.vue"
import { useI18n } from 'vue-i18n';

export default 
  {
    name: 'o2-saturation',
    props: {
        data: Number
    },
    setup() {
        const { t, locale } = useI18n()
    return {t, locale}
    },
    components: { DataHud }
}
</script>

<style scoped>
.data-hud {
    position: relative;
}
@media (max-width: 1044px) {
    .data-hud {
        position: relative;
        right: auto;
        top: auto;
    }
}
</style>


