<template>
    <h1 class='display-5' id="operation">
        {{t('help_content.disclaimer.title')}}
    </h1>
    <p>
        {{t('help_content.disclaimer.p1')}}
    </p>
    <p>
        {{t('help_content.disclaimer.p2')}}
    </p>
</template>

<script>
import {useI18n} from 'vue-i18n'
export default {
    setup() {
        const {t, locale} = useI18n();

        return {t,locale};
    },
}
</script>

<style scoped>
p {
    text-align: justify;
}
</style>