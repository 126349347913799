<template>
  <div class="data-box container" :style="getStyle">
    <div class="row">
      <div class="col-3">
        <img class="image" :src="icon" />
      </div>
      <div class="col-6">
        <p class="title text-center">{{ title }}</p>
        <p class="value text-center">{{ value }}</p>
      </div>
      <div class="col-3">
        <button
          type="button"
          class="btn btn-clear control-button"
          v-bind:class="{
            disabled: [
              'facial_skin_age',
              'blood_sugar',
              'blood_alcohol',
            ].includes(tag),
          }"
          data-bs-toggle="modal"
          data-bs-target="#detailedInformationScreenModal"
          v-on:click="handle_detail_info_click"
        >
          <img
            :id="tag + '_info'"
            class="button-icon"
            src="./assets/info-icon.svg"
          />
        </button>
        <button type="button" class="btn btn-clear control-button">
          <img
            :id="tag + '_share'"
            class="button-icon"
            src="./assets/share-icon.svg"
          />
        </button>
        <button
          type="button"
          class="btn btn-clear control-button"
          v-on:click="toggle_explanation"
        >
          <img :id="tag + '_explanation'" class="button-icon" :src="getArrow" />
        </button>
      </div>
    </div>
    <transition name="fade">
      <div
        class="row"
        v-if="this.description_flag && te('vs_descriptions.' + tag)"
      >
        <p class="explanation text-center">{{ t("vs_descriptions." + tag) }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { checkCondition, colors } from "./vitalRangeChecker";

export default {
  props: {
    icon: String,
    title: String,
    data: undefined,
    unit: String,
    tag: String,
  },
  setup() {
    const { t, te, locale } = useI18n();
    const description_flag = ref(false);
    const div_height = "130px";
    return { t, te, locale, description_flag, div_height };
  },
  methods: {
    toggle_explanation() {
      this.description_flag = !this.description_flag;
    },
    handle_detail_info_click() {
      this.$emit("updateModal", this.tag);
    },
  },
  computed: {
    value() {
      if (typeof this.data == "number") {
        let decimal = 1;
        if (["O2 Saturation", "Breathing Rate"].indexOf(this.title) > -1) {
          decimal = 0;
        }
        let str = this.data.toFixed(decimal).toString();
        if (this.unit) {
          str += " " + this.unit;
        }
        return str;
      } else if (typeof this.data == "string") {
        let str = this.data;
        if (this.unit) {
          str += " " + this.unit;
        } else if (str == "Coming Soon") {
          str = this.t("message.coming_soon");
        }
        return str;
      } else {
        return this.t("no_data");
      }
    },
    getArrow() {
      if (this.description_flag) {
        return require("./assets/up-arrow-icon.svg");
      } else {
        return require("./assets/down-arrow-icon.svg");
      }
    },
    getStyle() {
      let category = checkCondition(this.tag, this.data);
      let containerBorderColor;
      containerBorderColor = colors[category];
      let containerStyle = {
        display: "inline-block",
        "min-width": "300px",
        height: "130px max-content",
        "margin-bottom": "15px",
        "padding-right": "5px",
        "padding-left": "15px",

        background: "#FFFFFF",
        border: `5px solid ${containerBorderColor}`,
        "backdrop-filter": `blur(30px)`,

        /* Note: backdrop-filter has minimal browser support */
        "border-radius": "10px",
      };
      return containerStyle;
    },
  },
};
</script>

<style scoped>
.data-box {
  display: inline-block;
  min-width: 300px;
  height: 130px;
  margin-bottom: 15px;
  padding-right: 5px;
  padding-left: 15px;
  height: max-content;

  background: #ffffff;
  border: 5px solid #00d700;
  backdrop-filter: blur(30px);

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
}

.image {
  margin-top: 20px;
}

.right-controls {
  position: fixed;
  width: 10%;
  right: 0px;
  width: max-content;
}

.button-icon {
  width: 30px;
  height: 30px;
}

.control-button {
  padding-bottom: 0px;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #f5b33b;
  margin-top: 10px;
  margin-bottom: 5px;
}

.value {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.7);
}

.explanation {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.7);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media (max-width: 768px) {
  .title {
    font-size: 20px;
  }

  img {
    transform: scale(0.8);
  }
}
</style>
