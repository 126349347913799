import { uuidv4 } from "@firebase/util";

export function constructVitalSignsFromHealth(health, returnRAW = false) {
  // turn undefined values to null
  let results = JSON.parse(
    JSON.stringify(health, function (k, v) {
      if (v === undefined) {
        return null;
      } else {
        return v;
      }
    })
  );

  // process newHealth to support older dashboard
  let vs = {
    ...results.vitalSigns,
    ...results.holisticHealth,
  };
  if (results.datetime) {
    vs["datetime"] = results.datetime;
  }
  if (results.facialSkin) {
    vs["facialSkinAge"] = results.facialSkin.facialSkinAge;
  }
  if (results.risks) {
    vs = {
      ...vs,
      ...results.risks.cardiovascularRisks,
      ...results.risks.covidRisk,
    };
  }
  if (returnRAW) {
    return { vs, results };
  }
  return vs;
}

// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0");
}

// generateId :: Integer -> String
export function generateId(len) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}

/* Generate B2B company profile and data */
export function generateEmptyCompanySettings() {
  return {
    UID: `guest_${uuidv4()}`,
    companyId: "iOSw9052AdfalkP",
    projectId: "iOSw9052Adfalkn",
    siteId: "",
    programCode: "1",
    location: "Hong Kong",
    companyProfileImage:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAAG5SURBVFhH1ZZNjwFBEIZriOCCg5BwHlccBP/bxV+QiASJw1wkhAQJcUB8zaZm35XMru6uztqRfS5Tb013e6c/qjnn89mnNxLD8208ZsBxnCARJb7v/8MZuN/vtNlsqNfrIfNJMpmkarVK+XyeYjHZd/EMWBm4Xq80GAxotVoFnb/DYxQKBWo0GsjosTbQ6XQQ6SmXy1Sv16HUWO2B9XqNyMxisQiWSYLIAK/7eDyGMsNfNhqNoPSIDGy3WzocDlAypO1FBnjzPdt0OqTtRQYSiYT4aH0hbS9qlU6nKR6PQ8mQthcZSKVSlM1moWTkcjlEekQGuEa0Wi0oM5VKhZrNJpQeq4UtlUrGgsXvXdeFMmNlgOuBBD62UkSlmH94OBzSfD5HRg+fAJ6tWq2GzHNEd8HlcqFutwtlRyaToXa7HRzjZxjvgtvtRv1+H8qe/X4f9OdxVGgNTKdT8aWigvvzOCqUBnjNJ5MJ1O/QjaM0sFwuEb2G0+mEKIzSAG++VzKbzRCF0e6BV7Lb7RCFiczA8XhEFCYyA6oljcyACmUl9DzP+l+QjmKx+ONKF5Xiv8RYiqPgMQPvgegDuePN2zUkxYMAAAAASUVORK5CYII=",
    planExpiryDate: "unlimited",
    planType: "free",
    firstTime: {
      homepage: true,
      dashboard: true,
    },
  };
}

/* Generate B2C user profile and data */
export function generateEmptyUserSettingsObject() {
  return {
    // planExpiryDate: "unlimited",
    // planType: "free",
    // firstTime: {
    //     homepage: true,
    //     dashboard: true,
    // },
    // userId: uuidv4(),
    userId: `guest_${uuidv4()}`,
    email: "",
    phoneNumber: "",
    profileImage:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAAG5SURBVFhH1ZZNjwFBEIZriOCCg5BwHlccBP/bxV+QiASJw1wkhAQJcUB8zaZm35XMru6uztqRfS5Tb013e6c/qjnn89mnNxLD8208ZsBxnCARJb7v/8MZuN/vtNlsqNfrIfNJMpmkarVK+XyeYjHZd/EMWBm4Xq80GAxotVoFnb/DYxQKBWo0GsjosTbQ6XQQ6SmXy1Sv16HUWO2B9XqNyMxisQiWSYLIAK/7eDyGMsNfNhqNoPSIDGy3WzocDlAypO1FBnjzPdt0OqTtRQYSiYT4aH0hbS9qlU6nKR6PQ8mQthcZSKVSlM1moWTkcjlEekQGuEa0Wi0oM5VKhZrNJpQeq4UtlUrGgsXvXdeFMmNlgOuBBD62UkSlmH94OBzSfD5HRg+fAJ6tWq2GzHNEd8HlcqFutwtlRyaToXa7HRzjZxjvgtvtRv1+H8qe/X4f9OdxVGgNTKdT8aWigvvzOCqUBnjNJ5MJ1O/QjaM0sFwuEb2G0+mEKIzSAG++VzKbzRCF0e6BV7Lb7RCFiczA8XhEFCYyA6oljcyACmUl9DzP+l+QjmKx+ONKF5Xiv8RYiqPgMQPvgegDuePN2zUkxYMAAAAASUVORK5CYII=",
    name: "",
    birthday: "",
    age: "",
    gender: "",
    height: null,
    weight: null,
    unitSystem: false,
    countries: "",
    smoker: false,
    hypertension: false,
    bloodPressureMedication: false,
    diabetic: "",
    heartDisease: false,
    depression: false,
    userTier: 0,
    // planExpiryDate: "unlimited",
    // planType: "free",
    // firstTime: {
    //     homepage: true,
    //     dashboard: true,
    // },
  };
}

export function getSampleVitalSign() {
  const sampleVitalSign = {
    facialSkinAge: "Coming Soon",
    respiratoryRate: 14,
    bloodPressureSystolic: 120,
    bloodPressureDiastolic: 80,
    bloodSugar: "Coming Soon",
    stress: 1.8,
    bloodPressure: "120 / 80",
    hrvSdnn: 50.64574298787278,
    heartRate: 68.90783564019681,
    hrvRmssd: 17.46661131262679,
    spo2: 98.59814919019,
    bloodAlcohol: "Coming Soon",
    bmi: 22.1,
    ibi: 870.7282625054545,
    temperature: 36.60401162914195,
    generalWellness: 88,
    generalRisk: 0,
    covidRisk: 0,
  };
  return sampleVitalSign;
}

export function generateOneDayData(latestVitalSign) {
  let now = new Date();
  let oneDayData = [];

  let sampleVitalSign = getSampleVitalSign();
  let hoursSince7am = Math.max(0, now.getHours() - 7);
  for (let i = hoursSince7am; i > 0; i--) {
    let dayDataTime = new Date();
    dayDataTime.setHours(now.getHours() - i);

    let tempVitalSigns = {};
    let bloodPressureVar = Math.floor((Math.random() - 0.5) * 10);
    tempVitalSigns.bloodPressureDiastolic =
      sampleVitalSign.bloodPressureDiastolic + bloodPressureVar;
    tempVitalSigns.bloodPressureSystolic =
      sampleVitalSign.bloodPressureSystolic + bloodPressureVar;
    tempVitalSigns.bloodPressure =
      tempVitalSigns.bloodPressureSystolic +
      "/" +
      tempVitalSigns.bloodPressureDiastolic;
    tempVitalSigns.bmi =
      (-0.3 / 7) * i + sampleVitalSign.bmi + (Math.random() - 0.5) * 0.3;
    tempVitalSigns.generalRisk = 1.2 + (Math.random() - 0.5) * 0.02;
    tempVitalSigns.covidRisk = 0.5 + (Math.random() - 0.5) * 0.02;
    tempVitalSigns.generalWellness =
      (10 * (i - 15) ** 2) / 15 ** 2 + 80 + (Math.random() - 0.5) * 5;
    tempVitalSigns.hrvSdnn =
      sampleVitalSign.hrvSdnn + (Math.random() - 0.5) * 60;
    tempVitalSigns.heartRate =
      (5 * (i - 15) ** 2) / 15 ** 2 +
      sampleVitalSign.heartRate +
      (Math.random() - 0.5) * 5;
    tempVitalSigns.hrvRmssd =
      sampleVitalSign.hrvRmssd + (Math.random() - 0.5) * 10;
    tempVitalSigns.ibi = 60000 / tempVitalSigns.heartRate;
    tempVitalSigns.respiratoryRate =
      sampleVitalSign.respiratoryRate + Math.floor((Math.random() - 0.5) * 4);
    tempVitalSigns.spo2 = Math.min(
      100,
      (1.5 * (i - 15) ** 2) / 15 ** 2 +
        tempVitalSigns.spo2 +
        (Math.random() - 0.5) * 5
    );
    tempVitalSigns.stress =
      sampleVitalSign.stress + (Math.random() - 0.5) * 0.2;
    tempVitalSigns.temperature =
      sampleVitalSign.temperature + (Math.random() - 0.5) * 0.5;
    oneDayData.push({
      datetime: dayDataTime,
      vitalSigns: tempVitalSigns,
    });
  }
  let dayDataTime = new Date();
  dayDataTime.setHours(now.getHours() - 1);
  oneDayData.push({
    datetime: dayDataTime,
    vitalSigns: sampleVitalSign,
  });
  if (latestVitalSign) {
    oneDayData.push({
      datetime: now,
      vitalSigns: Object.assign({}, latestVitalSign),
    });
  }
  return oneDayData;
}

export function generateOneMonthData() {
  const sampleVitalSign = getSampleVitalSign();

  let latestVitalSign = {};
  //latestVitalSign = sampleVitalSign;

  // Generate some day data
  let now = new Date();
  let dayDataTime = new Date();
  dayDataTime.setHours(now.getHours() - 1);

  // Create fake data
  let fakeData = Array(30);
  for (let i = 0; i < 30; i++) {
    let temp = Object.assign({}, i === 29 ? latestVitalSign : sampleVitalSign);
    if (
      i == 1 ||
      i == 15 ||
      i == 16 ||
      i == 17 ||
      i == 18 ||
      i == 25 ||
      i == 26
    ) {
      temp = {};
    } else if (i != 29) {
      let bloodPressureVar = Math.floor((Math.random() - 0.5) * 20);
      temp.bloodPressureDiastolic =
        temp.bloodPressureDiastolic + bloodPressureVar;
      temp.bloodPressureSystolic =
        temp.bloodPressureSystolic + bloodPressureVar;
      temp.bloodPressure =
        temp.bloodPressureSystolic + "/" + temp.bloodPressureDiastolic;
      temp.bmi = (-0.3 / 7) * i + temp.bmi + (Math.random() - 0.5) * 0.3;
      temp.generalRisk = 1.2 + (Math.random() - 0.5) * 0.02;
      temp.covidRisk = 0.5 + (Math.random() - 0.5) * 0.02;
      temp.generalWellness =
        (10 * (i - 15) ** 2) / 15 ** 2 + 80 + (Math.random() - 0.5) * 5;
      temp.hrvSdnn = temp.hrvSdnn + (Math.random() - 0.5) * 60;
      temp.heartRate =
        (5 * (i - 15) ** 2) / 15 ** 2 +
        temp.heartRate +
        (Math.random() - 0.5) * 5;
      temp.hrvRmssd = temp.hrvRmssd + (Math.random() - 0.5) * 10;
      temp.ibi = 60000 / temp.heartRate;
      temp.respiratoryRate =
        temp.respiratoryRate + Math.floor((Math.random() - 0.5) * 4);
      temp.spo2 = Math.min(
        100,
        (1.5 * (i - 15) ** 2) / 15 ** 2 + temp.spo2 + (Math.random() - 0.5) * 5
      );
      temp.stress = temp.stress + (Math.random() - 0.5) * 0.2;
      temp.temperature = temp.temperature + (Math.random() - 0.5) * 1.0;
    }
    if (i == 20 || i == 21) {
      temp.stress = temp.stress + 1;
      temp.spo2 = 90 + Math.random() * 2;
    }
    fakeData[i] = temp;
  }
  return fakeData;
}
