<template>
  <div
    class="modal fade"
    id="userProfileInput"
    tabindex="-1"
    aria-hidden="true"
    v-if="!profileFilled"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-close-button close">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h1 class="modal-header fs-3"> {{ t('labels.user_profile') }} </h1>
        <div class="modal-body">
          <div class="container">
            <!-- title -->

            <!-- Personal Information -->
            <div class="row mb-3">
              <div class="fs-5 subheading">
                {{ t("user_info.personal_information") }}
              </div>
            </div>
            <form v-on:submit="saveSettings">
              <div class="row mb-3">
                <!-- Name -->
                <div class="col-6">
                  <label for="nameInput" class="form-label">
                    {{ t("user_info.name") }}
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    class="form-control"
                    type="text"
                    id="nameInput"
                    v-model="this.info.name"
                    @input="checkDisabled"
                  />
                </div>
                <!-- Birthday -->
                <div class="col-6">
                  <label for="birthdayInput" class="form-label">
                    {{ t("user_info.birthday") }}
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    id="birthdayInput"
                    v-model="this.info.birthday"
                    @change="checkDisabled"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <!-- Email -->
                <div class="col-6">
                  <label for="emailInput" class="form-label">
                    {{t('health_report.email')}}
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    class="form-control"
                    type="email"
                    id="emailInput"
                    v-model="this.info.email"
                    @input="checkDisabled"
                  />
                </div>
                <!-- Phone Number -->
                <div class="col-6">
                  <label for="phoneNumberInput" class="form-label">
                    {{t('health_report.phone_number')}}
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="phoneNumberInput"
                    v-model="this.info.phoneNumber"
                    @input="checkDisabled"
                  />
                </div>
              </div>
              <!-- Gender -->
              <div class="row mb-3">
                <div class="col-6">
                  <label for="genderInput" class="form-label">
                    {{ t("user_info.gender") }}
                    <span class="text-danger">*</span>
                  </label>
                </div>
                <div class="col-6">
                  <select
                    class="form-select"
                    id="genderInput"
                    @change="checkDisabled"
                    v-model="this.info.gender"
                  >
                    <option @change="checkDisabled" value=""></option>
                    <option @change="checkDisabled" value="male">
                      {{ t("user_info.male") }}
                    </option>
                    <option @change="checkDisabled" value="female">
                      {{ t("user_info.female") }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- Height & Weight -->
              <div class="row mb-3">
                <!-- Height -->
                <div class="col-6">
                  <label for="heightInput" class="form-label">
                    {{ t("user_info.height") }}
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="heightInput"
                    v-model="this.info.height"
                    @input="checkDisabled"
                  />
                </div>
                <!-- Weight -->
                <div class="col-6">
                  <label for="weightInput" class="form-label">
                    {{ t("user_info.weight") }}
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="weightInput"
                    v-model="this.info.weight"
                    @input="checkDisabled"
                  />
                </div>
              </div>
              <!-- Unit System -->
              <div class="row mb-3">
                <div class="col-6">
                  <label for="unitSystemSwitch" class="form-label">
                    {{ t("user_info.unit_system") }}
                  </label>
                </div>
                <div class="col-6">
                  <div class="align-left">
                    <div class="form-check form-switch" id="unitSystemSwitch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="unitSystemInput"
                        v-model="info.unitSystem"
                        @click="checkDisabled"
                      />
                      <label class="form-check-label" for="unitSystemInput">
                        {{
                          info.unitSystem
                            ? t("user_info.imperial")
                            : t("user_info.metric")
                        }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Countries -->
              <div class="row mb-3">
                <div class="col-6">
                  <label for="countriesInput" class="form-label">{{
                    t("user_info.country")
                  }}</label>
                </div>
                <div class="col-6">
                  <select
                    class="form-select"
                    id="countriesInput"
                    @change="checkDisabled"
                    v-model="this.info.countries"
                  >
                    <option @change="checkDisabled" value=""></option>
                    <option @change="checkDisabled" value="No">
                      {{ t("option.no") }}
                    </option>
                    <option @change="checkDisabled" value="Hong Kong" selected>
                      {{ t("user_info.countryOptions.hongkong") }}
                    </option>
                    <option @change="checkDisabled" value="China">
                      {{ t("user_info.countryOptions.china") }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Medical History -->
              <hr />
              <div class="row mb-3">
                <div class="fs-5 subheading">
                  {{ t("user_info.medical_history") }}
                </div>
              </div>
              <!-- Smoker -->
              <div class="row mb-3">
                <div class="col-6">
                  <label for="smokeSwitch" class="form-label">
                    {{ t("user_info.smoking") }}
                  </label>
                </div>
                <div class="col-6">
                  <div class="align-left">
                    <div class="form-check form-switch" id="smokeSwitch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="smokeInput"
                        v-model="info.smoker"
                        @click="checkDisabled"
                      />
                      <label class="form-check-label" for="smokeInput">
                        {{ info.smoker ? t("option.yes") : t("option.no") }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Hypertension -->
              <div class="row mb-3">
                <div class="col-6">
                  <label for="hypertensionSwitch" class="form-label">
                    {{ t("user_info.hypertension") }}
                  </label>
                </div>
                <div class="col-6">
                  <div class="align-left">
                    <div class="form-check form-switch" id="hypertensionSwitch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="hypertensionInput"
                        v-model="info.hypertension"
                        @click="checkDisabled"
                      />
                      <label class="form-check-label" for="hypertensionInput">
                        {{
                          info.hypertension ? t("option.yes") : t("option.no")
                        }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- BP Medication -->
              <div class="row mb-3">
                <div class="col-6">
                  <label for="bpmedicationSwitch" class="form-label">
                    {{ t("user_info.bp_medication") }}
                  </label>
                </div>
                <div class="col-6">
                  <div class="align-left">
                    <div class="form-check form-switch" id="bpmedicationSwitch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="bpMedicationInput"
                        v-model="info.bloodPressureMedication"
                        @click="checkDisabled"
                      />
                      <label class="form-check-label" for="bpMedicationInput">{{
                        info.bloodPressureMedication
                          ? t("option.yes")
                          : t("option.no")
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Diabetic -->
              <div class="row mb-3">
                <div class="col-6">
                  <label for="diabeticInput" class="form-label">{{
                    t("user_info.diabetic")
                  }}</label>
                </div>
                <div class="col-6">
                  <select
                    class="form-select"
                    id="diabeticInput"
                    @change="checkDisabled"
                    v-model="this.info.diabetic"
                  >
                    <option @change="checkDisabled" value=""></option>
                    <option @change="checkDisabled" value="No">
                      {{ t("option.no") }}
                    </option>
                    <option @change="checkDisabled" value="Type 1">
                      {{ t("user_info.type_1") }}
                    </option>
                    <option @change="checkDisabled" value="Type 2">
                      {{ t("user_info.type_2") }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- heartDisease -->
              <div class="row mb-3">
                <div class="col-6">
                  <label for="heartDiseaseSwitch" class="form-label">
                    {{ t("user_info.heart_disease") }}
                  </label>
                </div>
                <div class="col-6">
                  <div class="align-left">
                    <div class="form-check form-switch" id="heartDiseaseSwitch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="heartDiseaseInput"
                        v-model="info.heartDisease"
                        @click="checkDisabled"
                      />
                      <label class="form-check-label" for="heartDiseaseInput">
                        {{
                          info.heartDisease ? t("option.yes") : t("option.no")
                        }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- depression -->
              <div class="row mb-3">
                <div class="col-6">
                  <label for="depressionSwitch" class="form-label">
                    {{ t("user_info.depression") }}
                  </label>
                </div>
                <div class="col-6">
                  <div class="align-left">
                    <div class="form-check form-switch" id="depressionSwitch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="depressionInput"
                        v-model="info.depression"
                        @click="checkDisabled"
                      />
                      <label class="form-check-label" for="depressionInput">
                        {{ info.depression ? t("option.yes") : t("option.no") }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" :disabled="disabled" data-bs-dismiss="modal" @click="updateProfile">
            {{ t("button.continue") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { useI18n } from "vue-i18n";

export default {
  name: "user-profile-input",
  props: {
    form: Object,
  },
  setup() {
    const { t, locale } = useI18n();
    const {profileFilled, updateProfileFilled} = inject('profileFilled');
    return { 
      profileFilled,
      updateProfileFilled,
      t, locale
    };
  },
  emits: ["updateProfile"],
  mounted() {
    setTimeout(() => {
      this.loadForm();
      this.checkDisabled();
    }, 300);
  },
  components: {},
  methods: {
    loadForm() {
        this.info = this.form;
        if (this.debug) {
          this.info = {
            name: "DebugUser",
            email: "debug@panoptic.ai", 
            phoneNumber: "12345678",
            birthday: "2019-08-19",
            gender: "male",
            height: 170,
            weight: 70,
            unitSystem: false,
            countries: "Hong Kong",
            smoker: false,
            hypertension: false,
            bloodPressureMedication: false,
            diabetic: "No",
            heartDisease: false,
            depression: false,
          }
        }
    },
    checkDisabled() {
      let check = false;
      const required = ['name', 'birthday', 'gender', 'email', 'height', 'weight'];
      Object.keys(this.info).map((data) => {
        if (!required.includes(data)) {
          return;
        }
        if (this.info[data] === null || this.info[data] === "") {
          check = true;
          return;
        }
      });
      this.disabled = check;
    },
    updateProfile() {
      this.$emit("updateProfile", this.info);
      // window.localStorage.userProfile = JSON.stringify(this.form)
    },
  },
  computed: {
    // showGuide() {
    //     console.log("show guide")
    //     return 0
    // }
  },
  watch: {
    form(newValue) {
        this.info = newValue;
        // console.log(newValue);
    }
  },
  data() {
    return {
      info: {
        name: "",
        email: "",
        phoneNumber: "",
        birthday: "",
        gender: "",
        unitSystem: false,
        countries: "",
        height: "",
        weight: "",
        smoker: false,
        hypertension: false,
        bpMedication: false,
        diabetic: "",
        heartDisease: false,
        depression: false,
      },
      disabled: true, 
      debug: this.$root.debugMode
    };
  },
};
</script>

<style scoped>
.modal {
  overflow: hidden;
  max-height: 95%;
  max-width: 98%;
  left: 1%;
  top: 2.5%;
  border-radius: 10px;
}
.modal-dialog {
  width: 100%;
  height: 100%;
}
.modal-close-button {
  position: fixed;
  top: 4.5%;
  right: 3.5%;
}
/* Modal Content/Box */
.modal-content {
  position: grid;
  background-color: #fefefe;
  margin: 0% auto;
  padding: 5px;
  border: 1px solid #888;
  min-height: 95%;
  min-width: 100%;
  overflow: hidden;
}
.modal-header {
  display: flex;
  height: 10%;
  justify-content: center;
  font-size: xx-large;
}
.modal-body {
  display: grid;
  width: 100%;
  justify-content: center;
  padding: 2px;
  margin: 2px auto;
}
.content {
  /* border-style: dashed;
    border: 2px purple; */
  padding: 1%;
}
.content-title {
  font-weight: bolder;
  font-size: x-large;
}
.photo-div {
  /* border: 1px red;
    border-style: solid; */
  margin: 0 auto;
  padding: 10px;
}
.photo {
  max-width: 80%;
  margin: 0 auto;
}
.center-item {
  display: grid;
  justify-content: center;
}
.paragraph-div {
  /* border: 1px blue;
    border-style: solid; */
  text-align: justify;
  padding: 5px;
}
.paragraph-title {
  font-weight: bolder;
  font-size: x-large;
}
.paragraph-content {
  font-size: medium;
  width: 100%;
}
.modal-footer {
  bottom: 0px;
  height: 10%;
  justify-content: center;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>


