
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { onAuthStateChanged, browserLocalPersistence } from "firebase/auth";
import { cloudBackend } from './user-manager-definitions';
import type { companyUser, User, VitalSignOld, Health, userSettings, companySettings } from './user-definitions'
import { BaseUser, BaseCompanyUser } from './user-base'
import { generateEmptyCompanySettings, generateId, constructVitalSignsFromHealth } from "./user_utilities"
import { _generateLastScanResult } from "./user-base"  // for debug
import {
    collection,
    addDoc,
    query,
    where,
    getDocs,
    orderBy,
    limit,
    doc,
    setDoc,
    Timestamp,
    getFirestore,
  } from "firebase/firestore";
import _, { noop } from "lodash";
import Logger from "../common/logger";
const firebaseui = require("firebaseui");

export class FirebaseUser extends BaseCompanyUser implements companyUser {
    declare cloudInfo?: any | undefined
    declare name?: any | undefined
    declare currentUser: User | undefined
    userType: cloudBackend = cloudBackend.firebase;
    db: any

    constructor() {
        super()
        this.db = getFirestore();
    }
    async getCompanySettings(): Promise<companySettings | undefined> {
        Logger.log('call getUsersettings for uid', this.UID, this.name)
        try {

            const settingsRef = collection(this.db, "companyProfile");
            const q = query(settingsRef, where("UID", "==", this.UID));
            const docs = await getDocs(q);
            Logger.log("%c < query firestore > companyUser.getCompanySettings", "color: #A0A0A0", this.UID, this.name,)
      
            let res: Array<companySettings> = [];
            docs.forEach((doc) => res.push(doc.data() as companySettings));
            if (res[0]) {
                this.companySettings = res[0];
            } else {
                this.companySettings = generateEmptyCompanySettings();
            }
            if (this.UID && this.companySettings) { 
                // update dummy userId with the userId generate by the cloudbackend
                this.companySettings.UID = this.UID; 
            }
        } catch(e) {
            Logger.error(e);
            console.error(e)
        }
        return this.companySettings
    }
    async setCompanySettings(newCompanySettings: companySettings): Promise<any> {
        Logger.log("%csetCompanySettings: ", "color: #A0A0A0", this.companySettings, newCompanySettings)
        this.companySettings = newCompanySettings;
        try {
            const settingsRef = collection(this.db, "companyProfile");

            let docId = this.UID;
            await setDoc(doc(settingsRef, docId), this.companySettings);
            Logger.log(`%c < setDoc firestore > companyUser.setCompanySettings @ ${docId}`, "color: #696969")

            return true;
          } catch (e) {
            Logger.error(e);
            Logger.log("failed to update settings", "", e);
            return false;
          }
    }
    async uploadScanResults() {
        Logger.log('%cUpload Scan Results', 'color:green')
        Logger.log(this.currentUser)
        let currentVS = this.currentUser?.lastHealth?.vitalSigns
        if (currentVS) {
            let vitalSigns = {
                bloodAlcohol: currentVS.bloodAlcohol,
                bloodPressure: currentVS.bloodPressure,
                bloodPressureDiastolic: currentVS.bloodPressureDiastolic,
                bloodPressureSystolic: currentVS.bloodPressureSystolic, 
                bloodSugar: currentVS.bloodSugar,
                heartRate: currentVS.heartRate,
                hrvIbi: currentVS.ibi,
                hrvRmssd: currentVS.hrvRmssd,
                hrvSdnn: currentVS.hrvSdnn,
                respiratoryRate: currentVS.respiratoryRate,
                spo2: currentVS.spo2,
                stress: currentVS.stress,
                stressScore: currentVS?.stressScore,
                temperature: currentVS.temperature,
                vseVersion: currentVS?.version,
            }

            // clear undefined
            let results = JSON.parse(JSON.stringify(currentVS, function(k, v) {
                if (v === undefined) { return null; } 
                else { return v; } 
            }));
            vitalSigns = results;

            let userInfo = {
                userId: this.currentUser?.userId, 
                email: this.currentUser?.userSettings?.email, 
                phoneNumber: this.currentUser?.userSettings?.phoneNumber,
                profileImage: this.currentUser?.userSettings?.profileImage, 
                name: this.currentUser?.userSettings?.name, 
                birthday: this.currentUser?.userSettings?.birthday, 
                gender: this.currentUser?.userSettings?.gender, 
                height: this.currentUser?.userSettings?.height, 
                weight: this.currentUser?.userSettings?.weight, 
                unitSystem: this.currentUser?.userSettings?.unitSystem, 
                countries: this.currentUser?.userSettings?.countries, 
                smoker: this.currentUser?.userSettings?.smoker, 
                hypertension: this.currentUser?.userSettings?.hypertension, 
                bloodPressureMedication: this.currentUser?.userSettings?.bloodPressureMedication, 
                diabetic: this.currentUser?.userSettings?.diabetic, 
                heartDisease: this.currentUser?.userSettings?.heartDisease,
                depression: this.currentUser?.userSettings?.depression,
                userTier: null, 
            }

            // clear undefined
            results = JSON.parse(JSON.stringify(userInfo, function(k, v) {
                if (v === undefined) { return null; } 
                else { return v; } 
            }));
            userInfo = results;
    
            let scanResults = {
                datetime: this.currentUser?.lastHealth?.datetime,
                UID: this.UID,
                location: this.companySettings?.location,
                userInfo: userInfo,
                scanParameters: this.currentUser?.lastHealth?.scanParameters,
                facialSkin: this.currentUser?.lastHealth?.facialSkin,
                vitalSigns: vitalSigns,
                risks: this.currentUser?.lastHealth?.risks,
                holisticHealth: this.currentUser?.lastHealth?.holisticHealth,
            }
            Logger.log(scanResults)
            try {
                let docId = [this.UID?.toString(), new Date().toISOString(), generateId(4)].join("_");
                const settingsRef = collection(this.db, "companyData");
                await setDoc(doc(settingsRef, docId), scanResults);
                Logger.log(`%c < setDoc firestore > companyUser.uploadScanResults @ ${docId}`, "color: #696969", scanResults);
                // let doc = {
                //   vital_sign: this.lastVitalSigns,
                //   date: new Date(),
                //   user_id: this.userId,
                // };
                // const docRef = await addDoc(collection(this.db, "companyData"), scanResults);
                // Logger.log("%c < addDoc firestore > Document written with ID: ", "color: #696969", docRef.id, new Date());
            } catch (e) {
                Logger.error(e);
                console.error("Error adding document: ", e, scanResults);
            }
        }
        return ;
        // if ( this.currentUser ) {
        //     try {
        //         let docId = [this.UID?.toString(), new Date().toISOString(), generateId(4)].join("_");
        //         const settingsRef = collection(this.db, "companyData");
        //         await setDoc(doc(settingsRef, docId), this.currentUser.lastHealth);
        //         Logger.log(`%c < setDoc firestore > companyUser.uploadScanResults @ ${docId}`, "color: #696969", this.currentUser.lastHealth);
        //         // let doc = {
        //         //   vital_sign: this.lastVitalSigns,
        //         //   date: new Date(),
        //         //   user_id: this.userId,
        //         // };
          
        //         // const docRef = await addDoc(collection(this.db, "vitalSigns"), doc);
        //         // Logger.log("%c < addDoc firestore > Document written with ID: ", "color: #696969", docRef.id, new Date());
        //       } catch (e) {
        //         console.error("Error adding document: ", e, this.currentUser.lastHealth);
        //       }
        // }
    }
    async loadData(): Promise<void> {
        Logger.log('%cTODO: implement companyUser.loadData', 'color:red')
    }
    async login(containerId: string): Promise<void> {
        const auth = getAuth();

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                this.cloudInfo = user
                user.displayName? this.name = user.displayName : "Firebase Testing User";
                this.UID = user.uid;
            } else {
                // currentUser = undefined;
            }
        });

        if (auth.currentUser !== null) {
                Logger.log("Already logged in: ","", auth.currentUser);
                this.UID = auth.currentUser.uid;
                auth.currentUser.displayName? this.name = auth.currentUser.displayName : 'Firebase Testing User';
            // currentUser = new User(auth.currentUser);
            return;
        }

        await auth.setPersistence(browserLocalPersistence);

        return new Promise((resolve) => {
            const uiConfig = {
            signInOptions: [
                {
                provider: EmailAuthProvider.PROVIDER_ID,
                disableSignUp: {
                    status: true,
                    adminEmail: "",
                },
                },

                // {
                //   provider: GoogleAuthProvider.PROVIDER_ID,
                //   disableSignUp: {
                //     status: true,
                //     adminEmail: "",
                //     helpLink: "https://www.example.com/trouble_signing_in",
                //   },
                // },
                // {
                //   provider: FacebookAuthProvider.PROVIDER_ID,
                //   disableSignUp: {
                //     status: true,
                //     adminEmail: "",
                //     helpLink: "https://www.example.com/trouble_signing_in",
                //   },
                // },
                // {
                //   provider: TwitterAuthProvider.PROVIDER_ID,
                //   disableSignUp: {
                //     status: true,
                //     adminEmail: "",
                //     helpLink: "https://www.example.com/trouble_signing_in",
                //   },
                // },
                // {
                //   provider: GithubAuthProvider.PROVIDER_ID,
                //   disableSignUp: {
                //     status: true,
                //     adminEmail: "",
                //     helpLink: "https://www.example.com/trouble_signing_in",
                //   },
                // },
            ],
            callbacks: {
                signInSuccessWithAuthResult: (result: any) => {
                // TODO: Send the user a verification email
                // https://firebase.google.com/docs/auth/web/manage-users#send_a_user_a_verification_email
                
                resolve(result);
                },
            },
            };

            try {
                if (firebaseui.auth.AuthUI.getInstance()) {
                    const ui = firebaseui.auth.AuthUI.getInstance();
                    ui.start(containerId, uiConfig);
                } else {
                    const ui = new firebaseui.auth.AuthUI(getAuth());
                    ui.start(containerId, uiConfig);
                }
            } catch (err) {
                    Logger.error(err);
                    console.error(err);
            }
        });
    }
    async logout(): Promise<void> {
        await getAuth().signOut();
        Logger.log(this)

        // clear all states and data
        this.cloudInfo = undefined;
    }
    async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
        const auth = getAuth();
        let success = false

        if (!auth.currentUser || !auth.currentUser.email) {
            return false
        }

        const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            oldPassword
        );
        await reauthenticateWithCredential(auth.currentUser, credential)
            .then(async () => {
                if (!auth.currentUser) {
                    return false
                }

                await updatePassword(
                    auth.currentUser,
                    newPassword
                )
                    .then(() => {
                        // alert("Successfully changed password");
                        success = true
                    })
                    .catch((e) => {
                    Logger.error(e);
                    // alert("Failed to change password");
                    });
                })
                .catch((e) => {
                    Logger.error(e);
                    if (e == "FirebaseError: Firebase: Error (auth/wrong-password).") {
                        // alert("Old password is wrong.");
                    }
            });
        return success
    }
    async ready(): Promise<void> {
        let promise1 = new Promise((resolve) => {
            onAuthStateChanged(getAuth(), resolve);
        });

        const timeout = 3000; // ms
        let promise2 = new Promise((resolve) => setTimeout(resolve, timeout));

        let user = await Promise.race([promise1, promise2]);
        if (user) {
            this.cloudInfo = user;
            // if (!currentUser || currentUser.userId != user.uid) {
            //     currentUser = new User(user, "firebase");
            // } else {
            //     currentUser.userObj = user;
            // }
        }
    }
}