<template>
  <div
    class="modal fade"
    id="detailedInformationScreenModal"
    tabindex="-1"
    aria-labelledby="detailedInformationScreenModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <img
            class="button-icon"
            :src="require('./assets/left-arrow-icon.svg')"
            data-bs-dismiss="modal"
            aria-label="Close"
            type="button"
          />
          <div class="container-fluid" id="header-container">
            <img
              class="image"
              v-if="this.data[selectedTag]"
              :src="this.data[selectedTag]['image']"
            />
            <h5 class="modal-title" id="modal-title">
              {{
                selectedTag !== "" && this.data[selectedTag]
                  ? t('vital_signs.' + selectedTag + '.title') 
                : t('no_data')
              }}
            </h5>
          </div>
        </div>
        <div class="modal-body container-fluid">
          <div class="row">
            <h2>{{ t('detailed_info.history') }}</h2>
            <div class="col">
              <detailed-information-chart
                id="modal-history-chart"
                :oneDayData="oneDayData"
                :oneWeekData="oneWeekData"
                :oneMonthData="oneMonthData"
                :vsTag="selectedTag ? this.data[selectedTag]['data_tag'] : null"
                :dataRange="
                  selectedTag ? this.data[selectedTag]['max_range'] : 100
                "
                :referenceTag="selectedTag"
                :linearReg="getLR"
                :movingAverage="movingAverage"
                :anomaly="getAnomaly"
                :title="
                  selectedTag ? 
                    t('vital_signs.' + selectedTag + '.title') 
                  : t('vital_signs.general_wellness.title')"
              />
            </div>
          </div>
          <div class="row">
            <div class="col" id="modal-first-row">
              <div id="modal-data-display" :style="getStyle">
                {{
                  this.data[selectedTag]
                    ? this.value(
                        this.vsData[this.data[selectedTag]["data_tag"]]
                      )
                    : t('no_data')
                }}
              </div>
              <div id="modal-data-explanation">
                <p v-if="this.data[selectedTag]">
                  {{ getCurrentScan }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <h4>{{ t('detailed_info.trend_analysis') }}</h4>
              <div>
                <p>{{getTrend}}</p>
                <p>{{this.anomalyText}}</p>
                <p v-if="te('vital_signs.' + selectedTag)" v-html=" t('vital_signs.' + selectedTag + '.body[1]')">
                </p>
              </div>
              <h5>{{ t('detailed_info.references') }}</h5>
              <div>
                <ol v-if="this.data[selectedTag]">
                  <li
                    v-for="(item, index) in this.data[selectedTag][
                      'references'
                    ]"
                    :key="index + this.selectedTag + 'reference'"
                  >
                    <a
                      target="_blank"
                      :href="this.data[selectedTag]['references'][index]"
                      >{{ this.data[selectedTag]["references"][index] }}</a
                    >
                  </li>
                </ol>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <h4>{{t('detailed_info.things_to_do')}}</h4>
              <div>
                <ol v-if="this.data[selectedTag]">
                  <li
                    v-for="(item, index) in this.data[selectedTag]['things_to_do']"
                    :key="index + this.selectedTag + 'todo'"
                  >
                    {{ t('things_to_do.' + item) }}
                  </li>
                </ol>
              </div>
              <h4>{{t('detailed_info.note')}}</h4>
              <div>
                <p v-if="this.data[selectedTag]" v-html="t('vital_signs.' + selectedTag + '.body[2]')">
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import DetailedInformationChart from "./detailed-information-chart.vue";
import { checkCondition, colors } from "./vitalRangeChecker";
import { checkOutlier, interquartile_bound } from "./statistics";
import { useI18n } from 'vue-i18n';

export default {
  props: {
    selectedTag: String,
    vsData: undefined,
    oneDayData: Array,
    oneWeekData: Array,
    oneMonthData: Array,
  },

  setup() {
    const { t, te, locale } = useI18n()
    const modal_tag = ref("");
    const linearReg = ref();
    const lr = ref();
    const anomalyText = ref("")
    return { 
      modal_tag, 
      linearReg, 
      lr, 
      anomalyText,
      t, te, locale };
  },
  components: {
    DetailedInformationChart,
  },
  methods: {
    value(data) {
      let unit = this.data[this.selectedTag]
        ? " " + this.data[this.selectedTag]["unit"]
        : "";
      if (typeof data == "number") {
        let str = data.toFixed(1).toString() + unit;
        return str;
      } else if (typeof data == "string") {
        let str = data + unit;
        return str;
      } else {
        return this.t('no_data');
      }
    },
    setAnomalyText(anomalies) {
      if (anomalies.length > 0) {
        this.anomalyText = this.t('analysis.anomalies', {
          date: anomalies.join(','),
          vital_sign: this.selectedTag 
            ? this.t('vital_signs.' + this.selectedTag + '.title') 
          : this.t('vital_signs.general_wellness.title')
        })
      } else {
        this.anomalyText = ""
      }
    },
    setLinearReg(y, lr, tag) {
      this.linearReg = [];
      if(tag === 'bloodPressure') {
        for (let i = 0; i < 7; i++) {
          this.linearReg.push({systolic : {x:i, y:i*lr.systolic.slope+lr.systolic.intercept}, diastolic : {x:i, y:i*lr.diastolic.slope+lr.diastolic.intercept}});
        }
        if (lr.systolic.slope < 0 && lr.diastolic.slope < 0) {
          this.lr = lr.systolic.slope < lr.diastolic.slope ? lr.systolic : lr.diastolic;
        }
        else {
          this.lr = lr.systolic.slope > lr.diastolic.slope ? lr.systolic : lr.diastolic;
        }
      }
      else {
        this.linearReg =[0,1,2,3,4,5,6].map((b,a) => {return {x:a, y:a*lr.slope+lr.intercept}});
        this.lr = lr;
      }
    },
    linearRegression(y, x) {
      y = y.filter(a => !isNaN(a) && a !== null);
      var lr = {};
      var n = y.length;
      var sum_x = 0;
      var sum_y = 0;
      var sum_xy = 0;
      var sum_xx = 0;
      var sum_yy = 0;

      for (var i = 0; i < y.length; i++) {
        sum_x += x[i];
        sum_y += y[i];
        sum_xy += x[i] * y[i];
        sum_xx += x[i] * x[i];
        sum_yy += y[i] * y[i];
      }

      lr["slope"] = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
      lr["intercept"] = (sum_y - lr.slope * sum_x) / n;
      lr["r2"] = Math.pow(
        (n * sum_xy - sum_x * sum_y) /
          Math.sqrt(
            (n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)
          ),
        2
      );
      return lr;
    },
  },
  computed: {
    getStyle() {
      let containerStyle = {};
      if (this.selectedTag && this.data[this.selectedTag]["data_tag"]) {
        let tag = this.selectedTag;
        let dataTag = this.data[this.selectedTag]["data_tag"];
        let vsData = this.vsData[dataTag];
        let category = checkCondition(tag, vsData);
        let containerBorderColor;
        containerBorderColor = colors[category];
        containerStyle = {
          border: `5px solid ${containerBorderColor}`,
          "backdrop-filter": `blur(30px)`,
        };
      }

      return containerStyle;
    },
    getLR() {
      if(this.oneWeekData === undefined) {
        return;
      }
      let category = this.selectedTag;
      let dataUsed = this.oneWeekData;     
      let dataTag = category === '' ? 'generalWellness' : this.data[this.selectedTag]["data_tag"];

      let lr; 
       if (category === "bloodPressure") {
        let x = [];
        let ySystolic = [];
        let yDiastolic = [];
        for (let i = 0; i < dataUsed.length; i++) {
          if(!isNaN(dataUsed[i]['bloodPressureSystolic'])) {
            x.push(i);
            ySystolic.push(dataUsed[i]['bloodPressureSystolic']);
            yDiastolic.push(dataUsed[i]['bloodPressureDiastolic']);
          }
        }
        let lrSystolic = this.linearRegression(ySystolic, x);
        let lrDiastolic = this.linearRegression(yDiastolic, x);
        this.setLinearReg({systolic: ySystolic, diastolic: yDiastolic}, {systolic: lrSystolic, diastolic: lrDiastolic}, 'bloodPressure');
      } else {
        let x = [];
        let y = [];
        for (let i = 0; i < dataUsed.length; i++) {
        if(!isNaN(dataUsed[i][dataTag])) {
            x.push(i);
            y.push(dataUsed[i][dataTag]);
          }
        }
        lr = this.linearRegression(y, x);
        this.setLinearReg(y, lr, dataTag);
      }
      return [this.linearReg, lr];
    },
    getTrend() {
      if(this.oneWeekData === undefined) {
        return;
      }
      let category = this.selectedTag;

      let dataTag = category === '' ? 'generalWellness' : this.data[this.selectedTag]["data_tag"];
      let dataUsed = this.oneWeekData.filter(data =>data[dataTag]);
      let lr = this.lr; 
      let average;
      
      if (category === "bloodPressure") {
        let averageSystolic = dataUsed.reduce((a,b) => a+b['bloodPressureSystolic'], 0) / dataUsed.length;
        let averageDiastolic = dataUsed.reduce((a,b) => a+b['bloodPressureDiastolic'], 0) / dataUsed.length;
        average = `${averageSystolic.toFixed(0)} / ${averageDiastolic.toFixed(0)}`
      }
      else {
        let sum = dataUsed.reduce((a, b) => a + b[dataTag], 0);
        average = (sum / dataUsed.length);
      }
      category = this.selectedTag;

      if (category === '') {
        return '';
      }

      let tag = this.t('vital_signs.' + category + '.title');
      let unit = this.data[category].unit;
      let condition = checkCondition(category, average)
      let quality = condition.split("_");

      average = category === 'bloodPressure' ? average : average.toFixed(1);

      let analysis = "";

      if (quality[0] === "excellent") {
        let initialCondition = checkCondition(category, dataUsed[0][dataTag]);
        if (lr.slope < -0.1) {
          if (initialCondition === 'good_upper' || initialCondition === 'poor_upper')
          analysis = `${this.t('analysis.record', 
                      {
                        tag:tag, 
                        quality: this.t('analysis.' + quality[0]), 
                        average: average, 
                        unit: unit})
                      }${this.t('analysis.changing', 
                      {
                        conjunction: this.t('analysis.and'),
                        slope: this.t('analysis.decreasing'),
                        condition: this.t('analysis.better')
                      })} ${this.t('analysis.good_work')}`;
          else {
            analysis = `${this.t('analysis.record', 
                      {
                        tag:tag, 
                        quality: this.t('analysis.' + quality[0]), 
                        average: average, 
                        unit: unit})
                      }${this.t('analysis.decrease_worse', 
                      {
                        conjunction: this.t('analysis.however'),
                      })}`;
          }
        } else if (lr.slope > 0.1) {
          if (initialCondition === 'good_lower' || initialCondition === 'poor_lower'){
            analysis = `${this.t('analysis.record', 
                      {
                        tag:tag, 
                        quality: this.t('analysis.' + quality[0]), 
                        average: average, 
                        unit: unit})
                      }${this.t('analysis.changing', 
                      {
                        conjunction: this.t('analysis.and'),
                        slope: this.t('analysis.increasing'),
                        condition: this.t('analysis.better')
                      })} ${this.t('analysis.good_work')}`;
          }
          else {
            analysis = `${this.t('analysis.record', 
                      {
                        tag:tag, 
                        quality: this.t('analysis.' + quality[0]), 
                        average: average, 
                        unit: unit})
                      }${this.t('analysis.increase_worse', 
                      {
                        conjunction: this.t('analysis.however'),
                      })}`;
          }
        } else {
          analysis = `${this.t('analysis.constant', 
                      {
                        tag:tag, 
                        quality: this.t('analysis.' + quality[0]), 
                        average: average, 
                        unit: unit
                      })
                      } ${this.t('analysis.good_work')}`;
        }
      } else {
        if (lr.slope < -0.1) {
          analysis = this.t('analysis.record', 
                      {
                        tag:tag, 
                        quality: this.t('analysis.' + quality[0]), 
                        average: average, 
                        unit: unit});
          analysis += `${
            quality[1] === "lower"
              ? `${this.t('analysis.changing', 
              {
                conjunction: quality[0] === 'poor' ? this.t('analysis.and') : this.t('analysis.however'),
                slope: this.t('analysis.decreasing'),
                condition: this.t('analysis.worse')
              })} ${this.t('analysis.improve')}`
              : `${this.t('analysis.changing', 
              {
                conjunction: quality[0] === 'poor' ? this.t('analysis.however') : this.t('analysis.and'),
                slope: this.t('analysis.decreasing'),
                condition: this.t('analysis.better')
              })} ${this.t('analysis.good_work')}`
          }`;
        } else if (lr.slope > 0.1) {
          analysis = this.t('analysis.record', 
                      {
                        tag:tag, 
                        quality: this.t('analysis.' + quality[0]), 
                        average: average, 
                        unit: unit});
          analysis += `${
            quality[1] === "lower"
              ? `${this.t('analysis.changing', 
              {
                conjunction: quality[0] === 'poor' ? this.t('analysis.however') : this.t('analysis.and'),
                slope: this.t('analysis.increasing'),
                condition: this.t('analysis.better')
              })} ${this.t('analysis.good_work')}`
              : `${this.t('analysis.changing',
              {
                conjunction: quality[0] === 'poor' ? this.t('analysis.and') : this.t('analysis.however'),
                slope: this.t('analysis.increasing'),
                condition: this.t('analysis.worse')
              })} ${this.t('analysis.improve')}`
          }`;
        } else {
          analysis = `${this.t('analysis.constant', 
                      {
                        tag:tag, 
                        quality: this.t('analysis.' + quality[0]), 
                        average: average, 
                        unit: unit
                      })
                      } ${this.t('analysis.good_work')}`;
          analysis += `${
            quality[1] === "lower"
              ? ` ${this.t('analysis.increase')} ${this.t('analysis.improve')}`
              : ` ${this.t('analysis.decrease')} ${this.t('analysis.improve')}`
          }`;
        }
      }
      
      return analysis;
    },
    getCurrentScan() {
      if (this.vsData === undefined || this.vsData == {}) {
        return;
      }
      let category = this.selectedTag;
      let vsData = this.vsData[this.data[category]['data_tag']];
      let text = "";
      let condition = checkCondition(category, this.vsData[this.data[category]['data_tag']]).split('_')[0];
      if(category == 'covidRisk' || category == 'generalRisk') {
        let risk = {
          excellent: "low",
          good: "moderate",
          poor: "high"
        }
        condition = risk[condition];
      }
      if(vsData) {
        text = this.t('vital_signs.' + category + '.body[0]', {
        value: category === 'bloodPressure' ? vsData : vsData.toFixed(1),
        condition: this.t('analysis.' + condition)
      })
      } else {
        text = this.t('no_data');
      }
      return text;
    },
    movingAverage() {
      let window = 3;
      if (this.oneMonthData === undefined || this.selectedTag === '') {
        return;
      }
      if (this.selectedTag === 'bloodPressure') {
        let result = this.oneMonthData.map((d, index) => {
          let sum_systolic = d['bloodPressureSystolic'];
          let sum_diastolic = d['bloodPressureDiastolic'];
          let count = 1;
          if (index < window) {
            for (let i = index-1; i >=0 ; i--) {
              if(!isNaN(this.oneMonthData[i]['bloodPressureSystolic'])) {
                sum_systolic += this.oneMonthData[i]['bloodPressureSystolic'];
                sum_diastolic += this.oneMonthData[i]['bloodPressureDiastolic'];
                count++;
              }
            }
          } else {
            let i = 1;
            do {
              if (!isNaN(this.oneMonthData[index-i]['bloodPressureSystolic'])) {
                sum_systolic += this.oneMonthData[index-i]['bloodPressureSystolic'];
                sum_diastolic += this.oneMonthData[index-i]['bloodPressureDiastolic'];
                count++;
              }
              i++;
              if (count == window) {
                break;
              }
            }
            while (index - i >= 0)
          }
            return {systolic: sum_systolic/(count), diastolic: sum_diastolic/(count)};
        })
        return result;
      } else {
        let result = this.oneMonthData.map((d, index) => {
          let sum = d[this.data[this.selectedTag]['data_tag']];
          let count = 1;
          if (index < window) {
            for (let i = index-1; i >=0 ; i--) {
              if(!isNaN(this.oneMonthData[i][this.data[this.selectedTag]['data_tag']])) {
                sum += this.oneMonthData[i][this.data[this.selectedTag]['data_tag']];
                count++;
              }
            }
          } else {
            let i = 1;
            do {
              if (!isNaN(this.oneMonthData[index-i][this.data[this.selectedTag]['data_tag']])) {
                sum += this.oneMonthData[index-i][this.data[this.selectedTag]['data_tag']];
                count++;
              }
              i++;
              if (count == window) {
                break;
              }
            }
            while (index - i >= 0)
          }
          return sum/count;
        })
        return result;
      }
    },
    getAnomaly() {
      if (this.oneMonthData === undefined) {
        return;
      }
      const iqr_outlier = []

      const tag = this.selectedTag !== '' ? this.data[this.selectedTag]["data_tag"] : 'generalWellness'
      
      if(tag ==='bloodPressure') {
        const iqr_systolic = interquartile_bound(this.oneMonthData, 'bloodPressureSystolic');
        const iqr_diastolic = interquartile_bound(this.oneMonthData, 'bloodPressureDiastolic');
        this.oneMonthData.map((data, index) => {
          let date = new Date();
          date.setDate(date.getDate()-29+index)
          if (checkOutlier('bloodPressureSystolic', data['bloodPressureSystolic'], iqr_systolic) || 
            checkOutlier('bloodPressureDiastolic', data['bloodPressureDiastolic'], iqr_diastolic)) {
              iqr_outlier.push(date.toString().slice(3,15));
          }
        })
      }
      else {
        const iqr = interquartile_bound(this.oneMonthData, tag);

        this.oneMonthData.map((data, index) => {
          let date = new Date();
          date.setDate(date.getDate()-29+index);
          if (checkOutlier(tag, data[tag], iqr)) {iqr_outlier.push(date.toString().slice(3,15));}
        })
      }
      this.setAnomalyText(iqr_outlier);
      return iqr_outlier;
    }
  },
  data() {
    return {
      data: {
        heartRate: {
          title: "Heart Rate",
          things_to_do: [
            'exercise', 'eat_right', 'stress', 'smoke'
          ],
          references: [
            "https://www.health.harvard.edu/",
            "https://www.templehealth.org/",
            "https://www.webmd.com/",
          ],
          image: require("./assets/heart-icon.svg"),
          unit: "bpm",
          data_tag: "heartRate",
          max_range: 220,
        },
        spo2: {
          title: "O2 Saturation",
          things_to_do: ['fresh_air', 'drink_water', 'iron_food', 'train_breathing'],
          references: [
            "https://www.houstonmethodist.org/",
            "https://www.ncbi.nlm.nih.gov/",
          ],
          image: require("./assets/o2-saturation-icon.svg"),
          unit: "%",
          data_tag: "spo2",
          max_range: 100,
        },
        bmi: {
          title: "BMI",
          things_to_do: ['strive_active', 'veggies', 'fruits'],
          references: ["https://familydoctor.org"],
          image: require("./assets/scale.svg"),
          unit: "",
          data_tag: "bmi",
          max_range: 30,
        },
        hrvIbi: {
          title: "HR Variability IBI",
          things_to_do: ['exercise', 'hydrate', 'diet'],
          references: ["https://www.nature.com/", "https://www.whoop.com"],
          image: require("./assets/heart-icon.svg"),
          unit: "ms",
          data_tag: "ibi",
          max_range: 1700,
        },
        stress: {
          title: "Stress Rating",
          things_to_do: ['exercise', 'thankful', 'talk'],
          references: ["https://www.rescueremedy.com/en-us/"],
          image: require("./assets/stress-rate-icon.svg"),
          unit: "",
          data_tag: "stress",
          max_range: 5,
        },
        facialSkinAge: {
          title: "Facial Skin Age",
          things_to_do: ['hydrate', 'eat_healthy', 'sleep'],
          references: [
            "https://platinumdermatology.com.au",
          ],
          image: require("./assets/facial-age.svg"),
          unit: "",
          data_tag: "facialSkinAge",
          max_range: 200,
        },
        hrvSdnn: {
          title: "HR Variability SDNN",
          things_to_do: ['alcohol', 'sleep', 'breathing'],
          references: [
            "https://www.ncbi.nlm.nih.gov/",
            "https://www.whoop.com",
          ],
          image: require("./assets/heart-rate-variability-icon.svg"),
          unit: "ms",
          data_tag: "hrvSdnn",
          max_range: 232,
        },
        temperature: {
          title: "Temperature(Beta)",
          things_to_do: ['drinking', 'bath', 'cool'],
          references: [
            "https://www.everydayhealth.com/",
            "https://www.webmd.com",
            "https://www.mayoclinic.org/"
          ],
          image: require("./assets/thermometer.svg"),
          unit: "°C",
          data_tag: "temperature",
          max_range: 45.0,
        },
        bloodAlcohol: {
          title: "Blood Alcohol",
          things_to_do: [],
          image: require("./assets/blood-alcohol.svg"),
          unit: "",
          data_tag: "bloodAlcohol",
        },
        respiratoryRate: {
          title: "Breathing Rate",
          things_to_do: ['green', 'active', 'eat_well', 'drink'],
          references: [
            "https://www.hopkinsmedicine.org/",
            "https://www.genesismedical.org/",
            "https://www.ncbi.nlm.nih.gov/",
            "https://www.livescience.com/",
          ],
          image: require("./assets/lung.svg"),
          unit: "bpm",
          data_tag: "respiratoryRate",
          max_range: 25,
        },
        bloodPressure: {
          title: "Blood Pressure (Beta)",
          things_to_do: ['sweat', 'eat_healthy', 'meditate'],
          references: [
            "https://www.heart.org",
            "https://www.mayoclinic.org/",
            "https://www.cdc.gov/",
          ],
          image: require("./assets/meter-icon.svg"),
          unit: "mm Hg",
          data_tag: "bloodPressure",
          max_range: 140,
        },
        bloodSugar: {
          title: "Blood Sugar",
          body: "Loren ipsum dolor sit amet",
          image: require("./assets/blood-sugar.svg"),
          unit: "",
          data_tag: "bloodSugar",
        },
        generalRisk: {
          title: "Cardiovascular Risk",
          things_to_do: ["control_bp", "alcohol", "exercise", "smoke"],
          references: [
            "https://www.healthline.com/",
            "https://www.nhs.uk/",
            "https://www.who.int/",
            "https://www.health.harvard.edu/",
            "https://www.templehealth.org/",
            "https://www.webmd.com/",
          ],
          image: require("./assets/heart-icon.svg"),
          unit: "%",
          data_tag: "generalRisk",
          max_range: 100,
        },
        covidRisk: {
          title: "Covid Risk",
          things_to_do: ["vaccine", "distance", "mask", "open_area", "wash_hands", "cover_cough"],
          references: [
            "https://www.who.int/",
            "https://www.bhf.org.uk/",
            "https://www.chp.gov.hk/",
            "https://www.health.harvard.edu/",
            "https://www.templehealth.org/",
            "https://www.webmd.com/",
          ],
          image: require("./assets/heart-icon.svg"),
          unit: "%",
          data_tag: "covidRisk",
          max_range: 100,
        },
      },
    };
  },
};
</script>

<style scoped>
.container-fluid {
  padding-left: 5%;
  padding-right: 5%;
}

.button-icon {
  position: absolute;
  padding-left: 2vw;
  filter: brightness(0) saturate(100%) invert(81%) sepia(37%) saturate(1098%)
    hue-rotate(330deg) brightness(103%) contrast(92%);
}

.modal-header {
  border: none;
  justify-content: flex-start;
  background-color: white;
  padding: auto;
  padding-top: 1rem;
}

#modal-title {
  margin-left: 1em;
  font-size: 1.75rem;
  color: #f5b33b;
}

#header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vertical-align-center {
  margin-top: auto;
  margin-bottom: auto;
}

.modal-body {
  font-size: 1.25rem;
}

.modal-body > [class*="row"] {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modal-content {
  background-color: #e4e3e3;
}

#modal-first-row {
  display: flex;
  flex-direction: row;
}

#modal-data-display {
  font-size: 1.75rem;
  font-weight: 500;
  padding: 0.3em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  border: 5px solid #00d700;
  box-sizing: border-box;
  width: fit-content;
  height: fit-content;
  text-align: center;
  flex: 2;
}

#modal-data-explanation {
  margin-left: 2rem;
  font-size: 1.35 rem;
  font-weight: 500;
  flex: 17;
}

#modal-history-chart {
  margin-top: 1rem;
}

p{
  text-align: justify;
}

@media (max-width: 768px) {
  .image {
    transform: scale(0.7);
  }
  #modal-title {
    font-size: 1.5rem;
  }
}

</style>
