import { VitalSignEngine } from "vital-sign-engine";

/* Utility function to crop the image */
function crop(image, facebox) {
  let w = facebox.width * image.width;
  let h = facebox.height * image.height;
  let x = (facebox.xCenter - facebox.width / 2) * image.width;
  let y = (facebox.yCenter - facebox.height / 2) * image.height;
  h = h * 0.7;

  let canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;
  let ctx = canvas.getContext("2d");
  ctx?.drawImage(image, x, y, w, h, 0, 0, w, h);
  return canvas;
}

let MOVEMENT_ARRAY = [];
const BRIGHTNESS_RANGE = [90, 220];
const DISTANCE_RANGE = [0.25, 0.9];
const BOUNDARY_RANGE_X = [0.45, 0.55];
const BOUNDARY_RANGE_Y = [0.3, 0.7];
const MOVEMENT_RANGE = 0.1;

function resetMovementArray() {
  MOVEMENT_ARRAY = [];
}

async function checkConditions(image, facebox, fps) {
  // Get RGB data of face photo
  let w = facebox.width * image.width;
  let h = facebox.height * image.height;
  let x = (facebox.xCenter - facebox.width / 2) * image.width;
  let y = (facebox.yCenter - facebox.height / 2) * image.height;
  h = h * 0.7;
  let canvas = document.createElement("canvas");
  // Should we force set the canvas width and height here
  canvas.width = w;
  canvas.height = h;
  let ctx = canvas.getContext("2d");
  ctx?.drawImage(image, x, y, w, h, 0, 0, w, h);

  let rgbData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

  /* Condition 1: lighting */
  let vMean = 0;
  for (let i = 0; i < rgbData.length; i += 4) {
    vMean += Math.max(rgbData[i], rgbData[i + 1], rgbData[i + 2]);
  }
  vMean = vMean / (rgbData.length / 4);
  const condition1 =
    BRIGHTNESS_RANGE[0] <= vMean && vMean <= BRIGHTNESS_RANGE[1];

  /* Condition 2: distance */
  const condition2 =
    DISTANCE_RANGE[0] <= facebox.height && facebox.height <= DISTANCE_RANGE[1];

  /* Condition 3: centered */
  const condition3 =
    BOUNDARY_RANGE_X[0] <= facebox.xCenter &&
    facebox.xCenter <= BOUNDARY_RANGE_X[1] &&
    BOUNDARY_RANGE_Y[0] <= facebox.yCenter &&
    facebox.yCenter <= BOUNDARY_RANGE_Y[1];

  /* Condition 4: movement */
  let condition4 = false;
  if (MOVEMENT_ARRAY.length >= (fps > 20 ? 20 : fps )) {
    MOVEMENT_ARRAY.shift();
  }

  // Check how much movement from facebox center
  let maxX = 0;
  let maxY = 0;
  let minX = 1;
  let minY = 1;
  for (let i = 0; i < MOVEMENT_ARRAY.length; i++) {
    maxX = MOVEMENT_ARRAY[i][0] > maxX ? MOVEMENT_ARRAY[i][0] : maxX;
    maxY = MOVEMENT_ARRAY[i][1] > maxY ? MOVEMENT_ARRAY[i][1] : maxY;
    minX = MOVEMENT_ARRAY[i][0] < minX ? MOVEMENT_ARRAY[i][0] : minX;
    minY = MOVEMENT_ARRAY[i][1] < minY ? MOVEMENT_ARRAY[i][1] : minY;
  }

  const rangeX = maxX - minX;
  const rangeY = maxY - minY;

  condition4 = rangeX <= MOVEMENT_RANGE && rangeY <= MOVEMENT_RANGE;

  // Append latest motion
  MOVEMENT_ARRAY.push([facebox.xCenter, facebox.yCenter]);

  /* Condition 5: server is alive */
  let condition5 = await VitalSignEngine.isReady();

  return {
    brightness: condition1,
    distance: condition2,
    centered: condition3,
    movement: condition4,
    serverReady: condition5,
  };
}

export { crop, resetMovementArray, checkConditions };
