<template>
    <h1 class='display-5' id="guidelines">
        {{t('help_content.setup.title')}}
    </h1>
    <h2 class="fs-2 fw-normal mt-5 mb-4" id="dos">
        {{t('help_content.setup.dos.title')}}
    </h2>
    <p>
        {{t('help_content.setup.dos.p1')}}
        <br>
        <ol>
            <li>{{t('help_content.setup.dos.1')}}</li>
            <li>{{t('help_content.setup.dos.2')}}</li>
            <li>{{t('help_content.setup.dos.3')}}</li>
            <li>{{t('help_content.setup.dos.4')}}</li>
            <li>{{t('help_content.setup.dos.5')}}</li>
            <li>{{t('help_content.setup.dos.6')}}</li>
        </ol>
    </p>
    <h2 class="fs-2 fw-normal mt-5 mb-4" id="donts">
        {{t('help_content.setup.donts.title')}}
    </h2>
    <p>
        <ol>
            <li>{{t('help_content.setup.donts.1')}}</li>
            <li>{{t('help_content.setup.donts.2')}}</li>
            <li>{{t('help_content.setup.donts.3')}}</li>
            <li>{{t('help_content.setup.donts.4')}}</li>
            <li>{{t('help_content.setup.donts.5')}}</li>
            <li>{{t('help_content.setup.donts.6')}}</li>
            <li>{{t('help_content.setup.donts.7')}}</li>
        </ol>
    </p>
    <h2 class="fs-2 fw-normal mt-5 mb-4" id="low-accuracy">
        {{t('help_content.setup.low_accuracy.title')}}
    </h2>
    <ul>
        <li>
            <p>{{t('help_content.setup.low_accuracy.1')}}</p>
            <figure class="figure mx-auto d-block">
                <img src="./assets/dark-lighting.png" class="figure-img mx-auto img-fluid d-block w-100" alt="">
                <img src="./assets/bright-lighting.png" class="figure-img mx-auto img-fluid d-block w-100" alt="">
            </figure>
        </li>
        <li>
            <p>{{t('help_content.setup.low_accuracy.2')}}</p>
            <figure class="figure mx-auto d-block">
                <img src="./assets/uneven-light.png" class="figure-img mx-auto img-fluid d-block w-100" alt="">
                <img src="./assets/backlight.png" class="figure-img mx-auto img-fluid d-block w-100" alt="">
            </figure>
        </li>
        <li>
            <p>{{t('help_content.setup.low_accuracy.3')}}</p>
            <figure class="figure mx-auto d-block">
                <img src="./assets/mask.png" class="figure-img mx-auto img-fluid d-block w-100" alt="">
                <img src="./assets/hoodie.png" class="figure-img mx-auto img-fluid d-block w-100" alt="">
                <img src="./assets/face-covered.png" class="figure-img mx-auto img-fluid d-block w-100" alt="">
            </figure>
        </li>
        <li>
            <p>{{t('help_content.setup.low_accuracy.4')}}</p>
            <figure class="figure mx-auto d-block">
                <img src="./assets/side-facing.png" class="figure-img mx-auto img-fluid d-block w-100" alt="">
            </figure>
        </li>
        <li>
            <p>{{t('help_content.setup.low_accuracy.5')}}</p>
            <figure class="figure mx-auto d-block">
                <img src="./assets/distance-far.png" class="figure-img mx-auto img-fluid d-block w-100" alt="">
                <img src="./assets/distance-far-3.jpg" class="figure-img mx-auto img-fluid d-block w-100" alt="">
                <img src="./assets/distance-far-2.jpg" class="figure-img mx-auto img-fluid d-block w-100" alt="">
            </figure>
        </li>
        <li>
            <p>{{t('help_content.setup.low_accuracy.6')}}</p>
            <figure class="figure mx-auto d-block">
                <img src="./assets/distance-close-1.png" class="figure-img mx-auto img-fluid d-block w-100" alt="">
                <img src="./assets/distance-close-2.jpg" class="figure-img mx-auto img-fluid d-block w-100" alt="">
            </figure>
        </li>
    </ul>
</template>
s
<script>
import {useI18n} from 'vue-i18n';
export default {
    setup() {
        const {t, locale} = useI18n();
        return {t, locale}
    },
}
</script>

<style scoped>
p, li {
    text-align: justify;
}
a {
    text-decoration: none;
}
</style>