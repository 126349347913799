<template>
    <data-hud class="data-hud" title="Temperature (Beta)" unit="`C" :data="data"/>
</template>

<script>
import DataHud from "./data-hud.vue"

export default
  {
    name: 'temperature',
    props: {
        data: Number
    },
    setup() {
    },
    components: { DataHud }
}
</script>

<style scoped>
.data-hud {
    position: absolute;
    left: 135px;
    top: 730px;
}
</style>


