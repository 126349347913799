<template>
  <DashboardOnboard
  />
  <div class="content">
    <detailed-information-screen
      :selectedTag="this.selectedTag"
      :vsData="vitalSign"
      :oneDayData="oneDayData"
      :oneWeekData="oneWeekData"
      :oneMonthData="oneMonthData"
    />

    <!-- Top Panel -->
    <div class="top-panel">
      <p class="title">PanopticAI - {{t('page.dashboard')}}</p>
      <div class="userName">
        <div class="plan border border-3 border-success rounded px-2 fs-5">{{userType ? t('user_info.plan_type.' + userType) : ""}}</div>
        <div class="name">{{ userName }}</div></div>
      <general-wellness
        id='chart'
        class="m-4"
        :oneDayData="oneDayData"
        :oneWeekData="oneWeekData"
        :oneMonthData="oneMonthData"
        :lastScan="lastScan"
      />
      <p class="lastScan">{{t('last_scan')}}: {{ lastScanDate }}</p>
    </div>
    <div><p></p></div>

    <!-- Bottom Panel -->
    <div class="bottom-panel">
      <div class="row">
        <div class="col-xxl-3 col-md-6 col-sm-12">
          <data-box
            :icon="heartRateIcon"
            :title="t('vital_signs.heartRate.title')"
            :data="vitalSign.heartRate"
            unit="bpm"
            tag="heartRate"
            @update-modal="updateModalContent"
          />
          <data-box
            :icon="heartRateIcon"
            :title="t('vital_signs.hrvIbi.title')"
            :data="vitalSign.ibi"
            unit="ms"
            tag="hrvIbi"
            @update-modal="updateModalContent"
          />
          <data-box
            :class="userType == 'free' ? 'disableBox' : ''"
            :icon="HeartRateVariabilityIcon"
            :title="t('vital_signs.hrvSdnn.title')"
            :data="vitalSign.hrvSdnn"
            unit="ms"
            tag="hrvSdnn"
            @update-modal="updateModalContent"
          />
          <!-- <data-box
            :icon="BloodSugarIcon"
            :title="t('vital_signs.bloodSugar.title')"
            :data="vitalSign.bloodSugar"
            unit=""
            tag="bloodSugar"
            @update-modal="updateModalContent"
          /> -->
        </div>
        <div class="col-xxl-3 col-md-6 col-sm-12">
          <data-box
            :icon="O2SaturationIcon"
            :title="t('vital_signs.spo2.title')"
            :data="vitalSign.spo2"
            unit="%"
            tag="spo2"
            @update-modal="updateModalContent"
          />
          <data-box
            :class="userType == 'free' ? 'disableBox' : ''"
            :icon="breathingRateIcon"
            :title="t('vital_signs.respiratoryRate.title')"
            :data="vitalSign.respiratoryRate"
            unit="bpm"
            tag="respiratoryRate"
            @update-modal="updateModalContent"
          />
          <data-box
            :class="userType == 'free' ? 'disableBox' : ''"
            :icon="MeterIcon"
            :title="t('vital_signs.bloodPressure.title')"
            :data="vitalSign.bloodPressure"
            unit="mm Hg"
            tag="bloodPressure"
            @update-modal="updateModalContent"
          />
          <!-- <data-box
            :icon="BloodAlcoholIcon"
            :title="t('vital_signs.bloodAlcohol.title')"
            :data="vitalSign.bloodAlcohol"
            unit=""
            tag="bloodAlcohol"
            @update-modal="updateModalContent"
          /> -->
        </div>
        <div class="col-xxl-3 col-md-6 col-sm-12">
          <!--:class="userType == 'free' ? 'disableBox' : ''"-->
          <data-box
            :icon="FacialAgeIcon"
            :title="t('vital_signs.facialSkinAge.title')"
            :data="vitalSign.facialSkinAge"
            unit=""
            tag="facialSkinAge"
            @update-modal="updateModalContent"
          />
          <data-box
            :class="userType == 'free' ? 'disableBox' : ''"
            :icon="StressRateIcon"
            :title="t('vital_signs.stress.title')"
            :data="vitalSign.stress"
            tag="stress"
            @update-modal="updateModalContent"
          />
          <data-box
            :icon="ScaleIcon"
            :title="t('vital_signs.bmi.title')"
            :data="vitalSign.bmi"
            unit=""
            tag="bmi"
            @update-modal="updateModalContent"
          />
        </div>
        <div class="col-xxl-3 col-md-6 col-sm-12">
          <data-box
            :icon="heartRateIcon"
            :class="userType == 'free' ? 'disableBox' : ''"
            :title="t('vital_signs.generalRisk.title')"
            :data="vitalSign.generalRisk"
            unit="%"
            tag="generalRisk"
            @update-modal="updateModalContent"
          />
          <data-box
            :icon="heartRateIcon"
            :class="userType == 'free' ? 'disableBox' : ''"
            :title="t('vital_signs.covidRisk.title')"
            :data="vitalSign.covidRisk"
            unit="%"
            tag="covidRisk"
            @update-modal="updateModalContent"
          />
          <data-box
            :icon="temperatureIcon"
            :class="userType == 'free' ? 'disableBox' : ''"
            :title="t('vital_signs.temperature.title')"
            :data="vitalSign.temperature"
            unit="°C"
            tag="temperature"
            @update-modal="updateModalContent"
          />
          <!--
                    <data-box :icon="MeterIcon" title="Systolic BP" :data="vitalSign.blood_pressure_systolic" />
                    <data-box :icon="MeterIcon" title="Diastolic BP" :data="vitalSign.blood_pressure_diastolic" />
                    -->
        </div>
      </div>
      <div class="copyright text-center"><p>{{ t('copyright') }}</p></div>
    </div>
  </div>
  <side-bar />

</template>

<script>
import SideBar from "../sidebar/sidebar.vue";
import GeneralWellness from "./general-wellness.vue";
import DataBox from "./data-box.vue";
import DetailedInformationScreen from "./detailed-information-screen.vue";
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router'
import DashboardOnboard from '../onboarding/dashboard-onboard.vue';
import { newUserManager } from "../../user/company-user-manager.ts";
import { cloudBackend } from "@/user/company-user-manager-definitions";
import Logger from '@/common/logger';

export default {
  setup() {
    const { t, locale } = useI18n()
    const router = useRouter()

    var currentUser = newUserManager.currentUser
    // var currentUser = userManager.currentUser();
    if (!currentUser) {
      router.push('/')
    }
    return {
      t, locale,
      oneDayData: ref(),
      oneWeekData: ref(),
      oneMonthData: ref(),
      vitalSign: ref({}),
      lastScan: ref(),
      lastScanDate: ref(),
      selectedTag: ref(""),
    };
  },
  components: {
    SideBar,
    GeneralWellness,
    DataBox,
    DetailedInformationScreen,
    DashboardOnboard,
  },
  async created() {
    await this.getUserType();
    // this.userType = 'pro'; // for debug only
  },
  methods: {
    updateModalContent(tag) {
      this.selectedTag = tag;
    },
    async getUserType() {
      const currentUser = newUserManager.currentUser;
      if(!currentUser) return;
      else {
        this.userType = currentUser.userSettings.planType;
      }
    },
  },
  data() {
    return {
      userType: undefined,
    };
  },
  async mounted() {
    let currentUser = newUserManager.currentUser;
    // Logger.log(currentUser)

    // await userManager.ready();
    // let currentUser = userManager.currentUser();
    if (!currentUser) {
      return;
    } else if (currentUser.userType == cloudBackend.local) {

      this.oneDayData = currentUser.oneDayData;
      this.oneWeekData = currentUser.oneWeekData;
      this.oneMonthData = currentUser.oneMonthData;
      this.lastScan = {
        date: new Date(),
        user_id: currentUser.userId,
        vital_sign: currentUser.lastVitalSigns ? currentUser.lastVitalSigns : {},
      };
      this.vitalSign = currentUser.lastVitalSigns;
      if (currentUser.lastVitalSigns) {
        this.lastScanDate = this.lastScan.date;
      } else {
        this.lastScanDate = "-";
        // this.lastScan.vital_sign = fakeData[29];
        this.lastScan.vital_sign = currentUser.oneMonthData[29];
      }
      // if (!this.vitalSign) this.vitalSign = Object.assign({}, fakeData.slice(-1)[0]);
      if (!this.vitalSign) this.vitalSign = Object.assign({}, currentUser.oneMonthData.slice(-1)[0]);
    } 
    else {
      // logged in user, load user data
      setTimeout(() => {
        if ( this.$root.debugMode ) { Logger.log(currentUser) }
        this.oneDayData = currentUser.oneDayData;
        this.oneWeekData = currentUser.oneWeekData;
        this.oneMonthData = currentUser.oneMonthData;
        this.lastScan = currentUser.lastHealth;
        if (currentUser.lastHealth && currentUser.lastVitalSigns) {
          // data of last scan is found
          this.lastScan = {
            date: currentUser.lastHealth.datetime,
            user_id: currentUser.userId,
            vital_sign: currentUser.lastVitalSigns,
          }
          this.vitalSign = currentUser.lastVitalSigns;
          this.lastScanDate = this.lastScan.date;
        } else if (currentUser.data) {
          // get data from historical data
          this.lastScanDate = currentUser.lastHealth.datetime;
          this.lastScan = {
            date: currentUser.lastHealth.datetime,
            user_id: currentUser.userId,
            vital_sign: currentUser.lastVitalSigns,
          }
        } else {
          // no data
          this.lastScanDate = '-'
          this.lastScan = undefined;
        }
      }, 1000);
    }
  },
  computed: {
    temperatureIcon() {
      return require("./assets/thermometer.svg");
    },
    heartRateIcon() {
      return require("./assets/heart-icon.svg");
    },
    breathingRateIcon() {
      return require("./assets/lung.svg");
    },
    O2SaturationIcon() {
      return require("./assets/o2-saturation-icon.svg");
    },
    HeartRateVariabilityIcon() {
      return require("./assets/heart-rate-variability-icon.svg");
    },
    StressRateIcon() {
      return require("./assets/stress-rate-icon.svg");
    },
    MeterIcon() {
      return require("./assets/meter-icon.svg");
    },
    ScaleIcon() {
      return require("./assets/scale.svg");
    },
    BloodAlcoholIcon() {
      return require("./assets/blood-alcohol.svg");
    },
    BloodSugarIcon() {
      return require("./assets/blood-sugar.svg");
    },
    FacialAgeIcon() {
      return require("./assets/facial-age.svg");
    },
    userName() {
      // let currentUser = userManager.currentUser();
      let currentUser = newUserManager.currentUser;
      if (!currentUser) {
        return "";
      }
      if (currentUser.name == 'Guest') {
        return this.t("user.guest")
      }
      return currentUser.name;
    },
  },
};
</script>

<style scoped>
.content {
  position: absolute;
  padding-left: 87px;
  right: 0px;
  height: 100vh;
  min-height: max-content;
  background: #e4e3e3;
  overflow: auto;
}
.title {
  margin-left: 40px;
  margin-top: 30px;
  margin-bottom: 30px;

  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  color: #000000;
}
.userName {
  position: absolute;
  top: 0px;
  right: 45px;
  margin-left: 40px;
  margin-top: 30px;
  margin-bottom: 30px;

  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  color: #000000;
}
.lastScan {
  position: relative;
  margin-left: 30px;
}
.top-panel {
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.bottom-panel {
  padding: 30px;
  background: #e4e3e3;
}
.copyright {
  position: relative;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0);
}
#chart {
  height: 500px;
}
.disableBox{
  pointer-events: none;
  opacity: 0.6;
}
.plan {
  display: inline-block;
  margin-right: 20px;
}
.name {
  display: inline-block;
}
@media (max-width: 768px) {
  .content{
    padding-left: 0px;
  }
  .bottom-panel {
    margin-bottom: 87px;
  }
  .userName {
    font-size: 20px;
  }
  .title {
    margin-top: 75px;
    font-size: 20px;
  }
}

@media (max-width: 1053px) {
  #chart {
    height: 700px;
  }
}
</style>
