<template>
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="fs-3">{{ t("terms_condition.title") }}</h1>
        <div class="modal-close-button close">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="() => {agreePanoptic = false; agreeTnc = false}"
          ></button>
        </div>
      </div>
      <div class="modal-body">
        <div class="inputContainer">
          <p>
            {{ t("terms_condition.description") }}
          </p>
          <ol class="content text-start">
            <li
              v-for="content in $tm('terms_condition.content')"
              :key="content" v-html="content"
            >
            </li>
          </ol>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" v-model="agreeTnc" id="agreeTncBox">
          <label class="form-check-label" for="agreeTncBox">
            {{t('terms_condition.agree_tnc')}}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" v-model="agreePanoptic" id="agreePanopticBox">
          <label class="form-check-label" for="agreePanopticBox">
            {{t('terms_condition.agree_panoptic')}}
          </label>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary button-big"
          :disabled="disableContinue"
          @click="updateAgreeTnC"
        >
          {{ t("button.continue") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
    };
  },
  emits: ['updateAgreeTnC'],
  data() {
    return {
      agreeTnc: false,
      agreePanoptic: false,
      disableContinue: true,
    }
  },
  watch: {
    agreeTnc() {
      if(this.agreeTnc && this.agreePanoptic) {
        this.disableContinue = false;
      } else {
        this.disableContinue = true;
      }
    },
    agreePanoptic() {
      if(this.agreeTnc && this.agreePanoptic) {
        this.disableContinue = false;
      } else {
        this.disableContinue = true;
      }
    }
  },
  methods: {
    updateAgreeTnC() {
      this.$emit('updateAgreeTnC', true)
    }
  }
};
</script>


<style scoped>
.modal {
    overflow: hidden;
    max-height: 60%;
    max-width: 98%;
    left: 1%;
    top: 20%;
    border-radius: 10px;
}
.modal-dialog {
    width: 100%;
    /* height: 100%; */
}
.modal-close-button {
    position: absolute;
    right: 4.5%;
}
/* Modal Content/Box */
.modal-content {
    position: grid;
    background-color: #fefefe;
    margin: 0% auto;
    padding: 5px;
    border: 1px solid #888;
    /* min-height: 95%; */
    min-width: 100%;
    overflow: hidden;
    border-radius: 10px;
}
.modal-header {
    display: flex;
    height: 15%;
    justify-content: center;
    font-size: xx-large;
}
.modal-body {
    display: grid;
    width: 100%;
    padding: 20px;
    margin: 5px auto;
}
.modal-footer {
    bottom: 0px;
    height: 15%;
    justify-content: center;
}
.inputContainer {
    width: 100%;
}
.form-heading {
    font-size: x-large;
    font-weight: bold;
    text-decoration: underline;
}
.button-big {
    /* min-height: 50px; */
    height: 80%;
    min-width: 100px;
    font-size: large;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
</style>