<template>
  <div
    v-if="
      currentUser && currentUser.currentUser.lastVitalSigns && profileFilled
    "
    ref="report_pdf"
  >
    <div id="pdf-1" class="p-4">
      <div class="">
        <p class="fw-bolder color-orange" :style="{ 'font-size': '25px' }">
          {{ t("health_report.title") }}
        </p>
      </div>
      <div
        class="d-flex flex-row bg-white py-3 content-card fw-bold px-4 border-radius-all"
      >
        <div class="col-5">
          <div
            class="d-flex flex-row justify-content-start color-orange fw-bolder fs-2 text-center px-0"
            :style="{ fontSize: '18px !important' }"
          >
            {{ userName }}
          </div>
          <div
            class="d-flex flex-row justify-content-start color-gray fs-5 text-center px-0"
            :style="{ fontSize: '13px !important' }"
          >
            {{ t("health_report.email") }}:
            {{ currentUser ? currentUser.currentUser.userSettings.email : "" }}
          </div>
          <div class="d-flex flex-row fs-5">
            <div class="col-4 color-gray px-0" :style="{ fontSize: '13px' }">
              <div class="">{{ t("health_report.age") }}: {{ age }}</div>
              <div class="">
                {{ t("health_report.sex") }}:
                {{
                  currentUser !== undefined
                    ? t(
                        "health_report." +
                          currentUser.currentUser.userSettings.gender
                      )
                    : ""
                }}
              </div>
              <div class="">
                {{ t("health_report.bmi") }}:
                {{
                  currentUser.currentUser.lastVitalSigns
                    ? currentUser.currentUser.lastVitalSigns.bmi.toFixed(1)
                    : ""
                }}
              </div>
            </div>
            <div class="col-8 color-gray my-auto" :style="{ fontSize: '13px' }">
              <div class="d-flex flex-row">
                <div class="d-flex flex-shrink">
                  <img
                    src="./assets/location.svg"
                    alt="location"
                    class="icon p-0 me-2"
                  />
                </div>
                <div class="col">
                  {{ currentUser.companySettings.location }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="d-flex flex-shrink">
                  <img
                    src="./assets/calendar.svg"
                    alt="date"
                    class="icon p-0 me-2"
                  />
                </div>
                <div class="col">
                  {{ lastScanTime }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-7">
          <div
            class="d-flex flex-row color-gray align-items-center h-100 ps-4 py-2"
          >
            <div class="col-6 pe-1 h-100">
              <div
                class="general-score d-flex justify-content-center flex-column"
              >
                <div class="justify-content-center text-center">
                  {{ t("health_report.wellness.stress.title") }}
                </div>
                <div
                  class="d-flex flex-row justify-content-center align-items-center fs-2 color-orange"
                >
                  <img
                    src="./assets/stress-top.svg"
                    alt="covid"
                    class="ps-0 icon-profile-vitals me-2"
                  />
                  {{ this.getData("stress").toFixed(1) }}
                </div>
              </div>
            </div>
            <div class="col-6 ps-1 h-100">
              <div
                class="general-score d-flex justify-content-center flex-column"
              >
                <div class="justify-content-center text-center">
                  {{ t("health_report.general_wellness") }}
                </div>
                <div
                  class="d-flex flex-row justify-content-center align-items-center fs-2 color-orange"
                >
                  <img
                    src="./assets/general-wellness.svg"
                    alt="covid"
                    class="ps-0 icon-profile-vitals me-2"
                  />
                  {{ this.getData("generalWellness").toFixed(1) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Wellness -->
      <vitals-type
        :vitalSign="['generalWellness', 'stress']"
        :type="'wellness'"
        :pdf="true"
      />

      <!-- Respiratory -->
      <vitals-type
        :vitalSign="['respiratoryRate', 'spo2']"
        :type="'respiratory'"
        :pdf="true"
      />

      <disclaimer :pdf="true" />
    </div>

    <div id="pdf-1" class="p-4">
      <div class="">
        <p class="fw-bolder color-orange" :style="{ 'font-size': '25px' }">
          {{ t("health_report.title") }}
        </p>
      </div>

      <!-- Facial -->
      <vitals-type
        :vitalSign="['facialSkinAge']"
        :type="'facial'"
        :pdf="true"
      />

      <!-- Cardiovascular -->
      <vitals-type
        :vitalSign="[
          'heartRate',
          'hrvSdnn',
          'bloodPressureSystolic',
          'bloodPressureDiastolic',
        ]"
        :type="'cardiovascular'"
        :pdf="true"
      />
      <disclaimer :pdf="true" />
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { useI18n } from "vue-i18n";
import { newUserManager } from "../../user/company-user-manager.ts";
import { useRouter } from "vue-router";
import VitalsType from "./vitals-type.vue";
import Disclaimer from "./disclaimer.vue";
// import Copyright from '../../common/copyright.vue';

export default {
  name: "email-page",
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();
    var currentUser = newUserManager.currentUser;
    const { profileFilled } = inject("profileFilled");

    // if(!currentUser || !currentUser.currentUser.lastVitalSigns || !profileFilled) {
    if (!currentUser) {
      router.push("/");
    }

    return {
      t,
      locale,
      currentUser,
      profileFilled,
    };
  },
  components: {
    VitalsType,
    Disclaimer,
  },
  props: {
    font: String,
    pdf: Boolean,
  },
  created() {
    if (this.$root.$data.debugMode) {
      // pass
    } else {
      if (
        !this.currentUser ||
        !this.currentUser.currentUser.lastVitalSigns ||
        !this.profileFilled
      ) {
        this.$router.push("/");
      }
    }
  },
  computed: {
    userName() {
      if (!this.currentUser) {
        return "";
      }
      if (this.currentUser.currentUser.name == "Guest") {
        return this.t("user.guest");
      }
      return this.currentUser.currentUser.name;
    },
    age() {
      if (!this.currentUser) {
        return "";
      }
      const age = this.currentUser.currentUser.userSettings.age;
      if (age) {
        return age;
      }
      const dateString = this.currentUser.currentUser.userSettings.birthday;
      var ageInMilliseconds = new Date() - new Date(dateString);
      return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
    },
    lastScanTime() {
      const d = new Date(this.currentUser.currentUser.lastVitalSigns.datetime);
      let month = String(d.getMonth() + 1);
      let day = String(d.getDate());
      const year = String(d.getFullYear());

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return `${day}/${month}/${year}`;
    },
  },
  methods: {
    getData(tag) {
      if (
        this.currentUser &&
        this.currentUser.currentUser.lastVitalSigns &&
        this.currentUser.currentUser.lastVitalSigns[tag]
      ) {
        return this.currentUser.currentUser.lastVitalSigns[tag];
      } else {
        return 0;
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,400;0,700;0,800;1,300&display=swap");

.age-number {
  font-size: 120px;
}
.border-orange {
  border-bottom: solid;
  border-bottom-color: rgb(255, 130, 0);
}
.color-gray {
  color: #747579;
}
.container-full {
  padding: 50px;
  height: 100vh;
}
.content {
  position: static;
  padding-left: 87px;
  right: 0px;
  height: 100vh;
  min-height: max-content;
  background: #e4e3e3;
  overflow-y: scroll;
  font-size: large;
}
.content-card {
  border-radius: 17px;
}
.general-score {
  background-color: #eeeff3;
  border-radius: 17px;
  padding: 10px;
  height: 100%;
}
.icon {
  width: 15px;
  height: 20px;
}
.icon-facts {
  width: 35px;
  height: 25px;
}
.icon-profile-vitals {
  height: 40px;
}
.icon-vital-signs {
  width: 80px;
}
#logo {
  float: right;
  height: 25px;
}
.opacity-border-radius {
  border-radius: 10px 10px 0px 0px;
}
.panoptic-logo {
  width: 200px;
  padding: 0;
  margin-bottom: 25px;
}
#pdf-1 {
  background-color: #f6f6f6;
  width: 210mm;
  height: 296mm;
}
#profile-img {
  margin: auto auto;
  margin-bottom: 5px;
  max-width: 60%;
}
.text-justify {
  text-align: justify;
}
</style>